import { ChangeEvent } from 'react';

import { SelectOption } from './selects/simple-select';
import Spinner from './base-spinner';

interface ISelectProp {
  id: string;
  title: string;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  activeValue?: string | undefined;
  selectOptions: SelectOption[];
  isLoading?: boolean;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
}

const Select = ({
  id,
  title,
  onChange,
  activeValue,
  selectOptions,
  isLoading,
  placeholder,
  disabled,
  className,
}: ISelectProp) => {
  return (
    <div className={className}>
      <label
        htmlFor={id}
        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
      >
        {title}
      </label>
      <div className="relative">
        <select
          id={id}
          className="bg-gray-50 border border-gray-300 bg-white text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          onChange={(e) => {
            onChange(e);
          }}
          value={activeValue || placeholder}
          disabled={disabled}
        >
          {placeholder && (
            <option className="hidden" value={placeholder} disabled>
              {placeholder}
            </option>
          )}
          {selectOptions.map((option, index) => (
            <option
              key={`${option.value}-${index}`}
              value={option.value}
              disabled={option.disable ?? false}
            >
              {option.label}
            </option>
          ))}
        </select>
        {isLoading && (
          <div className="absolute top-1/2 -translate-y-1/2 -right-8">
            <Spinner size="h-6 w-6" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Select;
