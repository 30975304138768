import { useState } from 'react';
import { Label, TextInput } from 'flowbite-react';
import { HiCheckCircle, HiCursorClick, HiExclamation } from 'react-icons/hi';

import { customToastMessage } from 'src/components/toast';
import Button from 'src/components/button';

import { useAppDispatch } from 'src/store/hooks';
import { setMarkupLink } from 'src/store/slices/markup-editor-slice';
import { parseResponse } from 'src/store/services/helpers';
import {
  useStoreActionMutation,
  useUpdateActionMutation,
} from 'src/store/services/conversion-action/endpoints/conversion-action';
import {
  ConversionActionDTO,
  ConversionActionType,
  ConversionActionItem,
} from 'src/store/services/conversion-action/types';

interface IActionTypeProp {
  conversionActionType: ConversionActionType | undefined;
  selectedTag: string;
  editAction: ConversionActionItem | undefined;
  isExactly: boolean;
  pageUrl: string;
  actionName: string;
  onOpenEditor: () => void;
  onModalClose: () => void;
}

const ActionsTypeForm = ({
  conversionActionType,
  selectedTag,
  editAction,
  isExactly,
  pageUrl,
  actionName,
  onOpenEditor,
  onModalClose,
}: IActionTypeProp) => {
  const dispatch = useAppDispatch();
  const [inputPageUrl, setInputPageUrl] = useState(pageUrl);
  const [isModalExactly, setModalExactly] = useState<boolean>(isExactly);
  const [modalActionName, setModalActionName] = useState<string>(actionName);

  const [storeAction, { isLoading: saveLoading }] = useStoreActionMutation();
  const [updateAction, { isLoading: updateLoading }] =
    useUpdateActionMutation();

  const saveData = async (dto: ConversionActionDTO) => {
    const response = await storeAction(dto);
    const { result, errorMessage, error } = parseResponse(response);
    if (error && errorMessage) {
      customToastMessage(
        errorMessage,
        <HiExclamation className="h-5 w-5" />,
        'error'
      );
    }
    if (result) {
      customToastMessage(
        result.message,
        <HiCheckCircle className="h-5 w-5" />,
        'success'
      );
      onModalClose();
    }
  };

  const updateData = async (dto: ConversionActionDTO) => {
    if (!editAction?.id) return;
    dto.id = parseInt(editAction?.id);
    const response = await updateAction(dto);
    const { result, errorMessage, error } = parseResponse(response);
    if (error && errorMessage) {
      customToastMessage(
        errorMessage,
        <HiExclamation className="h-5 w-5" />,
        'error'
      );
    }
    if (result) {
      customToastMessage(
        result.message,
        <HiCheckCircle className="h-5 w-5" />,
        'success'
      );
      onModalClose();
    }
  };

  const handleSaveAction = async () => {
    const dto = {
      name: modalActionName,
      action_type: conversionActionType,
      tag_path: selectedTag,
      page_url: inputPageUrl,
      is_exactly: isModalExactly,
    } as ConversionActionDTO;

    if (editAction) {
      await updateData(dto);
    } else {
      await saveData(dto);
    }
    onModalClose();
  };

  const handleOpenEditor = async () => {
    let href = '';
    try {
      href = new URL(inputPageUrl).href;
    } catch (e) {
      customToastMessage(
        'Invalid url',
        <HiExclamation className="h-5 w-5" />,
        'error'
      );
      return;
    }

    dispatch(setMarkupLink(href));
    onOpenEditor();
  };

  const handleEditUrl = (url: string) => {
    if (!url.startsWith('https')) {
      const correctUrl = url.replace('http:', 'https:');
      setInputPageUrl(correctUrl);
    } else {
      setInputPageUrl(url);
    }
  };

  return (
    <div className="flex gap-y-4 flex-col w-full">
      {ConversionActionType.Page_Visit == conversionActionType && (
        <p className="text-sm font-normal text-gray-700">
          Enter URL or condition that will trigger a conversion event when the
          page is visited. This is the best used for a “thank you” or
          “confirmation” pages that is displayed after submitting a form.
        </p>
      )}
      {ConversionActionType.Button_or_Link == conversionActionType && (
        <p className="text-sm font-normal text-gray-700">
          Enter the URL of the page which contains the element you want to track
          clicks on, then click &quot;Open Editor&quot; button.
          <br />
          Once the page loads, select the element(s) which triggers a successful
          conversion when clicked.
        </p>
      )}
      <div className="flex flex-col gap-y-3">
        <Label htmlFor="conversionName">Conversion Action Name</Label>
        <TextInput
          id="conversionName"
          name="name"
          placeholder="e.g. Thank you page"
          type="text"
          value={modalActionName ?? ''}
          onChange={(e) => setModalActionName(e.target.value)}
          data-testid="create_action_name_input"
        />
      </div>
      <div className="flex flex-col gap-y-3">
        <Label htmlFor="formUrl">
          {ConversionActionType.Form
            ? 'Enter the URL of the page containing the form whose submissions you want to track'
            : 'URL'}
        </Label>
        <div className="flex">
          {ConversionActionType.Page_Visit == conversionActionType && (
            <select
              defaultValue={String(isModalExactly)}
              onChange={(e) => setModalExactly(e.target.value === 'true')}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 border-r-0 rounded-ee-none rounded-se-none"
              data-testid="create_page_visit_select"
            >
              <option value="false" data-testid="action_create_contains">
                contains
              </option>
              <option value="true" data-testid="action_create_exactly">
                exactly
              </option>
            </select>
          )}

          <div className="relative w-full">
            <input
              className={`block p-2.5 w-full z-20 text-sm text-gray-900
                           bg-gray-50 rounded-r-lg border border-gray-300
                           focus:ring-blue-500 focus:border-blue-500 ${
                             ConversionActionType.Page_Visit ==
                             conversionActionType
                               ? 'border-l-gray-50 border-l-2'
                               : 'rounded-lg'
                           }`}
              onChange={(e) => handleEditUrl(e.target.value)}
              value={inputPageUrl ?? ''}
              id="formUrl"
              name="url"
              placeholder="URL"
              type="url"
              data-testid="create_action_url_input"
              required
            />
          </div>

          {ConversionActionType.Page_Visit !== conversionActionType && (
            <Button
              className="whitespace-nowrap ml-3"
              type="default"
              title="Open Editor"
              onClick={handleOpenEditor}
              Icon={<HiCursorClick className="ml-2" />}
              disabled={inputPageUrl.length <= 10}
              reverse
            />
          )}
        </div>
      </div>
      <div>
        <Button
          type="default"
          title="Save"
          onClick={handleSaveAction}
          disabled={saveLoading || updateLoading}
          isLoading={saveLoading || updateLoading}
          dataTest="save_action_button"
          reverse
        />
      </div>
    </div>
  );
};

export default ActionsTypeForm;
