import { ReactNode, useRef, useState } from 'react';

interface ITooltipProps {
  hoverElement: string | JSX.Element;
  children: ReactNode;
  position?: 'left' | 'center' | 'right';
  className?: string;
}

const Tooltip = ({
  hoverElement,
  children,
  position,
  className,
}: ITooltipProps) => {
  const [open, setOpen] = useState(false);
  const [arrowPosition, setArrowPosition] = useState(false);
  const hovered = useRef<HTMLDivElement | null>(null);
  const tooltip = useRef<HTMLDivElement | null>(null);

  const handleTooltipOver = () => {
    setOpen(true);

    if (hovered.current && tooltip.current) {
      const windowHeight = window.innerHeight;
      const hoveredBounds = hovered.current.getBoundingClientRect();
      const dropdownElement = tooltip.current;

      const { width: popupWidth, height: popupHeight } =
        dropdownElement.getBoundingClientRect();
      const {
        top,
        left,
        width: hoveredWidth,
        height: hoveredHeigth,
      } = hoveredBounds;

      const popupPosition =
        position === 'center'
          ? left + hoveredWidth / 2 - popupWidth / 2
          : position === 'right'
          ? left
          : left + hoveredWidth - popupWidth;

      const freeSpace = windowHeight - top + hoveredHeigth;
      const topPosition = top + 320 >= windowHeight;
      const maxHeight = freeSpace > 320 ? 320 : freeSpace;

      dropdownElement.style.cssText = ` 
        top: ${
          !topPosition ? top + hoveredHeigth + 8 : top - popupHeight - 8
        }px; 
        left: ${popupPosition}px; 
        max-height: ${topPosition ? 320 : maxHeight}px;
      `;

      setArrowPosition(topPosition);
    }
  };

  return (
    <div
      ref={hovered}
      className={className}
      onMouseMove={handleTooltipOver}
      onMouseLeave={() => setOpen(false)}
    >
      {hoverElement}
      <div
        ref={tooltip}
        onClick={() => setOpen(false)}
        className={`py-2.5 px-3 ${
          open
            ? 'opacity-100 z-[62] pointer-events-auto'
            : 'opacity-0 -z-[1] pointer-events-none'
        } fixed w-max bg-white right-0 py-2 border border-gray-200 rounded-xl shadow-md`}
      >
        {children}
        <div
          className={`absolute w-4 h-4 bg-white transform -translate-x-1/2 ${
            arrowPosition
              ? 'top-[calc(100%_-_8px)] left-1/2 mb-1 rotate-45'
              : '-top-3 left-1/2 mt-1 rotate-45'
          } -z-[1]`}
          style={{ left: '50%' }}
        />
      </div>
    </div>
  );
};

export default Tooltip;
