import { Dropdown } from 'flowbite-react';
import { RoundedCheckIcon, Trush, SaveArrow } from 'src/components/icons';
import { IElement } from 'src/store/services/create-campaign/types';
import Button from 'src/components/button';

interface IHeaderMarkupProp {
  title: string;
  selectedItems: IElement[];
  haveSelectItem: boolean;
  onDeleteItemClick: (item: IElement) => void;
  onSaveClick: (itemsArr: IElement[]) => void;
  onOpenSelectedModal: () => void;
  selectName?: string;
  setElementName?: string;
  isConversionActions?: boolean;
  selectedType?: 'text' | 'button' | 'image' | undefined;
}

type IHeaderProp = IHeaderMarkupProp;

const Header = (props: IHeaderProp) => {
  const {
    selectName,
    isConversionActions,
    setElementName,
    title,
    haveSelectItem,
    onOpenSelectedModal,
    selectedItems,
    onSaveClick,
    onDeleteItemClick,
    selectedType,
  } = props;
  const handleDelete = (e: any, item: IElement) => {
    e.stopPropagation();
    onDeleteItemClick(item);
  };

  return (
    <div className="flex justify-between items-center py-[10px] px-[12px] border-b-[3px] border-[#A4CAFE] bg-[#E1EFFE] small:flex-col">
      <p className="text-[12px] font-medium text-gray-600 middle:text-ellipsis middle:whitespace-nowrap middle:overflow-hidden middle:w-[calc(100%-640px)] small:w-full small:text-center small:mb-[12px]">
        {title}
      </p>
      <div className="flex items-center">
        {haveSelectItem && (
          <div className="flex items-center mr-[26px]">
            {!isConversionActions && (
              <p className="mr-[14px] text-[14px] font-medium">
                Element: {selectedType}
              </p>
            )}
            <button
              className="flex items-center px-3 py-2 box-border border-0 rounded-lg bg-[#84E1BC] text-[12px] font-medium hover:bg-[#84E1BC]"
              onClick={onOpenSelectedModal}
            >
              <RoundedCheckIcon className="mr-2" />
              {setElementName ? setElementName : 'Make replaceable'}
            </button>
          </div>
        )}
        <div className="[&>div>button]:w-[191.4px] [&>div:nth-child(2)]:w-[213px] border border-gray-300 rounded-lg bg-[#FFFFFF] py-[7.2px] px-2.5 mr-7 box-border">
          <Dropdown
            label={
              <p className="w-full text-[12px] font-normal text-gray-500 text-left">
                {selectName ? selectName : 'Replaceable Items'} (
                {selectedItems.filter((e) => !e.deletion).length})
              </p>
            }
            inline={true}
          >
            {selectedItems
              .filter((e) => !e.deletion)
              .map((item: IElement) => (
                <Dropdown.Item key={item.path}>
                  <p className="w-full">{item.name}</p>
                  <button onClick={(e) => handleDelete(e, item)}>
                    <Trush />
                  </button>
                </Dropdown.Item>
              ))}
          </Dropdown>
        </div>
        <Button
          title="Save & Exit"
          Icon={<SaveArrow className="mr-2" />}
          onClick={() => onSaveClick(selectedItems)}
        />
      </div>
    </div>
  );
};

export default Header;
