import { Tooltip } from 'flowbite-react';
interface IPagination {
  currentPage: number;
  totalPages: number;
  step: number;
  onChangePage: (e: any) => void;
  disabledNext?: boolean;
  tooltipTitle?: string;
}

const PaginationArrows = ({
  currentPage,
  totalPages,
  step,
  onChangePage,
  disabledNext,
  tooltipTitle,
}: IPagination) => {
  const isFirstPage = currentPage === 1;
  const totalValue = +currentPage - 1 + +step;
  const isLastPage = totalValue >= totalPages;

  const handlePrevPage = () => {
    if (!isFirstPage) {
      onChangePage(+currentPage - +step);
    }
  };

  const handleNextPage = () => {
    if (!isLastPage) {
      onChangePage(+currentPage + +step);
    }
  };
  return (
    <div className="flex items-center">
      <button
        className={`border rounded-s-md border-gray-300 px-3 py-1.5 ${
          isFirstPage ? 'cursor-not-allowed' : 'cursor-pointer bg-primary-100'
        }`}
        onClick={handlePrevPage}
        disabled={isFirstPage}
      >
        <span
          className={`text-sm font-medium ${
            isLastPage ? 'text-gray-500' : 'text-primary-600'
          }`}
        >
          Previous
        </span>
      </button>
      {tooltipTitle ? (
        <Tooltip content={tooltipTitle} trigger="hover">
          <button
            className={`border rounded-e-md border-gray-300 border-l-0 px-3 py-1.5 ${
              isLastPage || disabledNext
                ? 'cursor-not-allowed'
                : 'cursor-pointer bg-primary-100'
            }`}
            onClick={handleNextPage}
            disabled={isLastPage || disabledNext}
          >
            <span
              className={`text-sm font-medium ${
                isLastPage || disabledNext
                  ? 'text-gray-500'
                  : 'text-primary-600'
              }`}
            >
              Next
            </span>
          </button>
        </Tooltip>
      ) : (
        <button
          className={`border rounded-e-md border-gray-300 border-l-0 px-3 py-1.5 ${
            isLastPage || disabledNext
              ? 'cursor-not-allowed'
              : 'cursor-pointer bg-primary-100'
          }`}
          onClick={handleNextPage}
          disabled={isLastPage || disabledNext}
        >
          <span
            className={`text-sm font-medium ${
              isLastPage || disabledNext ? 'text-gray-500' : 'text-primary-600'
            }`}
          >
            Next
          </span>
        </button>
      )}
    </div>
  );
};

export default PaginationArrows;
