import { HiOutlineX } from 'react-icons/hi';
import { IBaseModal } from './modal-box';

export interface IModalHeader extends IBaseModal {
  onClose?: () => void;
}

const Header = ({ children, onClose, className }: IModalHeader) => {
  return (
    <div className={`border-b p-4 ${className}`}>
      <div className="flex items-center justify-between">
        {children}
        {onClose && (
          <button onClick={onClose}>
            <HiOutlineX size={18} />
          </button>
        )}
      </div>
    </div>
  );
};

export default Header;
