import React, { useCallback, useState } from 'react';

import OutsideClickHandler from 'src/helpers/outside-click';

import { DropDownArrow } from '../icons';
import Spinner from '../base-spinner';

interface ISelect {
  ItemList: (handleClose: (e: any) => void) => JSX.Element;
  title?: string;
  type?: 'base' | 'text';
  value?: string;
  placeholder?: string;
  baseClassName?: string;
  inputClassName?: string;
  popupClassName?: string;
  disabled?: boolean;
  isLoad?: boolean;
  errorMessage?: string;
  reverse?: boolean;
}

const BaseSelect = ({
  type = 'base',
  title,
  value,
  placeholder,
  baseClassName,
  inputClassName,
  popupClassName,
  disabled,
  isLoad,
  reverse,
  ItemList,
}: ISelect) => {
  const [selectOpen, setSelectOpen] = useState(false);

  const handleSelectOpen = useCallback(
    (e: any) => {
      e.preventDefault();
      !disabled && setSelectOpen(true);
    },
    [disabled]
  );

  const handleClose = useCallback((e: any) => {
    e.stopPropagation();
    setSelectOpen(false);
  }, []);
  return (
    <OutsideClickHandler onOutsideClick={() => setSelectOpen(false)}>
      <div
        className={`block relative ${
          disabled ? 'pointer-events-none' : 'cursor-pointer'
        } ${baseClassName}`}
        onClick={(e) => handleSelectOpen(e)}
        role="combobox"
        aria-expanded={selectOpen ? 'true' : 'false'}
        aria-haspopup="listbox"
        aria-controls="selectDropdown"
      >
        {type === 'base' ? (
          <>
            {title && (
              <label
                htmlFor="selectInput"
                className="block mb-2 text-sm font-medium text-gray-900 text-left"
              >
                {title}
              </label>
            )}
            <div
              className={`relative w-full pointer-events-none ${
                disabled ? 'opacity-60' : ''
              }`}
              tabIndex={0}
            >
              <input
                id="selectInput"
                type="text"
                readOnly
                value={placeholder || value || ''}
                className={`${
                  reverse ? 'pl-9' : ''
                } py-2 px-4 w-full min-h-[37px] bg-white text-sm font-normal text-gray-900 border border-gray-300 rounded-lg ${inputClassName}`}
                tabIndex={-1}
                aria-disabled={disabled ? 'true' : 'false'}
                aria-readonly="true"
                aria-placeholder={placeholder || ''}
                aria-label={title || ''}
              />
              {isLoad ? (
                <div className="absolute right-2.5 top-1/2 -translate-y-[55%]">
                  <Spinner size="h-3 w-3" />
                </div>
              ) : (
                <DropDownArrow
                  className={`absolute ${
                    reverse ? 'left-2.5' : 'right-2.5'
                  } top-1/2 -translate-y-2/4`}
                />
              )}
            </div>
          </>
        ) : (
          <p className="leading-tight font-medium border-0 bg-inherit text-xs text-blue-600 text-left">
            {title ? title : ''}
            {isLoad && <Spinner className="ml-1" size="h-3 w-3" />}
          </p>
        )}
        {selectOpen && (
          <div
            className={`absolute ${
              type === 'base' ? 'top-[calc(100%_-_5px)]' : 'top-full'
            } left-0 right-0 p-3 ${type === 'base' ? 'pt-5' : ''} ${
              type === 'base'
                ? 'max-h-[154px] max-w-max'
                : 'min-w-[210px] max-h-[154px] max-w-max'
            } border ${type === 'base' ? 'border-t-0' : ''} border-gray-300 ${
              type === 'base' ? 'rounded-b-lg' : 'rounded-lg'
            } bg-white shadow-md overflow-y-auto z-[60] ${popupClassName}`}
            role="listbox"
            aria-activedescendant={value}
          >
            {ItemList(handleClose)}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default React.memo(BaseSelect);
