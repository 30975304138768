import { useEffect, useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { setFilters } from 'src/store/slices/campaignSlice';

import { SelectOption } from 'src/components/selects/simple-select';
import { DetailCampaignAd } from 'src/store/services/create-campaign/types';

interface IAdsVersion {
  ads: DetailCampaignAd[];
  onChangeAds: (adID: string[]) => void;
}

const useGetAdsVersions = ({ ads, onChangeAds }: IAdsVersion) => {
  const dispatch = useAppDispatch();
  const { filters } = useAppSelector((state) => state.createCampaignReducer);

  const [selectedVersion, setSelectedVersion] = useState<
    SelectOption[] | undefined
  >(undefined);

  const adsVersion = useMemo(() => {
    const versionArr = [];
    versionArr.push({ label: 'All', value: '' });

    if (ads) {
      ads.forEach((item) => {
        versionArr.push({
          label: `${item.group.name} -> #${item.id}`,
          value: item.id,
        });
      });
    }

    return versionArr;
  }, [ads]);

  const handleSelectAds = (versionID: string) => {
    const currentVersion = adsVersion.find((ver) => ver.value === versionID);
    if (versionID !== '') {
      if (currentVersion) {
        if (selectedVersion) {
          const newState = selectedVersion.find(
            (ver) => ver.value === currentVersion.value
          )
            ? selectedVersion.filter(
                (ver) => ver.value !== currentVersion.value
              )
            : [...selectedVersion, currentVersion];

          setSelectedVersion(newState);
          dispatch(
            setFilters({ ...filters, ads: newState.map((item) => item.value) })
          );
          onChangeAds(newState.map((ad) => ad.value));
        } else {
          setSelectedVersion([currentVersion]);
          dispatch(setFilters({ ...filters, ads: [currentVersion.value] }));
          onChangeAds([currentVersion.value]);
        }
      }
    } else {
      setSelectedVersion(undefined);
      onChangeAds([]);
      dispatch(setFilters({ ...filters, ads: [] }));
    }
  };

  useEffect(() => {
    if (adsVersion) {
      const selected = adsVersion.filter((ad) =>
        filters.ads?.includes(ad.value)
      );
      setSelectedVersion(selected);
    }
  }, [adsVersion, filters.ads]);

  return {
    adsVersion,
    selectedAdsVersion: selectedVersion,
    handleSelectAds,
  };
};

export default useGetAdsVersions;
