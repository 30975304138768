import { useRef, useState } from 'react';
import { clickableWindow, windowButton, windowTitle } from '../selected-window';

import { IElement } from 'src/store/services/create-campaign/types';
import { getSelectedElementPath } from 'src/features/markup-editor/hooks/getSelectedElementPath';

export const getTypeElement = (element: any) => {
  const tag = element.tagName.toLowerCase();
  const elementBackground = window
    .getComputedStyle(element)
    .getPropertyValue('background-image');

  if (elementBackground) {
    const hasBackgroundImage = elementBackground.includes('url');
    if (hasBackgroundImage) return 'image';
  }

  if (tag === 'img' || tag === 'svg') {
    return 'image';
  }

  // const checkFirstParent = element.parentNode.tagName.toLowerCase();
  // const tagWithParents = [tag, checkFirstParent];
  // if (
  //   tagWithParents.find((item) => item === 'button') ||
  //   tagWithParents.find((item) => item === 'a') ||
  //   tagWithParents.find((item) => item === 'input')
  // ) {
  if (tag === 'button' || tag === 'a' || tag === 'input') {
    return 'button';
  }

  return 'text';
};

function hasEmptyChildren(element: HTMLElement, depth: number): boolean {
  const children = element.children;
  const ctrlClicked = sessionStorage.getItem('ctrlDown');
  if (ctrlClicked === 'true') {
    if (children.length === 0) {
      return true;
    }

    let count = 1;
    let index = 0;
    const stack = [children[0]];

    while (stack.length > 0) {
      const el = stack.pop();
      if (el && el.children.length > 0) {
        count++;
        break;
      }

      index++;
      if (children[index]) stack.push(children[1]);
    }

    return count < 2;
  }

  if (depth === 0) {
    return false;
  }

  for (let i = 0; i < Math.min(depth, children.length); i++) {
    const child = children[i];

    if (
      child.nodeType === Node.ELEMENT_NODE &&
      ((child as HTMLElement).tagName === 'IMG' ||
        (child as HTMLElement).style.backgroundImage !== '')
    ) {
      return false;
    }

    if (
      (child.nodeType === Node.ELEMENT_NODE &&
        (child as HTMLElement).innerHTML.trim() === '') ||
      (child.nodeType === Node.TEXT_NODE && child.nodeValue?.trim() === '')
    ) {
      return true;
    }

    if (hasEmptyChildren(child as HTMLElement, depth - 1)) {
      return true;
    }
  }
  return false;
}

const hasTextInChildren = (
  element: HTMLElement | ChildNode,
  deep = false
): boolean => {
  if (getTypeElement(element) === 'button' || deep) {
    const childNodes = element.childNodes;
    for (let i = 0; i < childNodes.length; i++) {
      const child = childNodes[i];
      if (child.nodeType === Node.TEXT_NODE) {
        const textContent = child.textContent?.trim();
        if (textContent && textContent !== '') {
          return true;
        }
      }
      if (child.childNodes.length > 0) {
        return hasTextInChildren(child, true);
      }
    }
  } else {
    if (
      element.nodeType === Node.TEXT_NODE ||
      hasEmptyChildren(element as HTMLElement, 1)
    ) {
      const textContent = element.textContent?.trim();
      if (textContent && textContent !== '') {
        return true;
      }
    }
  }

  return false;
};

const customFindElementByPath = (path: string, doc: any) => {
  const element = doc.querySelector(path);

  if (element) return element;

  const customElementPath = doc.querySelector(cleanedPath(path));
  if (customElementPath) return customElementPath;

  const pathArr = path.includes('>') ? path.split(' > ') : path.split(' ');
  const lastElementInPath = doc.querySelectorAll(pathArr[pathArr.length - 1]);

  if (lastElementInPath.length === 1) {
    return lastElementInPath[0];
  } else {
    let currentElement = doc.body;

    for (const selectorPart of pathArr) {
      const currentChange = currentElement.querySelector(selectorPart);

      if (currentChange) {
        currentElement = currentChange;
      }
    }

    return currentElement;
  }
};

const handleMouseClick = (
  e: any,
  handleSelect: (element: any) => void,
  windowRef: any,
  byElements?: string[] | undefined
) => {
  e.preventDefault();
  e.stopPropagation();
  const element = e.target;
  const rect = e.target.getBoundingClientRect();
  const checkElement = byElements && checkIsByElements(byElements, element);
  const elementTagName = element.tagName.toLowerCase();
  const isSelected = element.hasAttribute('selected');
  const elementName = element.getAttribute('selected');
  const newText = windowTitle(elementName);

  windowRef.current = clickableWindow(
    newText,
    rect.top + rect.height + window[0].scrollY + 'px',
    rect.right + window[0].scrollX
  );

  if (isSelected) {
    const elementBackground = window
      .getComputedStyle(element)
      .getPropertyValue('background-image');
    const hasBackgroundImage = elementBackground.includes('url');
    const hasChildren = element.children.length > 0;
    const hasTextContent = hasTextInChildren(element);
    if (hasBackgroundImage || !hasChildren || hasTextContent) {
      if (
        elementTagName === 'img' ||
        elementTagName === 'svg' ||
        elementTagName === 'input'
      ) {
        element.parentNode.style.position = 'relative';
        window[0].document.body.appendChild(windowRef.current);
      } else {
        if (!byElements) {
          element.style.position = 'relative';
          window[0].document.body.appendChild(windowRef.current);
        }
      }
    }
    if (checkElement) {
      element.style.position = 'relative';
      window[0].document.body.appendChild(windowRef.current);
      return;
    }
  } else {
    const elementBackground = window
      .getComputedStyle(element)
      .getPropertyValue('background-image');
    const hasBackgroundImage = elementBackground.includes('url');
    const hasChildren = element.children.length > 0;
    const hasTextContent = hasTextInChildren(element);
    const handleAddPreSelect = (formSelect?: any) => {
      if (formSelect) {
        const newButton = windowButton();
        windowRef.current.appendChild(newButton);

        windowRef.current.addEventListener('click', (e: any) => {
          e.stopPropagation();
          e.preventDefault();
          handleSelect(formSelect);
        });
        formSelect.setAttribute('pre-select', 'true');
        formSelect.style.boxShadow = 'inset 0px 0px 0px 4px #3F83F8';
      } else {
        const newButton = windowButton();
        windowRef.current.appendChild(newButton);

        windowRef.current.addEventListener('click', (e: any) => {
          e.stopPropagation();
          e.preventDefault();
          handleSelect(element);
        });
        element.setAttribute('pre-select', 'true');
        element.style.boxShadow = 'inset 0px 0px 0px 4px #3F83F8';
      }
    };
    if (hasBackgroundImage || !hasChildren || hasTextContent) {
      if (!byElements) {
        handleAddPreSelect();
      }
    }
    if (checkElement) {
      handleAddPreSelect();
    }

    if (byElements && byElements.includes('form')) {
      const formInElement = element.querySelector('form');
      if (formInElement) {
        handleAddPreSelect(formInElement);
      } else {
        const formOutElement = element.closest('form');
        handleAddPreSelect(formOutElement);
      }
    }
  }

  return;
};

const handleMouseOver = (e: any, byElements: string[] | undefined) => {
  const element = e.target;
  const checkElement = byElements && checkIsByElements(byElements, element);
  if (
    element.getAttribute('selected') ||
    element.tagName?.toLowerCase() === 'html' ||
    element.tagName?.toLowerCase() === 'head' ||
    element.tagName?.toLowerCase() === 'body' ||
    element.getAttribute('maked-button') ||
    element.parentNode.getAttribute('maked-button') ||
    element.parentNode.parentNode.getAttribute('maked-button')
  ) {
    return;
  }

  const hasChildren = element.children.length > 0;
  const elementBackground = window
    .getComputedStyle(element)
    .getPropertyValue('background-image');
  const hasBackgroundImage = elementBackground.includes('url');
  const hasTextContent = hasTextInChildren(element);

  const handleOverElement = (formSelect?: any) => {
    if (formSelect) {
      formSelect.setAttribute('hovered', 'true');
      formSelect.style.boxShadow = 'inset 0px 0px 0px 4px #3F83F8';
    } else {
      element.setAttribute('hovered', 'true');
      element.style.boxShadow = 'inset 0px 0px 0px 4px #3F83F8';
    }
  };

  if (!hasChildren || hasBackgroundImage || hasTextContent) {
    if (!byElements) {
      handleOverElement();
    }
  }
  if (checkElement) {
    handleOverElement();
  }
  if (byElements && byElements.includes('form')) {
    const formInElement = element.querySelector('form');
    if (formInElement) {
      handleOverElement(formInElement);
    } else {
      const formOutElement = element.closest('form');
      if (formOutElement) {
        handleOverElement(formOutElement);
      }
    }
  }
};

const handleMouseOut = (e: any, byElements: string[] | undefined) => {
  const element = e.target;
  const checkElement = byElements && checkIsByElements(byElements, element);
  if (
    element.tagName?.toLowerCase() === 'html' ||
    element.tagName?.toLowerCase() === 'head' ||
    element.tagName?.toLowerCase() === 'body' ||
    element.getAttribute('selected')
  ) {
    return;
  }
  const preSelected = (formSelect?: any) => {
    if (formSelect) {
      if (
        !!formSelect.getAttribute('hovered') &&
        !formSelect.getAttribute('pre-select')
      ) {
        formSelect.removeAttribute('hovered');
        formSelect.style.boxShadow = null;
      }
    } else {
      if (
        !!element.getAttribute('hovered') &&
        !element.getAttribute('pre-select')
      ) {
        element.removeAttribute('hovered');
        element.style.boxShadow = null;
      }
    }
  };

  // При наличии списка элементов, на которые необходимо реагировать
  if (checkElement) {
    preSelected();
  }
  // Когда нужно отслеживать формы на странице
  if (byElements && byElements.includes('form')) {
    const formInElement = element.querySelector('form');
    if (formInElement) {
      preSelected(formInElement);
    } else {
      const formOutElement = element.closest('form');
      preSelected(formOutElement);
    }
  }

  // Когда нужно отслеживать все элементы на странице
  if (!byElements) {
    preSelected();
  }
};

const setSelectedElements = (
  doc: any,
  params: any,
  windowRef: any,
  setSelectedElement?: (element: any) => void
) => {
  params.map((item: any) => {
    try {
      const element = customFindElementByPath(item.path, doc);

      if (element) {
        const rect = element.getBoundingClientRect();
        const newText = windowTitle(item.name);
        windowRef.current = clickableWindow(
          newText,
          rect.top + rect.height + window[0].scrollY + 'px',
          rect.right + window[0].scrollX
        );

        element.setAttribute('selected', `${item.name}`);
        element.style.boxShadow = 'inset 0px 0px 0px 4px #FACA15';

        window[0].document.body.appendChild(windowRef.current);
        setSelectedElement && setSelectedElement(element);
      }
    } catch (e) {
      console.log('Select element Error(in setSelectedElements): ', e);
    }
  });
};

export const useIframeMethods = (
  list: IElement[],
  onOpenModal: (value: boolean) => void,
  getSelectedElement: (value: any) => void,
  byElements?: string[]
) => {
  const windowRef = useRef<any>(null);
  const [selectedElement, setSelectedElement] = useState<any>(undefined);

  const handleLocalElement = (e: any) => {
    setSelectedElement(e);
    getSelectedElement(e);
  };
  return {
    handleClick: (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      getSelectedElementPath(e.target);
      if (selectedElement === e.target && !windowRef.current) {
        handleMouseClick(e, () => onOpenModal(true), windowRef);
      } else if (windowRef.current) {
        windowRef.current.remove();
        windowRef.current = null;
      }

      // удаление выбранного элемента по второму клику
      if (selectedElement && selectedElement.hasAttribute('pre-select')) {
        selectedElement.style.boxShadow = null;
        selectedElement.removeAttribute('pre-select');
      }

      // выбор активного элемента
      if (selectedElement !== e.target) {
        const elementBackground = window
          .getComputedStyle(e.target)
          .getPropertyValue('background-image');
        const hasBackgroundImage = elementBackground.includes('url');

        const hasChildren = e.target.children.length > 0;
        const hasTextContent = hasTextInChildren(e.target);
        const actionElement = byElements?.includes(
          e.target.tagName.toLowerCase()
        );

        if (
          hasBackgroundImage ||
          !hasChildren ||
          hasTextContent ||
          actionElement
        ) {
          if (byElements) {
            const checkElement = checkIsByElements(byElements, e.target);
            if (checkElement && !byElements.includes('form')) {
              setSelectedElement(e.target);
              getSelectedElement(e.target);
              handleMouseClick(
                e,
                () => onOpenModal(true),
                windowRef,
                byElements
              );
            }
            if (byElements.includes('form')) {
              const formInElement = e.target.querySelector('form');
              if (formInElement) {
                setSelectedElement(formInElement);
                getSelectedElement(formInElement);
                handleMouseClick(
                  e,
                  () => onOpenModal(true),
                  windowRef,
                  byElements
                );
              } else {
                const formOutElement = e.target.closest('form');
                setSelectedElement(formOutElement);
                getSelectedElement(formOutElement);
                handleMouseClick(
                  e,
                  () => onOpenModal(true),
                  windowRef,
                  byElements
                );
              }
            }
          } else {
            setSelectedElement(e.target);
            getSelectedElement(e.target);
            handleMouseClick(e, () => onOpenModal(true), windowRef);
          }
        }
      } else {
        setSelectedElement(undefined);
        getSelectedElement(undefined);
      }
    },
    handleMouseOver: (e: any) => handleMouseOver(e, byElements),
    handleMouseOut: (e: any) => handleMouseOut(e, byElements),
    setSelectedElements: (doc: any) =>
      setSelectedElements(
        doc,
        list.filter((e) => !e.deletion),
        windowRef,
        handleLocalElement
      ),
  };
};

const checkIsByElements = (byElements: string[], element: any) => {
  const tagName = element.tagName.toLowerCase();
  const argType = element.getAttribute('type');
  const isCanSelect = byElements.find(
    (item) => item === tagName || item.includes(argType)
  );
  return !!isCanSelect;
};

// const findButtonElement = (element: any) => {
//   let parentElement = element;
//   if (element.tagName === 'IMG') return element;
//   while (parentElement) {
//     if (parentElement.tagName === 'A' || parentElement.tagName === 'BUTTON') {
//       return parentElement;
//     }
//     parentElement = parentElement.parentElement;
//   }
//   return element;
// };

const cleanedPath = (xpath: string) => {
  const cssXpath = xpath
    .replace(
      /\[(\w+)=(["'])(.*?)\2\]/g,
      function (_match, attr, _quote, value) {
        if (attr === 'id') {
          return '.' + value;
        } else if (attr === 'class') {
          return '.' + value.split(' ')[0];
        } else {
          return '';
        }
      }
    )
    .replace(/\[(\w+)\]/g, '')
    .replace(/>/g, ' > ')
    .replace(/(\w+)\[/g, '$1 > ')
    .replace(/\]/g, '')
    .replace(/ {2,}/g, ' ');
  let isNth = false;
  return cssXpath
    .split(' > ')
    .reverse()
    .map((it) => {
      if (!isNth) {
        isNth = it.includes(':nth');
      }
      if (isNth) {
        return it.includes(':nth') ? it.split(':nth')[0] : it.split('.')[0];
      }
      return it;
    })
    .reverse()
    .join(' > ');
};
