import Table from 'src/components/table/table';
import Chart, { ChartSkeleton } from 'src/components/chart/chart';
import { isSignificant } from 'src/helpers/cdf';
import {
  ChartData,
  DetailCampaignAd,
  FilterCalendarDate,
  TStatistic,
} from 'src/store/services/create-campaign/types';

import { TABLE_DETAIL_COLUMN } from './constants';

import useDetailDataTransform from './hooks/useDetailDataTransform';

import CampaignsOverviewComponent from '../campaigns/blocks/campaigns-overview';
import StatusLine, {
  StatusLineSkeleton,
} from '../campaigns/blocks/status-line';

interface IDetailProp {
  campaignID: string;
  url: string;
  ads: DetailCampaignAd[];
  setFilterParams: (param: FilterCalendarDate) => void;
  handleOpenPreview: (id: string, type: string) => void;
  lastCampaignDate: Date;
  chartData: ChartData | null;
  total?: TStatistic;
  isLoading?: boolean;
  chartLoading?: boolean;
}

const DetailCampaign = ({
  campaignID,
  ads,
  setFilterParams,
  handleOpenPreview,
  lastCampaignDate,
  chartData,
  total,
  isLoading,
  chartLoading,
}: IDetailProp) => {
  const { adsTransform } = useDetailDataTransform(ads, campaignID);

  const totalLine = total
    ? {
        experimental_conversions: total.experimental_conversions || 0,
        experimental_visitors: total.experimental_visitors || 0,
        ctrl_conversions: total.ctrl_conversions || 0,
        ctrl_visitors: total.ctrl_visitors || 0,
      }
    : {
        experimental_conversions: 0,
        experimental_visitors: 0,
        ctrl_conversions: 0,
        ctrl_visitors: 0,
      };

  return (
    <div>
      {isLoading ? (
        <StatusLineSkeleton />
      ) : (
        <StatusLine
          status={ads[0].campaign.user_campaign.status}
          distribution={ads[0].campaign.user_campaign.distribution_type}
          googleCampaignTitle={ads[0].campaign.name}
          createdAt={ads[0].campaign.user_campaign.created_at}
          campaignURL={ads[0].campaign.user_campaign.page_url}
        />
      )}
      <CampaignsOverviewComponent
        onChangeDate={(start, end) => {
          setFilterParams({
            id: campaignID,
            startDate: Intl.DateTimeFormat('sv-SE').format(start),
            endDate: Intl.DateTimeFormat('sv-SE').format(end),
          });
        }}
        onChangeVersion={(version) => {
          setFilterParams({
            id: campaignID,
            version: version,
          });
        }}
        onChangeAds={(ads) => {
          setFilterParams({
            id: campaignID,
            ads: ads,
          });
        }}
        onChangeDevice={(deviceValue) => {
          setFilterParams({
            id: campaignID,
            is_desktop: deviceValue,
          });
        }}
        onChangeConversion={(conversions) => {
          setFilterParams({
            id: campaignID,
            conversions: conversions,
          });
        }}
        visitors={totalLine.ctrl_visitors || 0}
        experimental_visitors={totalLine.experimental_visitors || 0}
        leads={totalLine.ctrl_conversions || 0}
        experimental_leads={totalLine.experimental_conversions || 0}
        isSignificant={isSignificant(
          totalLine.experimental_conversions || 0,
          totalLine.experimental_visitors || 0,
          totalLine.ctrl_conversions || 0,
          totalLine.ctrl_visitors || 0
        )}
        show={true}
        lastCampaignDate={lastCampaignDate}
        ads={ads}
        isLoading={chartLoading || isLoading}
      />
      {chartLoading ? (
        <div className="py-6">
          <ChartSkeleton />
        </div>
      ) : (
        chartData && (
          <div className="py-6">
            <Chart data={chartData} />
          </div>
        )
      )}
      <div className="py-4">
        <Table
          type="light"
          data={adsTransform}
          columns={TABLE_DETAIL_COLUMN({
            handleOpenPreview,
          })}
          stickHead
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default DetailCampaign;
