import { parseResponse } from 'src/store/services/helpers';
import { useGetSegmentByIdQuery } from 'src/store/services/segments/endpoints/segments';

const useUpdateDataTransform = (id: string) => {
  const { isLoading, data, error } = useGetSegmentByIdQuery(id);
  const { result, errorMessage } = parseResponse({ data, error });
  let segmentName = '';
  const attributeSorting: { [key: string]: any } = {};

  if (result) {
    const { segment, attributes } = result.data;
    segmentName = segment.name;

    attributes.forEach((item) => {
      if (!attributeSorting[item.id]) {
        attributeSorting[item.id] = {
          id: item.id,
          section: item.name,
          childAttributes: [
            {
              item: item.child_attribute.name,
              value: item.child_attribute.id,
            },
          ],
        };
        return;
      }

      attributeSorting[item.id].childAttributes.push({
        item: item.child_attribute.name,
        value: item.child_attribute.id,
      });
    });
  }

  return {
    isLoading,
    segment: {
      name: segmentName,
      attributes: Object.values(attributeSorting),
    },
    error: result ? undefined : errorMessage,
  };
};

export default useUpdateDataTransform;
