import Button from 'src/components/button';
import ModalBox from 'src/components/modal-box/modal-box';
import { useAppDispatch } from 'src/store/hooks';
import {
  setCopyToAllAd,
  setCopyToEmptyAd,
} from 'src/store/slices/campaignSlice';

interface IModalProps {
  isOpen: boolean;
  adID: number;
  onClose: () => void;
}

const CopyAdModal = ({ isOpen, adID, onClose }: IModalProps) => {
  const dispatch = useAppDispatch();
  const handleCopyToAllAdValues = () => {
    dispatch(setCopyToAllAd(adID));
    onClose();
  };

  const handleCopyToEmptyAdValues = () => {
    dispatch(setCopyToEmptyAd(adID));
    onClose();
  };

  return (
    <ModalBox isOpen={isOpen} onClose={onClose} windowClassName="max-w-md">
      <ModalBox.Body>
        <div className="text-center">
          <p>
            Copy these values to other ads’ personalization elements in this
            campaign?
          </p>
        </div>
      </ModalBox.Body>
      <ModalBox.Footer className="border-t-0">
        <div className="flex justify-between">
          <Button
            title="Copy to All"
            type="default"
            onClick={handleCopyToAllAdValues}
          />
          <Button
            title="Copy to Empty Only"
            type="default"
            onClick={handleCopyToEmptyAdValues}
          />
          <Button title="Cancel" type="outline" onClick={onClose} />
        </div>
      </ModalBox.Footer>
    </ModalBox>
  );
};

export default CopyAdModal;
