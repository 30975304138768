import { segmentsPageAPI } from '..';
import { ResponseWrap } from '../../response_wrap';
import {
  SegmentAttribute,
  SegmentChildAttribute,
  SegmentUpdate,
  SegmentsData,
  UpdateSegment,
  UseSegmentDto,
} from '../types';

export const segmentsAPI = segmentsPageAPI.injectEndpoints({
  endpoints: (builder) => ({
    getAllSegments: builder.query<ResponseWrap<SegmentsData[]>, void>({
      query: () => ({
        url: `api/segments/`,
      }),
      providesTags: ['getAllSegments'],
    }),
    getSegmentById: builder.query<ResponseWrap<SegmentUpdate>, string>({
      query: (id) => ({
        url: `api/segments/${id}`,
      }),
    }),
    getAllSegmentsAttributes: builder.query<
      ResponseWrap<SegmentAttribute[]>,
      void
    >({
      query: () => ({
        url: `api/segments/attributes`,
      }),
    }),
    getSegmentsAttributesChild: builder.query<
      ResponseWrap<SegmentChildAttribute[]>,
      string
    >({
      query: (id) => ({
        url: `api/segments/attributes/all/childs/${id}`,
      }),
    }),
    createSegment: builder.mutation<ResponseWrap<any>, UseSegmentDto>({
      query: (segment) => ({
        method: 'POST',
        url: 'api/segments/',
        body: segment,
        invalidatesTags: ['getAllSegments'],
      }),
    }),
    updateSegment: builder.mutation<ResponseWrap<any>, UpdateSegment>({
      query: ({ segment, id }) => ({
        method: 'PUT',
        url: `api/segments/${id}`,
        body: segment,
        invalidatesTags: ['getAllSegments'],
      }),
    }),
    deleteSegment: builder.mutation<ResponseWrap<any>, number>({
      query: (id) => ({
        method: 'DELETE',
        url: `api/segments/${id}`,
        invalidatesTags: ['getAllSegments'],
      }),
    }),
  }),
});

export const {
  useGetAllSegmentsQuery,
  useLazyGetAllSegmentsQuery,
  useGetSegmentByIdQuery,
  useLazyGetSegmentByIdQuery,
  useGetAllSegmentsAttributesQuery,
  useLazyGetSegmentsAttributesChildQuery,

  useCreateSegmentMutation,
  useUpdateSegmentMutation,
  useDeleteSegmentMutation,
} = segmentsAPI;
