const getAllKeys = (obj: any): string[] => {
  const keys: string[] = [];
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        if (key === 'category') {
          keys.push(key);
        }
        const nestedKeys = getAllKeys(obj[key] as object);
        keys.push(...nestedKeys.map((nestedKey) => `${nestedKey}`));
      } else {
        keys.push(key);
      }
    }
  }

  return keys;
};

export default getAllKeys;
