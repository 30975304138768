import { ResponseWrap } from '../../response_wrap';
import { authPageAPI } from '..';
import { AuthRequest, AuthResponse } from '../types';

export const authAPI = authPageAPI.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<ResponseWrap<AuthResponse>, AuthRequest>({
      query: (credentials) => ({
        url: 'api/auth',
        credentials: 'include',
        method: 'POST',
        body: credentials,
      }),
    }),
    logoutQuery: builder.mutation<{ message: string }, void>({
      query: () => ({
        url: 'api/auth',
        credentials: 'include',
        method: 'DELETE',
      }),
    }),
    protected: builder.mutation<{ message: string }, void>({
      query: () => 'protected',
    }),
  }),
});

export const { useLoginMutation, useLogoutQueryMutation } = authAPI;
