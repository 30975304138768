import { useEffect, useMemo, useState } from 'react';
import { HiExclamation, HiCheckCircle } from 'react-icons/hi';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { parseError } from 'src/store/services/helpers';
import { useLazyGetActionsQuery } from 'src/store/services/conversion-action/endpoints/conversion-action';
import { useGetCampainDataQuery } from 'src/store/services/create-campaign/endpoints/create-campaign';
import { setGlobalLoading, setNextStep } from 'src/store/slices/storageSlice';
import {
  setAdsElements,
  setGoogleAdsReset,
} from 'src/store/slices/campaignSlice';

import Table from 'src/components/table/table';
import { customToastMessage } from 'src/components/toast';

import { TABLE_STEP_THREE_COLUMNS } from './constants';
import useStepDataTransform from './hooks/useStepThreeDataTransform';
import CreateCampaignFooter from './blocks/create-campaign-footer';
import useSaveAsDraft from './hooks/useSaveAsDraft';
import useGPT from './hooks/useGPT';
import CopyAdModal from './blocks/copy-ad-modal';
import CompanyPreviewModal from './blocks/company-preview-modal';

const StepThree = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useGetCampainDataQuery(id as string, { skip: !id });
  const dispatch = useAppDispatch();
  const [getActions] = useLazyGetActionsQuery();
  const { campaignID, segmentID } = useAppSelector(
    (state) => state.createCampaignReducer
  );
  const { currentAds, totalAds } = useAppSelector(
    (state) => state.createCampaignReducer
  );
  const {
    adsCount,
    totalKeywordsCount,
    searchValue,
    currentPage,
    step,
    googleAdsData,
    handlePageChange,
    handleStepChange,
    handleSearch,
    totalCountAds,
    paginatorLoading,
  } = useStepDataTransform();
  const [generateDisabled, setGenerateDisabled] = useState(false);
  const [addId, setAddId] = useState('');
  const [previewType, setPreviewType] = useState('');
  const [nextLoad, setNextLoad] = useState(false);
  const [isOpenCompanyPreviewModal, setIsOpenCompanyPreviewModal] =
    useState(false);
  const [copyModalOpen, setCopyModalOpen] = useState<number>();
  const {
    handleSave,
    handlePreviewSave,
    errorMessage,
    isLoading: isLoadingSaveDraft,
  } = useSaveAsDraft();

  const showToast = (message: string, status: string) => {
    customToastMessage(message, <HiExclamation className="h-5 w-5" />, status);
  };

  const isUpdateCampaign = useMemo(
    () => pathname.includes('update'),
    [pathname]
  );

  const GPTData = useGPT(showToast, googleAdsData, setGenerateDisabled);

  const handleNextStep = async () => {
    setNextLoad(true);
    await handleSave();
    await getActions();
    if (!errorMessage) {
      navigate('?step=4');
      dispatch(setNextStep());
    }
    setNextLoad(false);
  };

  const handleChangesReset = (elementID: number) => {
    dispatch(setGoogleAdsReset(elementID));
  };

  const openPreview = ({ domain = '', id = '', preview_type = '' }) => {
    if (isLoadingSaveDraft) return false;
    const draftGeneration = Math.random()
      .toString(36)
      .slice(2, 2 + 6);

    const windowReference = window.open();
    handlePreviewSave(id, draftGeneration).then((isSave) => {
      const previewAds = googleAdsData.find((item: any) => item.id === id);
      const haveParams = previewAds?.url.includes('?');
      const url = encodeURI(
        previewAds?.url +
          `${
            haveParams ? '&' : '?'
          }pipe_preview=true&campaign_id=${campaignID}&ad_id=${id}&type=${preview_type}&domain=${domain}&draft=${draftGeneration}`
      );
      if (isSave) {
        if (windowReference) windowReference.location = url;
      } else {
        window.close();
      }
    });
  };

  const handleOpenPreview = (id: string, preview_type: string) => {
    setAddId(id);
    setPreviewType(preview_type);
    if (preview_type === 'company') {
      setIsOpenCompanyPreviewModal(true);
      return;
    }
    openPreview({ id, preview_type });
  };

  const handleCloseCompanyPreviewModal = (domain: string) => {
    setIsOpenCompanyPreviewModal(false);
    openPreview({ domain, preview_type: previewType, id: addId });
  };

  const handleSaveAsDraft = async () => {
    dispatch(setGlobalLoading(true));
    const campaign = await handleSave();
    if (campaign && !errorMessage) {
      dispatch(setGlobalLoading(false));
      customToastMessage(
        `Campaign successfully ${campaignID ? 'updated' : 'created'}.`,
        <HiCheckCircle className="h-5 w-5" />,
        'success'
      );
    }
    if (isUpdateCampaign) {
      dispatch(setGlobalLoading(false));
      navigate('/campaigns');
    }
  };

  const handleSelectVariable = (
    variable: string,
    elementIndex: number,
    adsID: number
  ) => {
    dispatch(
      setAdsElements({
        adsID,
        elementIndex,
        newElementValue: variable,
      })
    );
  };

  const handleCopyModalOpen = (id: number) => {
    setCopyModalOpen(id);
  };

  useEffect(() => {
    if (errorMessage) {
      const { data } = parseError(errorMessage);
      customToastMessage(
        data.message,
        <HiExclamation className="h-5 w-5" />,
        'error'
      );
    }
  }, [errorMessage]);

  useEffect(() => {
    if (!GPTData.fetchLoading) {
      setGenerateDisabled(false);
    }
  }, [GPTData.fetchLoading]);

  return (
    <div className="mt-20">
      <Table
        type="even-bg"
        headerContent={{
          countAds: adsCount,
          countKeywords: totalKeywordsCount,
          searchTitle: searchValue,
          withAdFilter: true,
          onSearchChange: (searchTitle: string) => handleSearch(searchTitle),
        }}
        columns={TABLE_STEP_THREE_COLUMNS({
          generateDisabled: generateDisabled,
          onElementGPTGenerate: GPTData.handleGPTGenerate,
          onElementGPTFixGrammar: GPTData.handleGPTFixText,
          onElementGPTImprove: GPTData.handleGPTImproveText,
          onElementBackAnswer: GPTData.handleBackAnswer,
          onPreviewClick: handleOpenPreview,
          onResetClick: handleChangesReset,
          handleVariableSelect: handleSelectVariable,
          copyToAll: handleCopyModalOpen,
        })}
        data={googleAdsData}
        paginatorContent={{
          currentPage: currentPage,
          totalPages: totalCountAds,
          step: step,
          onPageChange: (e: any) => handlePageChange(e),
          onChangeStep: (e: any) => handleStepChange(e),
          disabledNext: currentAds !== totalAds,
          tooltipTitle:
            currentAds !== totalAds
              ? 'Wait for the AI to complete the prefill'
              : '',
        }}
        isLoading={paginatorLoading}
      />
      <CreateCampaignFooter
        handleNextStep={() => handleNextStep()}
        disabledNextButton={false}
        onDraftSave={handleSaveAsDraft}
        nextButtonLoading={nextLoad}
        saveTitle={isUpdateCampaign ? 'Save and Exit' : undefined}
      />
      <CompanyPreviewModal
        isOpen={isOpenCompanyPreviewModal}
        onClose={() => {
          setIsOpenCompanyPreviewModal(false);
        }}
        onSave={handleCloseCompanyPreviewModal}
        segmentID={segmentID}
      />
      {copyModalOpen && (
        <CopyAdModal
          adID={copyModalOpen}
          isOpen={!!copyModalOpen}
          onClose={() => {
            setCopyModalOpen(undefined);
          }}
        />
      )}
    </div>
  );
};

export default StepThree;
