import jwt_decode from 'jwt-decode';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { User } from '../services/user/types';
import { authAPI } from '../services/auth/endpoints/auth';
import { AuthResponse } from '../services/auth/types';

export interface AuthState {
  user: User | null;
  access_token: string | null;
  isAuthenticated: boolean;
}

const TOKEN_FIELD = 'token';

const initialState: AuthState = {
  user: null,
  access_token: localStorage.getItem(TOKEN_FIELD),
  isAuthenticated: localStorage.getItem(TOKEN_FIELD) != null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<AuthResponse>) => {
      state.access_token = action.payload.access_token;
      if (state.access_token == null) return;
      localStorage.setItem(TOKEN_FIELD, state.access_token);
      state.isAuthenticated = true;
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    loadUser: (state) => {
      const token = localStorage.getItem(TOKEN_FIELD);
      if (token === null) {
        state.isAuthenticated = false;
        return;
      }

      const decodedToken = jwt_decode<{
        username: string;
        email: string;
        sub: string;
      }>(token);

      state.user = {
        ...state.user,
        id: parseInt(decodedToken.sub),
        email: decodedToken.email,
        username: decodedToken.username,
      };
    },
    logout: (state) => {
      state.access_token = null;
      state.user = null;
      state.isAuthenticated = false;
      localStorage.removeItem(TOKEN_FIELD);
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        authAPI.endpoints.login.matchFulfilled,
        (state, { payload }) => {
          state.access_token = payload.data.access_token;
        }
      )
      .addMatcher(authAPI.endpoints.logoutQuery.matchFulfilled, (state) => {
        state.access_token = null;
        state.user = null;
        state.isAuthenticated = false;
        localStorage.removeItem(TOKEN_FIELD);
      });
  },
});

export const { setCredentials, setUser, loadUser, logout } = authSlice.actions;
export default authSlice.reducer;
