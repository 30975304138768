import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { setStep } from 'src/store/slices/storageSlice';

import BreadCrumbs from 'src/components/bread-crumbs';
import Stepper from 'src/components/stepper';

import StepOne from 'src/features/create-campaign/StepOne';
import StepTwo from 'src/features/create-campaign/StepTwo';
import StepThree from 'src/features/create-campaign/StepThree';
import StepFour from 'src/features/create-campaign/StepFour';
import { useBrowserNavigation } from 'src/features/create-campaign/hooks/useBrowserNavigation';

const STEPS_TITLES = [
  'Define Segment',
  'Select Personalization Elements',
  'Set Personalization Rules',
  'Launch',
];

const STEP_ITEMS = [
  <StepOne key="StepOne" />,
  <StepTwo key="StepTwo" />,
  <StepThree key="StepThree" />,
  <StepFour key="StepFour" />,
];

const StepNavigation = React.memo(() => {
  const { campaignStep } = useAppSelector((state) => state.storageReducer);
  return (
    <>
      <Stepper items={STEPS_TITLES} activeStep={campaignStep} />
      {STEP_ITEMS[campaignStep - 1]}
    </>
  );
});
StepNavigation.displayName = 'StepNavigation';

const UpdateCampaignPage = () => {
  const dispatch = useAppDispatch();
  const { campaignName } = useAppSelector(
    (state) => state.createCampaignReducer
  );

  const [searchParams] = useSearchParams();
  useBrowserNavigation();
  useEffect(() => {
    const currentStep = searchParams.get('step');
    if (currentStep) {
      dispatch(setStep(+currentStep));
    }
  }, [dispatch, searchParams]);

  return (
    <section className="flex flex-col h-auto py-6 px-10">
      <div className="mb-4">
        <BreadCrumbs
          items={[
            { title: 'Campaigns', link: '/campaigns' },
            {
              title: 'Update Personalization Campaign',
              link: '/update-campaign',
            },
          ]}
        />
      </div>
      <h1 className="text-2xl font-semibold mb-[42px]">{campaignName}</h1>
      <StepNavigation />
    </section>
  );
};

export default UpdateCampaignPage;
