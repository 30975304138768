import { HiArrowRight } from 'react-icons/hi';
import { QuestionTooltip } from 'src/components/icons';
import Tooltip from 'src/components/tooltip';

interface ICRCard {
  percent: string;
  isPersonalized: boolean;
  isPositive?: boolean;
  isControlZero?: boolean;
}

const CRCard = ({
  percent,
  isPersonalized,
  isPositive,
  isControlZero,
}: ICRCard) => {
  return (
    <div>
      {isPersonalized &&
        (!isControlZero ? (
          <p
            className={`flex justify-end items-center text-xs font-medium ${
              isPositive ? 'text-green-500' : 'text-red-500'
            }`}
          >
            {percent}
            <HiArrowRight
              className={`ml-2 ${isPositive ? '-rotate-45' : 'rotate-45'}`}
              color={isPositive ? '#0E9F6E' : '#F05252'}
            />
          </p>
        ) : (
          <div className="flex justify-end items-center">
            <Tooltip
              className="ml-1"
              hoverElement={
                <QuestionTooltip color={isPositive ? '#0E9F6E' : '#F05252'} />
              }
              position="center"
            >
              <div className="max-w-[192px]">
                <p className="mb-1.5 leading-none text-sm font-medium text-gray-900 text-left normal-case">
                  CR Lift
                </p>
                <p className="leading-tight text-xs font-normal text-gray-500 text-left normal-case">
                  {isPositive
                    ? 'Due to zero control conversions, CR Lift cannot be measured, yet there is a positive trend in CR (Pers.).'
                    : 'Due to zero personalized conversions, CR Lift cannot be measured.'}
                </p>
              </div>
            </Tooltip>

            <HiArrowRight
              className={`ml-2 ${isPositive ? '-rotate-45' : 'rotate-45'}`}
              color={isPositive ? '#0E9F6E' : '#F05252'}
            />
          </div>
        ))}
    </div>
  );
};

export default CRCard;
