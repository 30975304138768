import { userAPI } from '..';
import { ResponseWrap, SuccessMessageResponse } from '../../response_wrap';
import { ChangePasswordRequest, UserProfile } from '../types';

export const userDataAPI = userAPI.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query<ResponseWrap<UserProfile>, void>({
      query: () => ({
        url: 'api/users/profile',
      }),
    }),
    checkVerifyUrl: builder.query<ResponseWrap<boolean>, void>({
      query: () => ({
        url: 'api/users/check-verify-url',
      }),
    }),
    changePassword: builder.mutation<
      ResponseWrap<SuccessMessageResponse>,
      ChangePasswordRequest
    >({
      query: (body) => ({
        url: 'api/users/password',
        method: 'PUT',
        body: body,
      }),
    }),
  }),
});

export const {
  useGetProfileQuery,
  useCheckVerifyUrlQuery,
  useLazyGetProfileQuery,

  useChangePasswordMutation,
} = userDataAPI;
