import { clientDomainPageAPI } from '..';
import { ResponseWrap } from '../../response_wrap';
import { DomainResponse, DomainUrlsResponse } from '../types';

export const clientDomainAPI = clientDomainPageAPI.injectEndpoints({
  endpoints: (builder) => ({
    getAllUserDomains: builder.query<ResponseWrap<DomainUrlsResponse[]>, void>({
      query: () => ({
        url: '/api/domain/load/all',
      }),
    }),
    checkScriptStatus: builder.query<any, { id: string }>({
      query: (params) => ({
        url: `/api/domain/check/item/${params.id}`,
      }),
    }),
    checkUserDomain: builder.query<DomainResponse, { url: string }>({
      query: (params) => ({
        url: `api/domain/check?url=${params.url}`,
      }),
      transformResponse: (response: ResponseWrap<DomainResponse>) =>
        response.data,
    }),
    addDomain: builder.mutation<
      ResponseWrap<DomainUrlsResponse>,
      { url: string }
    >({
      query: (body) => ({
        url: '/api/domain/add',
        method: 'POST',
        body: body,
      }),
    }),
    postChangeDomainUrl: builder.mutation<
      ResponseWrap<DomainUrlsResponse>,
      { id: string; url: string }
    >({
      query: (body) => ({
        url: '/api/domain/update',
        method: 'POST',
        body: body,
      }),
    }),
    removeDomain: builder.mutation<
      ResponseWrap<string>,
      { id: string; url: string }
    >({
      query: (body) => ({
        url: '/api/domain/delete',
        method: 'POST',
        body: body,
      }),
    }),
  }),
});

export const {
  useGetAllUserDomainsQuery,
  useCheckUserDomainQuery,
  useCheckScriptStatusQuery,

  useLazyGetAllUserDomainsQuery,
  useLazyCheckUserDomainQuery,
  useLazyCheckScriptStatusQuery,

  useAddDomainMutation,
  usePostChangeDomainUrlMutation,
  useRemoveDomainMutation,
} = clientDomainAPI;
