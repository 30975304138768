import { useCallback, useState } from 'react';
import BreadCrumbs from 'src/components/bread-crumbs';
import { AddCross } from 'src/components/icons';
import Button from 'src/components/button';
import SyncAccessModal from 'src/features/campaigns/blocks/sync-access-modal';
import useSyncCamapaign from 'src/features/campaigns/hooks/useSyncCampaign';
import { SyncStatus } from 'src/store/services/google/types';
import { Campaigns } from '../features/campaigns/campaigns';
import ModalBox from 'src/components/modal-box/modal-box';

export function CampaignsPage() {
  const [openSyncModal, setOpenSyncModal] = useState(false);
  const { isSyncing, handleRedirectPage } = useSyncCamapaign();

  const handleCloseModal = useCallback(() => {
    setOpenSyncModal(false);
  }, []);

  const handleCreateCampaign = (url: string) => {
    if (SyncStatus[isSyncing] !== 'InProgress') {
      handleRedirectPage(url);
    } else {
      setOpenSyncModal(true);
    }
  };

  return (
    <section className="h-auto flex flex-col h-full py-6 px-10">
      <SyncAccessModal />
      <div className="mb-4">
        <BreadCrumbs items={[{ title: 'Campaigns', link: '/campaigns' }]} />
      </div>
      <div className="flex items-center justify-between mb-[42px]">
        <h1 className="text-2xl font-semibold">Personalization Campaigns</h1>
        <Button
          type="default"
          title="New Personalization Campaign"
          onClick={() => handleCreateCampaign('/campaigns/create-campaign')}
          Icon={<AddCross />}
        />
      </div>
      <Campaigns
        syncInProgress={SyncStatus[isSyncing] === 'InProgress'}
        handleRedirectPage={handleCreateCampaign}
      />
      <ModalBox
        isOpen={openSyncModal}
        onClose={handleCloseModal}
        windowClassName="max-w-md"
      >
        <ModalBox.Body>
          <p className="text-sm font-medium text-[#111928] text-center">
            Synchronization with Google Ads is in progress.
            <br />
            Wait for it to finish before creating a Personalization Campaign
          </p>
        </ModalBox.Body>
        <ModalBox.Footer className="border-t-0 pt-0">
          <div className="flex justify-center">
            <Button
              type="outline"
              title="Ok"
              className="min-w-[100px] [&]:mr-0 justify-center"
              onClick={handleCloseModal}
            />
          </div>
        </ModalBox.Footer>
      </ModalBox>
    </section>
  );
}
