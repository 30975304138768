import { useState } from 'react';
import { Table } from 'flowbite-react';
import { format } from 'date-fns';
import { HiCheckCircle, HiExclamation, HiDotsHorizontal } from 'react-icons/hi';
import {
  ConversionActionType,
  ConversionActionItem,
} from 'src/store/services/conversion-action/types';
import { useAppSelector } from 'src/store/hooks';
import { parseResponse } from 'src/store/services/helpers';
import {
  useDeleteActionMutation,
  useGetActionsQuery,
} from 'src/store/services/conversion-action/endpoints/conversion-action';

import { customToastMessage } from 'src/components/toast';
import Dropdown, { DropItem } from 'src/components/base-dropdown';
import { Edit, Trush } from 'src/components/icons';
import ModalBox from 'src/components/modal-box/modal-box';
import Button from 'src/components/button';
import Spinner from 'src/components/base-spinner';

interface IActionList {
  handleEdit: (item: ConversionActionItem) => void;
}

const ActionsList = ({ handleEdit }: IActionList) => {
  const { actions } = useAppSelector((state) => state.conversionActionReducer);
  const { isLoading, refetch } = useGetActionsQuery();
  const [deleteAction] = useDeleteActionMutation();
  const [confirmModal, setConfirmModal] = useState<string>();

  const handleCloseConfirmModal = () => {
    setConfirmModal(undefined);
  };

  const handleDelete = async (id: string) => {
    const response = await deleteAction({ id: parseInt(id) });

    const { result, errorMessage, error } = parseResponse(response);
    if (error && errorMessage) {
      customToastMessage(
        errorMessage,
        <HiExclamation className="h-5 w-5" />,
        'error'
      );
    }
    if (result) {
      customToastMessage(
        result.message,
        <HiCheckCircle className="h-5 w-5" />,
        'success'
      );
    }
    refetch();
    setConfirmModal(undefined);
  };

  return (
    <>
      {isLoading && (
        <div className="block items-center p-4 px-10 text-center mt-28">
          <Spinner size="h-6 w-6" />
        </div>
      )}

      {!isLoading && actions.length <= 0 && (
        <div className="block items-center p-4 px-10 text-center mt-28">
          <div className="flex items-center flex-col gap-y-4 select-none relative">
            <img src="/assets/images/empty_conversions.svg" alt="" />
          </div>
          <div className="mt-10 text-gray-700">
            Create your first conversion action by clicking the button in the
            top right corner.
          </div>
        </div>
      )}

      {!isLoading && actions.length > 0 && (
        <div className="px-10 py-4 [&>div]:overflow-x-visible">
          <Table className="divide-y divide-gray-200">
            <Table.Head className="bg-gray-100">
              <Table.HeadCell className="bg-gray-100 uppercase text-xs font-semibold text-gray-700">
                Conversion action
              </Table.HeadCell>
              <Table.HeadCell className="bg-gray-100 uppercase text-xs font-semibold text-gray-700 text-right">
                Conversions
              </Table.HeadCell>
              <Table.HeadCell className="bg-gray-100 uppercase text-xs font-semibold text-gray-700">
                Type
              </Table.HeadCell>
              <Table.HeadCell className="bg-gray-100 uppercase text-xs font-semibold text-gray-700">
                Last conversion
              </Table.HeadCell>
              <Table.HeadCell className="bg-gray-100 uppercase text-xs font-semibold text-gray-700">
                Created
              </Table.HeadCell>
              <Table.HeadCell className="bg-gray-100 w-[54px]"></Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y divide-gray-200 bg-white">
              {actions.map((item) => (
                <Table.Row className="" key={item.id} data-testid="action_row">
                  <Table.Cell className="p-4 text-sm text-gray-900 font-semibold">
                    <span data-testid="action_name">{item.name}</span>
                  </Table.Cell>
                  <Table.Cell className="p-4 text-right text-gray-900 text-sm font-medium">
                    {item.count_conversions}
                  </Table.Cell>
                  <Table.Cell className="p-4 text-gray-900 text-sm font-medium whitespace-nowrap">
                    {ConversionActionType[item.action_type].replaceAll(
                      '_',
                      ' '
                    )}
                  </Table.Cell>
                  <Table.Cell className="p-4 text-gray-900 text-sm font-medium whitespace-nowrap">
                    {item.last_conversion &&
                      format(Date.parse(item.last_conversion), 'dd-MMM-yyyy')}
                  </Table.Cell>
                  <Table.Cell className="p-4 text-gray-900 text-sm font-medium whitespace-nowrap">
                    {format(Date.parse(item.created_at), 'dd-MMM-yyyy')}
                  </Table.Cell>
                  <Table.Cell className="p-4 text-gray-900 text-sm font-medium flex justify-center">
                    <Dropdown
                      label={
                        <div
                          className="flex items-center justify-center bg-gray-100 w-[22px] h-[22px] rounded"
                          data-testid="action_change_dropdown_button"
                        >
                          <HiDotsHorizontal />
                        </div>
                      }
                      className="[&>div]:py-0"
                    >
                      <DropItem
                        className="py-2"
                        icon={<Edit className="mr-2" />}
                        onClick={() => handleEdit(item)}
                      >
                        <span
                          className="text-sm font-normal text-gray-700"
                          data-testid="action_update_button"
                        >
                          Edit
                        </span>
                      </DropItem>
                      <DropItem
                        className="py-2"
                        icon={<Trush className="mr-3" color="#E02424" />}
                        onClick={() => setConfirmModal(item.id)}
                      >
                        <span
                          className="text-sm font-normal text-red-600"
                          data-testid="action_delete_button"
                        >
                          Delete
                        </span>
                      </DropItem>
                    </Dropdown>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      )}

      <ModalBox
        isOpen={!!confirmModal}
        onClose={handleCloseConfirmModal}
        windowClassName="max-w-md"
      >
        <ModalBox.Body>
          <p className="mb-4 text-base font-bold text-[#111928]">
            Are you sure you want to delete this action?{' '}
          </p>
          <div className="flex justify-end">
            <Button
              type="danger"
              title="Delete"
              className="mr-4"
              onClick={() => handleDelete(confirmModal || '')}
            />
            <Button
              type="gray"
              title="Cancel"
              className="[&]:bg-gray-200"
              onClick={handleCloseConfirmModal}
            />
          </div>
        </ModalBox.Body>
      </ModalBox>
    </>
  );
};

export default ActionsList;
