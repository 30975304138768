import { Checkbox, Label } from 'flowbite-react';

interface IBaseCheckbox {
  id: string;
  checked: boolean;
  title?: string;
  onHandleCheck: (e?: any) => void;
}

const BaseCheckbox = ({ id, checked, title, onHandleCheck }: IBaseCheckbox) => {
  return (
    <div className="flex gap-2">
      <Checkbox
        id={id}
        className={`cursor-pointer ${title && 'mr-2'}`}
        onChange={onHandleCheck}
        checked={checked}
      />
      {title && <Label htmlFor={id}>{title}</Label>}
    </div>
  );
};

export default BaseCheckbox;
