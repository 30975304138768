import { ReactNode, FC, useState, useRef } from 'react';
import OutsideClickHandler from 'src/helpers/outside-click';

interface IDropItemProps {
  children: ReactNode;
  onClick?: (e?: any) => void;
  icon?: ReactNode;
  className?: string;
}

interface IDropdownProps {
  label: ReactNode;
  className?: string;
  children: ReactNode;
  position?: 'left' | 'center' | 'right';
}

export const DropItem: FC<IDropItemProps> = ({
  children,
  icon,
  onClick,
  className,
}) => {
  return (
    <button
      className={`flex items-center px-4 w-full ${
        className || ''
      } hover:bg-gray-100`}
      onClick={onClick}
    >
      {icon}
      {children}
    </button>
  );
};

const Dropdown: FC<IDropdownProps> = ({
  label,
  children,
  className,
  position,
}) => {
  const [open, setOpen] = useState(false);
  const menu = useRef<HTMLButtonElement | null>(null);
  const dropdown = useRef<HTMLDivElement | null>(null);

  const handleMenuClicked = () => {
    setOpen(!open);

    if (menu.current && dropdown.current) {
      const windowHeight = window.innerHeight;
      const menuBounds = menu.current.getBoundingClientRect();
      const dropdownElement = dropdown.current;

      const { width: popupWidth, height: popupHeight } =
        dropdownElement.getBoundingClientRect();
      const { top, left, width: menuWidth, height: menuHeigth } = menuBounds;

      const popupPosition =
        position === 'center'
          ? left + menuWidth / 2 - popupWidth / 2
          : position === 'right'
          ? left
          : left + menuWidth - popupWidth;

      const freeSpace = windowHeight - top + menuHeigth;
      const topPosition = top + 320 >= windowHeight;
      const maxHeight = freeSpace > 320 ? 320 : freeSpace;

      dropdownElement.style.cssText = ` 
        top: ${!topPosition ? top + menuHeigth + 8 : top - popupHeight - 8}px; 
        left: ${popupPosition}px; 
        max-height: ${topPosition ? 320 : maxHeight}px;
      `;
    }
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
      <div className={`relative ${className || ''}`}>
        <button ref={menu} className="bg-inherit" onClick={handleMenuClicked}>
          {label}
        </button>
        <div
          ref={dropdown}
          onClick={() => setOpen(false)}
          className={`${
            open
              ? 'opacity-100 z-[60] pointer-events-auto'
              : 'opacity-0 -z-[1] pointer-events-none'
          } fixed w-max bg-white right-0 py-2 border border-gray-200 rounded-xl shadow-md overflow-hidden`}
        >
          {children}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Dropdown;
