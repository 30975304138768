import React, { useCallback } from 'react';
import { parseResponse } from 'src/store/services/helpers';
import { useLazyGetSegmentsAttributesChildQuery } from 'src/store/services/segments/endpoints/segments';

const useSegmentChild = (id: string) => {
  const [attributeArray, setAttributeAtrray] = React.useState<any[]>([]);
  const [childError, setChildError] = React.useState('');
  const [getSegmentsAttributesChild, { isLoading }] =
    useLazyGetSegmentsAttributesChildQuery();

  const transformData = useCallback((data: any, parent: string) => {
    const result = data.map((item: any) => {
      const newItem: any = {
        name: item.name,
        value: item.id,
        parentID: parent,
      };

      if (item.child_attribute && item.child_attribute.length > 0) {
        newItem.child = transformData(item.child_attribute, item.id);
      }

      return newItem;
    });

    return result;
  }, []);

  const handleFindChildAttributes = React.useCallback(async () => {
    const { data, error } = await getSegmentsAttributesChild(id);
    const { result, errorMessage } = parseResponse({ data, error });
    if (result) {
      const attributeChilds = transformData(result.data, id);
      setAttributeAtrray(attributeChilds);
    }
    if (error) {
      setChildError(errorMessage);
    }
  }, [getSegmentsAttributesChild, id, transformData]);

  React.useEffect(() => {
    handleFindChildAttributes();
  }, [getSegmentsAttributesChild, handleFindChildAttributes, id]);

  return {
    isLoading,
    segmentAttributes: attributeArray,
    error: childError,
  };
};

export default useSegmentChild;
