import { ElementType } from 'react';
import { Tooltip } from 'react-tooltip';

interface ISidebarButton {
  url: string;
  title: string;
  Icon: ElementType;
  handlePageNavigate?: (url: string, e: any) => void;
  active?: boolean;
  isSidebarOpen?: boolean;
  openInNewTab?: boolean;
}

const SidebarButton = ({
  url,
  title,
  Icon,
  handlePageNavigate,
  active,
  isSidebarOpen,
  openInNewTab,
}: ISidebarButton) => {
  return (
    <button
      className="w-full"
      type="button"
      onClick={(e) => handlePageNavigate && handlePageNavigate(url, e)}
    >
      <a
        href={url}
        className={`flex items-center space-x-2 text-gray-700 hover:bg-gray-200 p-2 rounded-lg ${
          active ? 'bg-blue-100' : ''
        }`}
        target={openInNewTab ? '_blank' : '_self'}
        data-tooltip-id={`sidebar-tooltip-${title}`}
        data-tooltip-place="right"
        data-tooltip-content={title}
      >
        <Icon className="w-6 h-6" color={active ? '#1d4ed8' : '#9ca3af'} />
        <span
          className={`font-normal text-base whitespace-nowrap transition-all duration-300 ${
            active ? 'text-primary-700' : 'text-gray-900'
          } ${isSidebarOpen ? 'inline' : 'hidden'}`}
        >
          {title}
        </span>
      </a>
      <Tooltip
        id={`sidebar-tooltip-${title}`}
        hidden={isSidebarOpen}
        style={{ zIndex: 60 }}
      />
    </button>
  );
};

export default SidebarButton;
