import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkHoursPassed } from 'src/helpers/check-hours-passed';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { setRedirectAfterSync } from 'src/store/slices/storageSlice';

const useSyncCamapaign = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { latestSync, isSyncing } = useAppSelector(
    (state) => state.googleSyncReducer
  );

  const passEigthHoursPerSync = useMemo(
    () => checkHoursPassed(8, latestSync || ''),
    [latestSync]
  );

  const handleRedirectPage = async (url: string) => {
    if (!latestSync) {
      navigate('/integrations');
      return;
    }
    if (!passEigthHoursPerSync) {
      navigate(url);
      return;
    } else {
      dispatch(setRedirectAfterSync(url));
    }
  };

  return {
    isSyncing,
    handleRedirectPage,
  };
};

export default useSyncCamapaign;
