import { useState } from 'react';
import Cropper, { Point } from 'react-easy-crop';
import ModalBox from '../modal-box/modal-box';
import getCroppedImg from './crop-image';

interface ICropModal {
  image: string;
  imageAspect: number;
  isOpen: boolean;
  onClose: () => void;
  onSave: (image: string | Blob) => void;
  imageFormat: string;
}

const CropImageModal = ({
  image,
  imageAspect,
  isOpen,
  onClose,
  onSave,
  imageFormat,
}: ICropModal) => {
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const [zoom, setZoom] = useState(1);
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = async () => {
    try {
      if (image && croppedAreaPixels) {
        const croppedImage = await getCroppedImg(
          image,
          croppedAreaPixels,
          imageFormat
        );
        if (croppedImage) {
          onSave(croppedImage);
          onClose();
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <ModalBox isOpen={isOpen} onClose={onClose} windowClassName="max-w-5xl">
      <ModalBox.Header onClose={onClose}>Select image size</ModalBox.Header>
      <ModalBox.Body>
        <div className="relative w-full h-[475px]">
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={imageAspect}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
      </ModalBox.Body>
      <ModalBox.Footer>
        <button
          onClick={showCroppedImage}
          className="mt-2 bg-blue-500 text-white px-4 py-2 rounded"
        >
          Save Image
        </button>
      </ModalBox.Footer>
    </ModalBox>
  );
};

export default CropImageModal;
