import { BlueChecked } from './icons';
import React from 'react';

interface IStepperProp {
  items: string[];
  activeStep: number;
}

const Stepper = ({ items, activeStep }: IStepperProp) => {
  return (
    <ol className="flex items-center flex-1 text-sm font-medium text-center text-gray-500 small:text-base">
      {items.map((item, index) => (
        <React.Fragment key={`${item}-${index}`}>
          {index !== items.length - 1 ? (
            <>
              <li
                className={`flex items-center ${
                  index + 1 <= activeStep && 'text-blue-600 dark:text-blue-500'
                } whitespace-nowrap `}
              >
                {index + 1 <= activeStep && <BlueChecked className="mr-1.5" />}
                {item}
              </li>
              <span className="flex-1 h-[0.6px] mx-4 bg-gray-200" />
            </>
          ) : (
            <li
              className={`flex items-center ${
                index + 1 <= activeStep && 'text-blue-600 dark:text-blue-500'
              }`}
            >
              {index + 1 <= activeStep && <BlueChecked className="mr-1.5" />}
              {item}
            </li>
          )}
        </React.Fragment>
      ))}
    </ol>
  );
};

export default Stepper;
