import { createCampaignPageAPI } from '..';
import { ResponseWrap } from '../../response_wrap';
import {
  GoogleADSCustomers,
  GoogleCampaigns,
  DraftData,
  CampaignListItem,
  FilterCalendarDate,
  GenerateTextQueryPRop,
  GetCampaignData,
  CampaignVerions,
  FixTextQueryPRop,
  ChartData,
  RecommendationsAds,
  CampaignConversion,
  IExtraCampaignProp,
} from '../types';

export const createCampaignAPI = createCampaignPageAPI.injectEndpoints({
  endpoints: (builder) => ({
    publishAndValidateCampaign: builder.mutation<{ message: string }, number>({
      query: (id) => ({
        url: `api/campaign/${id}/publish`,
        method: 'POST',
      }),
      invalidatesTags: ['getAllCampaign'],
    }),
    pauseCampaign: builder.mutation<{ message: string }, number>({
      query: (id) => ({
        url: `api/campaign/${id}/pause`,
        method: 'POST',
      }),
      invalidatesTags: ['getAllCampaign'],
    }),
    deleteCampaign: builder.mutation<{ message: string }, number>({
      query: (id) => ({
        url: `api/campaign/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['getAllCampaign'],
    }),
    getAllCampaign: builder.query<
      ResponseWrap<{ url: string; campaigns: CampaignListItem[] }>,
      FilterCalendarDate
    >({
      query: ({ startDate, endDate }) => ({
        url: `api/campaign/all?start_date=${startDate ?? ''}&end_date=${
          endDate ?? ''
        }`,
      }),
      providesTags: ['getAllCampaign'],
    }),
    getRecommendationById: builder.query<RecommendationsAds[], string>({
      query: (campaignID) => ({
        url: `api/campaign/load-recommendations/${campaignID}`,
      }),
    }),
    getCampaignById: builder.query<
      ResponseWrap<IExtraCampaignProp>,
      { campaignID: string; filter: any }
    >({
      query: ({ campaignID, filter }) => ({
        url: `api/campaign/all/${campaignID}?start_date=${
          filter.startDate
        }&end_date=${filter.endDate}&version=${filter.version ?? ''}`,
      }),
    }),
    getChartData: builder.query<ChartData, { campaignID: string; filter: any }>(
      {
        query: ({ campaignID, filter }) =>
          `api/campaign/load-chart-data?campaign_id=${campaignID}&startDate=${
            filter.startDate
          }&endDate=${filter.endDate}&part=${
            filter.part || 'day'
          }&google_ad_ids=${filter.ads || ''}&conversion_action_ids=${
            filter.conversions || ''
          }&is_desktop=${filter.is_desktop || ''}`,
      }
    ),
    getCampainData: builder.query<ResponseWrap<GetCampaignData>, string>({
      query: (campaignID) => ({
        url: `api/campaign/updated/${campaignID}`,
      }),
      providesTags: ['getCampainData'],
    }),
    getGoogleAds: builder.query<ResponseWrap<GoogleADSCustomers[]>, void>({
      query: () => ({
        url: 'api/campaign/customers',
      }),
    }),
    getGoogleAdsCampaign: builder.query<
      ResponseWrap<GoogleCampaigns[]>,
      { customerID: string }
    >({
      query: ({ customerID }) => ({
        url: `api/campaign/${customerID}`,
      }),
    }),
    getCampaignDomainsValue: builder.query<ResponseWrap<string[]>, void>({
      query: () => ({
        url: 'api/domain/all',
      }),
    }),
    getCampaignsData: builder.query<ResponseWrap<any>, string>({
      query: (campaignID) => ({
        url: `api/campaign/ads/data/${campaignID}`,
      }),
    }),
    getGenerateText: builder.query<ResponseWrap<string>, GenerateTextQueryPRop>(
      {
        query: (body) => ({
          method: 'POST',
          url: `api/campaign/generate-message`,
          body: body,
        }),
      }
    ),
    getFixedText: builder.query<ResponseWrap<string>, FixTextQueryPRop>({
      query: (body) => ({
        method: 'POST',
        url: `api/campaign/fix-message`,
        body: body,
      }),
    }),
    getImprovedText: builder.query<ResponseWrap<string>, FixTextQueryPRop>({
      query: (body) => ({
        method: 'POST',
        url: `api/campaign/improve-message`,
        body: body,
      }),
    }),
    getCampaignVersions: builder.query<ResponseWrap<CampaignVerions[]>, string>(
      {
        query: (campaignID) => ({
          url: `api/campaign/versions/${campaignID}`,
        }),
      }
    ),
    getCampaignRecommendations: builder.query<RecommendationsAds[], string>({
      query: (campaign_id) => ({
        url: `api/campaign/load-recommendations/${campaign_id}`,
      }),
    }),
    getCampaignConversions: builder.query<CampaignConversion[], string>({
      query: (campaign_id) => ({
        url: `api/campaign/load-conversion-actions/${campaign_id}`,
      }),
    }),
    publishCampaign: builder.mutation<ResponseWrap<string>, DraftData>({
      query: (body) => {
        return body.campaign_id
          ? {
              method: 'POST',
              url: `api/campaign/publish?campaignID=${body.campaign_id}`,
              body: body,
            }
          : {
              method: 'POST',
              url: 'api/campaign/publish',
              body: body,
            };
      },
      invalidatesTags: ['getAllCampaign'],
    }),
    addCustomImage: builder.mutation<ResponseWrap<string>, any>({
      query: (file) => ({
        method: 'POST',
        url: 'api/campaign/upload-image',
        body: file,
      }),
    }),
    createCampaign: builder.mutation<
      ResponseWrap<{ id: string; lastUpdated: string }>,
      DraftData
    >({
      query: (body) => {
        return body.campaign_id
          ? {
              method: 'PUT',
              url: `api/campaign/${body.campaign_id}`,
              body: body,
            }
          : {
              method: 'POST',
              url: 'api/campaign',
              body: body,
            };
      },
      invalidatesTags: ['getAllCampaign'],
    }),
    deleteElement: builder.mutation<ResponseWrap<string>, string>({
      query: (elementID) => ({
        method: 'DELETE',
        url: `api/campaign/element/${elementID}`,
      }),
      invalidatesTags: ['getCampainData'],
    }),
    setPreviewSave: builder.mutation<
      ResponseWrap<any>,
      { adID: string; body: DraftData; draftGeneration?: string }
    >({
      query: ({ adID, body, draftGeneration }) => ({
        method: 'POST',
        url: `api/campaign/save-preview${
          body.campaign_id ? '/' + body.campaign_id : ''
        }/${adID}${draftGeneration ? `?draft=${draftGeneration}` : ''}`,
        body: body,
      }),
    }),
    checkRedirectedUrl: builder.mutation<{ url: string }, string>({
      query: (url) => ({
        method: 'POST',
        url: 'api/domain/get-redirected-url',
        body: { url: url },
      }),
    }),
  }),
});

export const {
  useGetAllCampaignQuery,
  useGetCampainDataQuery,
  useGetGoogleAdsQuery,
  useGetGoogleAdsCampaignQuery,
  useGetCampaignDomainsValueQuery,
  useGetCampaignVersionsQuery,
  useGetCampaignRecommendationsQuery,
  useGetCampaignConversionsQuery,
  useGetRecommendationByIdQuery,
  useGetChartDataQuery,

  useLazyGetAllCampaignQuery,
  useLazyGetCampaignByIdQuery,
  useLazyGetCampaignsDataQuery,
  useLazyGetGenerateTextQuery,
  useLazyGetFixedTextQuery,
  useLazyGetImprovedTextQuery,
  useLazyGetCampainDataQuery,
  useLazyGetCampaignRecommendationsQuery,
  useLazyGetCampaignConversionsQuery,
  useLazyGetRecommendationByIdQuery,
  useLazyGetChartDataQuery,

  usePauseCampaignMutation,
  usePublishAndValidateCampaignMutation,
  useDeleteCampaignMutation,
  usePublishCampaignMutation,
  useAddCustomImageMutation,
  useCreateCampaignMutation,
  useDeleteElementMutation,
  useSetPreviewSaveMutation,
  useCheckRedirectedUrlMutation,
} = createCampaignAPI;
