import { TextInput, Tooltip } from 'flowbite-react';
import { InfoIcon, SearchIcon, Stars } from '../icons';
import SimpleSelect from '../selects/simple-select';

export interface ITableHeader {
  countAds: number;
  countKeywords: number;
  searchTitle: string;
  withAdFilter?: boolean;
  onSearchChange: (searchTitle: string) => void;
  onGptClick?: () => void;
}

const TableHeader = ({
  countAds,
  countKeywords,
  searchTitle,
  withAdFilter,
  onSearchChange,
  onGptClick,
}: ITableHeader) => {
  return (
    <div className="flex items-center justify-between p-4">
      <div className="flex w-2/4 items-center">
        <p className="mr-2.5 text-xs font-medium text-[#6B7280] whitespace-nowrap">
          Ads: {countAds}
        </p>
        <p className="mr-2.5 text-xs font-medium text-[#6B7280] whitespace-nowrap">
          Keywords: {countKeywords}
        </p>
        <div className="mr-2.5">
          <Tooltip
            content="Only ads that lead to the web page specified in the previous step will be displayed"
            trigger="hover"
          >
            <InfoIcon />
          </Tooltip>
        </div>
        <TextInput
          type="text"
          icon={SearchIcon}
          placeholder="Search by Ad id, title, keyword"
          value={searchTitle}
          onChange={(e) => onSearchChange(e.target.value)}
          required={false}
          className="w-full"
        />
      </div>
      {withAdFilter && (
        <div className="flex items-center">
          <p className="mr-2.5 text-xs font-medium text-gray-500">
            Ad data for
          </p>
          <SimpleSelect
            items={[{ label: 'last 30 days', value: 'last 30 days' }]}
            inputClassName="max-w-[138px]"
            onSelect={() => null}
            disabled
            reverse
          />
        </div>
      )}
      {onGptClick && (
        <Tooltip
          content="Generated by ChatGPT 4.0 based on your ad, keywords and element's initial value"
          trigger="hover"
        >
          <button
            type="button"
            className="flex items-center py-2 px-3 border border-[#1A56DB] rounded-lg text-sm font-medium text-[#1A56DB] mr-[20px]"
            onClick={onGptClick}
          >
            <Stars className="mr-2 rotate-[180deg]" />
            Generate all empty fields with ChatGPT
          </button>
        </Tooltip>
      )}
    </div>
  );
};

export default TableHeader;
