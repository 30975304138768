import { useEffect } from 'react';
import BreadCrumbs from 'src/components/bread-crumbs';
import CompanyInfo from 'src/features/company-info/company-info';
import { useAppDispatch } from 'src/store/hooks';
import { useGetCompanyInfoQuery } from 'src/store/services/company-info/endpoints/company-info';
import { useLazyGetProfileQuery } from 'src/store/services/user/endpoints/user';
import { setGlobalLoading } from 'src/store/slices/storageSlice';

const CompanyInfoPage = () => {
  const dispatch = useAppDispatch();
  const { data, isLoading } = useGetCompanyInfoQuery();
  const [getProfile] = useLazyGetProfileQuery();

  useEffect(() => {
    dispatch(setGlobalLoading(isLoading));
  }, [dispatch, isLoading]);

  useEffect(() => {
    if (!data) {
      getProfile();
    }
  }, [data, getProfile]);

  return (
    <section className="flex flex-col h-auto py-6 px-10">
      <div className="mb-4">
        <BreadCrumbs
          items={[{ title: 'Company Info', link: '/company-info' }]}
        />
      </div>
      <h1 className="text-2xl font-semibold">Company Info</h1>
      {!isLoading && <CompanyInfo />}
    </section>
  );
};

export default CompanyInfoPage;
