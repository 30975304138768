import { useState, useEffect } from 'react';

export const useImagePreview = (
  lowQualitySrc: string,
  highQualitySrc: string
) => {
  const [src, setSrc] = useState(lowQualitySrc);

  useEffect(() => {
    const image = new Image();
    image.src = highQualitySrc;
    image.onload = () => {
      setSrc(highQualitySrc);
    };

    return () => {
      image.onload = null;
    };
  }, [lowQualitySrc, highQualitySrc]);

  return src;
};
