import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IElement } from '../services/create-campaign/types';
import { createCampaignAPI } from '../services/create-campaign/endpoints/create-campaign';
import { authAPI } from '../services/auth/endpoints/auth';

interface MarkupEditorInterface {
  link: string;
  list: IElement[];
  iframeUrl: string;
}
const initialState: MarkupEditorInterface = {
  link: '',
  list: [],
  iframeUrl: '',
};

const markupEditorSlice = createSlice({
  name: 'markupEditorSlice',
  initialState,
  reducers: {
    setMarkupEditor: (state, action: PayloadAction<MarkupEditorInterface>) => {
      state = action.payload;
    },
    setMarkupList: (state, action: PayloadAction<IElement[]>) => {
      state.list = action.payload;
      return state;
    },
    setMarkupDeleteElementByPath: (state, action: PayloadAction<string>) => {
      state.list = state.list.filter((e) => e.path !== action.payload);
      return state;
    },
    setMarkupDeleteElementById: (state, action: PayloadAction<string>) => {
      const list = state.list.slice();
      list.forEach((e) => {
        if (e.id === action.payload) {
          e.deletion = true;
        }
      });
      state.list = list;
      return state;
    },
    setMarkupLink: (state, action: PayloadAction<string>) => {
      state.link = action.payload;
      return state;
    },
    setMarkupIframeLink: (state, action: PayloadAction<string>) => {
      const customUrlEncode = encodeURIComponent(action.payload);
      state.iframeUrl = `${process.env.REACT_APP_PROXY_URL}${customUrlEncode}`;
      return state;
    },
    resetEditorState: (state) => {
      state.iframeUrl = '';
      state.link = '';
      state.list = [];
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(
        createCampaignAPI.endpoints.getCampainData.matchFulfilled,
        (state, action) => {
          const customUrlEncode = encodeURIComponent(
            action.payload.data.campaign.page_url
          );
          state.list = action.payload.data.elements;
          state.link = action.payload.data.campaign.page_url;
          state.iframeUrl = `${process.env.REACT_APP_PROXY_URL}${customUrlEncode}`;
          return state;
        }
      )
      .addMatcher(
        createCampaignAPI.endpoints.checkRedirectedUrl.matchFulfilled,
        (state, action) => {
          const customUrlEncode = encodeURIComponent(action.payload.url);
          state.iframeUrl = `${process.env.REACT_APP_PROXY_URL}${customUrlEncode}`;
          return state;
        }
      )
      .addMatcher(authAPI.endpoints.logoutQuery.matchFulfilled, (state) => {
        state = initialState;
        return state;
      })
      .addMatcher(authAPI.endpoints.login.matchFulfilled, (state) => {
        state = initialState;
        return state;
      });
  },
});

export const {
  setMarkupLink,
  setMarkupEditor,
  setMarkupDeleteElementByPath,
  setMarkupDeleteElementById,
  setMarkupList,
  resetEditorState,
  setMarkupIframeLink,
} = markupEditorSlice.actions;

export default markupEditorSlice.reducer;
