import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { companyInfoAPI } from '../services/company-info/endpoints/company-info';
import { userDataAPI } from '../services/user/endpoints/user';
import { clientDomainAPI } from '../services/client-domains/endpoints/client-domains';
import { authAPI } from '../services/auth/endpoints/auth';

interface ICompanyInfo {
  id: string;
  name: string;
  site: string;
  description: string;
  brandVoice: string;
}

const initialState: ICompanyInfo = {
  id: '',
  name: '',
  site: '',
  description: '',
  brandVoice: '',
};

const companyInfoSlice = createSlice({
  name: 'companyInfo',
  initialState,
  reducers: {
    setId: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
      return state;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
      return state;
    },
    setSite: (state, action: PayloadAction<string>) => {
      state.site = action.payload;
      return state;
    },
    setDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload;
      return state;
    },
    setBrandVoice: (state, action: PayloadAction<string>) => {
      state.brandVoice = action.payload;
      return state;
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(
        companyInfoAPI.endpoints.getCompanyInfo.matchFulfilled,
        (state, action) => {
          const payload = action.payload;
          if (payload) {
            state.id = payload.id || '';
            state.name = payload.name;
            state.site = payload.page_url;
            state.description = payload.description;
            state.brandVoice = payload.tone_of_voice;
          }
          return state;
        }
      )
      .addMatcher(
        userDataAPI.endpoints.getProfile.matchFulfilled,
        (state, action) => {
          const payload = action.payload;
          if (payload) {
            state.name = payload.data.company_name;
          }
          return state;
        }
      )
      .addMatcher(
        clientDomainAPI.endpoints.checkUserDomain.matchFulfilled,
        (state, action) => {
          if (!state.site) {
            state.site = action.payload.url;
          }
          return state;
        }
      )
      .addMatcher(authAPI.endpoints.logoutQuery.matchFulfilled, (state) => {
        state = initialState;
        return state;
      })
      .addMatcher(authAPI.endpoints.login.matchFulfilled, (state) => {
        state = initialState;
        return state;
      });
  },
});

export const { setId, setName, setSite, setDescription, setBrandVoice } =
  companyInfoSlice.actions;

export default companyInfoSlice.reducer;
