import { useState } from 'react';
import { TextInput } from 'flowbite-react';

import { Trush } from 'src/components/icons';
import ModalBox from 'src/components/modal-box/modal-box';
import Button from 'src/components/button';

interface IModalProps {
  isOpen: boolean;
  destination_url?: string;
  onClose: () => void;
  onSave: (selected: any, name?: string) => void;
  onDelete: () => void;
}

function validateURL(url: string) {
  if (url.startsWith('tel:')) {
    return true;
  }
  return /\b(https?|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]*[-A-Za-z0-9+&@#/%=~_|]/i.test(
    url
  );
}

const DestinationModal = ({
  isOpen,
  onClose,
  onSave,
  onDelete,
  destination_url,
}: IModalProps) => {
  const [url, setUrl] = useState(destination_url || '');
  const [error, setError] = useState(false);

  const handleSave = () => {
    if (!url) {
      onSave(url);
      setUrl('');
      onClose();
      return;
    } else if (!validateURL(url)) {
      setError(true);
      return;
    }
    onSave(url);
    setUrl('');
    onClose();
  };

  return (
    <ModalBox isOpen={isOpen} onClose={onClose} windowClassName="max-w-xl">
      <ModalBox.Header>
        <p className="text-lg font-semibold text-gray-900">Destination URL</p>
      </ModalBox.Header>
      <ModalBox.Body>
        <TextInput
          id="atribute-name"
          placeholder=" Destination URL"
          value={url}
          onChange={(e) => {
            setUrl(e.target.value), setError(false);
          }}
          required={true}
          helperText={
            error && (
              <span className="text-sm text-red-800">Incorrect url!</span>
            )
          }
        />
      </ModalBox.Body>
      <ModalBox.Footer className="border-t-0 pt-0">
        <div className="flex justify-between">
          <Button
            className="min-w-73"
            title="Save"
            type="default"
            onClick={handleSave}
          />
          <Button
            type="danger"
            className="min-w-73"
            title="Delete"
            Icon={
              <Trush className="mr-2.5" color={!url ? '#000000' : '#FFFFFF'} />
            }
            onClick={() => {
              onDelete();
              onClose();
            }}
            disabled={!url}
          />
        </div>
      </ModalBox.Footer>
    </ModalBox>
  );
};

export default DestinationModal;
