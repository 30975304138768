import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import slices from './slices';
import { authAPI } from './services/auth/endpoints/auth';
import { googleAPI } from './services/google/endpoints/google';
import { createCampaignAPI } from './services/create-campaign/endpoints/create-campaign';
import { conversionActionAPI } from './services/conversion-action/endpoints/conversion-action';
import { variablesApi } from './services/variables/endpoints/variables';
import { segmentsAPI } from './services/segments/endpoints/segments';
import { userDataAPI } from './services/user/endpoints/user';
import { companyInfoAPI } from './services/company-info/endpoints/company-info';
import { openAiAPI } from './services/open-ai-generation/endpoints/open-ai-generation';
import { clientDomainAPI } from './services/client-domains/endpoints/client-domains';

export const store = configureStore({
  reducer: {
    [authAPI.reducerPath]: authAPI.reducer,
    [googleAPI.reducerPath]: googleAPI.reducer,
    [createCampaignAPI.reducerPath]: createCampaignAPI.reducer,
    [conversionActionAPI.reducerPath]: conversionActionAPI.reducer,
    [variablesApi.reducerPath]: variablesApi.reducer,
    [segmentsAPI.reducerPath]: segmentsAPI.reducer,
    [userDataAPI.reducerPath]: userDataAPI.reducer,
    [companyInfoAPI.reducerPath]: companyInfoAPI.reducer,
    [openAiAPI.reducerPath]: openAiAPI.reducer,
    [clientDomainAPI.reducerPath]: clientDomainAPI.reducer,
    ...slices,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authAPI.middleware)
      .concat(googleAPI.middleware)
      .concat(createCampaignAPI.middleware)
      .concat(conversionActionAPI.middleware)
      .concat(variablesApi.middleware)
      .concat(userDataAPI.middleware)
      .concat(segmentsAPI.middleware)
      .concat(companyInfoAPI.middleware)
      .concat(openAiAPI.middleware)
      .concat(clientDomainAPI.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
