import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ClearbitVaribalesType } from '../services/variables/types';
import { variablesApi } from '../services/variables/endpoints/variables';
import { authAPI } from '../services/auth/endpoints/auth';

interface ActionType {
  clearbitVariables: ClearbitVaribalesType;
  clearbitCompanies: any;
  clearbitCompaniesCount: number;
}

const initialState: ActionType = {
  clearbitVariables: {
    name: '',
    legalName: '',
    domain: '',
    category: {
      sector: '',
      industryGroup: '',
      industry: '',
      subIndustry: '',
    },
    foundedYear: 0,
    location: '',
    timeZone: '',
    geo: {
      city: '',
      postalCode: '',
      state: '',
      stateCode: '',
      country: '',
      countryCode: '',
    },
    type: '',
    metrics: {
      trafficRank: '',
      employees: 0,
      employeesRange: '',
      raised: 0,
      estimatedAnnualRevenue: '',
    },
  },
  clearbitCompanies: {},
  clearbitCompaniesCount: 0,
};

const varaiblesSlice = createSlice({
  name: 'varaiblesSlice',
  initialState,
  reducers: {
    setClearbitVariables: (
      state,
      action: PayloadAction<ClearbitVaribalesType>
    ) => {
      state.clearbitVariables = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(
        variablesApi.endpoints.getClearbitVariables.matchFulfilled,
        (state, action) => {
          state.clearbitVariables = action.payload.data;
        }
      )
      .addMatcher(
        variablesApi.endpoints.getClearbitCompanies.matchFulfilled,
        (state, action) => {
          state.clearbitCompanies = action.payload.data;
        }
      )
      .addMatcher(
        variablesApi.endpoints.getClearbitCompanies.matchFulfilled,
        (state, action) => {
          state.clearbitCompaniesCount = action.payload.data;
        }
      )
      .addMatcher(authAPI.endpoints.logoutQuery.matchFulfilled, (state) => {
        state = initialState;
        return state;
      })
      .addMatcher(authAPI.endpoints.login.matchFulfilled, (state) => {
        state = initialState;
        return state;
      });
  },
});

export const { setClearbitVariables } = varaiblesSlice.actions;

export default varaiblesSlice.reducer;
