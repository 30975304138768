import { ChangeEvent } from 'react';
import { ClearCross, IIcon, InfoIcon, Trush } from './icons';
import { Tooltip } from 'flowbite-react';

interface IInputProps {
  id: string;
  title?: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onDeletedClick?: () => void;
  placeholder?: string;
  tooltipText?: string;
  onClearClick?: () => void;
  className?: string;
  disabled?: boolean;
}

const Input = ({
  id,
  title,
  value,
  onChange,
  onDeletedClick,
  placeholder,
  tooltipText,
  onClearClick,
  className,
  disabled,
}: IInputProps) => {
  return (
    <div className={className}>
      {title && (
        <label
          htmlFor={id}
          className="flex items-center mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          {title}
          {tooltipText && (
            <div className="ml-2.5">
              <Tooltip
                id={`${id}-tooltip`}
                content={tooltipText}
                trigger="hover"
              >
                <InfoIcon
                  {...({
                    'aria-describedby': `${id}-tooltip`,
                    role: 'tooltip',
                  } as IIcon)}
                />
              </Tooltip>
            </div>
          )}
        </label>
      )}
      <div className="flex items-center w-full">
        <div className="relative w-full max-w-[364px]">
          <input
            id={id}
            value={value}
            onChange={onChange}
            className="max-w-[364px] w-full bg-gray-50 border border-gray-300 bg-white text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block py-2 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 disabled:text-gray-400 disabled:bg-gray-100"
            placeholder={placeholder}
            required
            disabled={disabled}
          />
          {onClearClick && value && (
            <button
              className="absolute right-[16px] top-1/2 translate-y-[-50%]"
              type="button"
              onClick={onClearClick}
              aria-label="Clear input"
            >
              <ClearCross />
            </button>
          )}
        </div>
        {onDeletedClick && (
          <button
            className="ml-3"
            type="button"
            onClick={onDeletedClick}
            aria-label="Delete input"
          >
            <Trush />
          </button>
        )}
      </div>
    </div>
  );
};

export default Input;
