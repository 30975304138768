import BaseSelect from 'src/components/selects/base-select';
import {
  CheckIcon,
  CursorClick,
  GoBackArrow,
  LeftTextAlign,
} from 'src/components/icons';

interface IGenerateProps {
  title: string;
  isLoading?: boolean;
  disabled?: boolean;
  onHandleGenerate: () => void;
  onHandleFixGrammar: () => void;
  onHandleImproveText: () => void;
  onHandleBackAnswer: () => void;
}

const ChatGenerateSelect = ({
  title,
  isLoading,
  disabled,
  onHandleGenerate,
  onHandleFixGrammar,
  onHandleImproveText,
  onHandleBackAnswer,
}: IGenerateProps) => {
  return (
    <BaseSelect
      type="text"
      title={title}
      isLoad={isLoading}
      disabled={disabled}
      baseClassName="[&>p]:flex [&>p]:items-center [&>p]:whitespace-nowrap"
      ItemList={(handleClose) => (
        <div className="flex flex-col">
          <button
            className="flex items-center p-1 -mt-1 hover:bg-blue-100"
            onClick={(e) => {
              handleClose(e);
              onHandleGenerate();
            }}
          >
            <LeftTextAlign className="mr-4" color="#6B7280" />
            <p className="leading-tight text-xs font-medium text-gray-700">
              Generate text
            </p>
          </button>
          <button
            className="flex items-center mt-1 p-1 hover:bg-blue-100"
            onClick={(e) => {
              handleClose(e);
              onHandleFixGrammar();
            }}
          >
            <CheckIcon className="mr-4" color="#6B7280" />
            <p className="leading-tight text-xs font-medium text-gray-700">
              Fix spelling & grammar
            </p>
          </button>
          <button
            className="flex items-center mt-1 p-1 hover:bg-blue-100"
            onClick={(e) => {
              handleClose(e);
              onHandleImproveText();
            }}
          >
            <CursorClick className="mr-4" color="#6B7280" />
            <p className="leading-tight text-xs font-medium text-gray-700">
              Improve writing
            </p>
          </button>
          <div className="h-px my-1 -mr-3 -ml-3 bg-gray-200" />
          <button
            className="flex items-center p-1 -mb-1.5 hover:bg-blue-100"
            onClick={(e) => {
              handleClose(e);
              onHandleBackAnswer();
            }}
          >
            <GoBackArrow className="mr-4" color="#6B7280" />
            <p className="leading-tight text-xs font-medium text-gray-700">
              Undo
            </p>
          </button>
        </div>
      )}
    />
  );
};

export default ChatGenerateSelect;
