import authReducer from './authSlice';
import googleSyncReducer from './googleSyncSlice';
import createCampaignReducer from './campaignSlice';
import markupEditorReducer from './markup-editor-slice';
import conversionActionReducer from './conversionActionSlice';
import storageReducer from './storageSlice';
import variablesReducer from './variablesSlice';
import companyInfoReducer from './companyInfoSlice';
import clientDomainReducer from './clientDomainSlice';

const slices = {
  authReducer,
  googleSyncReducer,
  createCampaignReducer,
  markupEditorReducer,
  conversionActionReducer,
  storageReducer,
  variablesReducer,
  companyInfoReducer,
  clientDomainReducer,
};

export default slices;
