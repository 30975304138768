import { useNavigate, useLocation } from 'react-router-dom';
import { FilterArrow } from 'src/components/icons';

interface IFilterButton {
  param: string;
}

const FilterButton = ({ param }: IFilterButton) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const handleToggle = () => {
    const currentSortOrder = searchParams.get(param);
    const newSortOrder = currentSortOrder === 'ASC' ? 'DESC' : 'ASC';

    const otherParams = Array.from(searchParams.keys()).filter(
      (key) => key !== param
    );
    otherParams.forEach((key) => searchParams.delete(key));

    searchParams.set(param, newSortOrder);

    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  return (
    <button onClick={handleToggle}>
      <FilterArrow className="ml-2" color="#374151" />
    </button>
  );
};

export default FilterButton;
