import {
  BaseQueryFn,
  FetchBaseQueryError,
  FetchArgs,
} from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { logout, setCredentials } from 'src/store/slices/authSlice';
import { baseQuery, refreshQuery } from './helpers';
import { ResponseWrap } from './response_wrap';
import { AuthResponse } from './auth/types';

const mutex = new Mutex();

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await refreshQuery(
          '/api/auth/refresh',
          api,
          extraOptions
        );

        if (refreshResult.meta?.response?.status === 403) {
          api.dispatch(logout());
        } else {
          if (refreshResult.data) {
            const authResponse =
              refreshResult.data as ResponseWrap<AuthResponse>;
            api.dispatch(setCredentials(authResponse.data));
            result = await baseQuery(args, api, extraOptions);
          } else {
            api.dispatch(logout());
          }
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};
