import React from 'react';
import BaseSelect from 'src/components/selects/base-select';

export type TItem = {
  label: string;
  value: string;
  hasFallbackValue: boolean;
};

export type TSection = {
  name: string;
  sectionItems: TItem[];
};

interface ITextSelectProps {
  title: string;
  textItems: TSection[];
  onSelectItem: (item: TItem) => void;
}

const VariableSelect = ({
  title,
  textItems,
  onSelectItem,
}: ITextSelectProps) => {
  return (
    <BaseSelect
      type="text"
      title={title}
      popupClassName="left-auto right-0 pt-2"
      ItemList={(handleClose) => (
        <div className="max-h-[154px] flex flex-col">
          {textItems.map((section) => (
            <React.Fragment key={section.name}>
              <p className="leading-tight text-xs font-medium text-gray-500 mt-2">
                {section.name}
              </p>
              {section.sectionItems.map((item) => (
                <button
                  key={`${section.name}-${item.value}`}
                  className="p-1 hover:bg-blue-100"
                  onClick={(e) => {
                    handleClose(e), onSelectItem(item);
                  }}
                >
                  <p className="leading-tight text-xs font-medium text-left text-gray-900">
                    {item.label}
                  </p>
                </button>
              ))}
            </React.Fragment>
          ))}
        </div>
      )}
    />
  );
};

export default VariableSelect;
