export const clickableWindow = (text: any, top: string, left: number) => {
  const newDiv = document.createElement('div');
  newDiv.appendChild(text);

  newDiv.setAttribute('maked-button', 'true');
  newDiv.style.opacity = '0';
  newDiv.style.position = 'absolute';
  newDiv.style.padding = '12px 11px';
  newDiv.style.paddingRight = '23px';
  newDiv.style.backgroundColor = '#FACA15';
  newDiv.style.pointerEvents = 'none';
  newDiv.style.zIndex = '99';

  newDiv.style.top = top;

  setTimeout(() => {
    newDiv.style.left = left - newDiv.offsetWidth + 'px';
    newDiv.style.opacity = 'initial';
  }, 0);

  return newDiv;
};

export const windowTitle = (text = '') => {
  const newText = document.createElement('p');

  newText.style.color = '#FFFFFF';
  newText.style.fontSize = '14px';
  newText.style.fontWeight = '500';
  newText.style.textAlign = 'left';
  newText.style.letterSpacing = '1px';
  newText.style.margin = 'unset';
  newText.style.webkitTextFillColor = '#FFFFFF';
  newText.textContent = text;
  return newText;
};

export const windowButton = () => {
  const newButton = document.createElement('div');

  newButton.style.display = 'flex';
  newButton.style.alignItems = 'center';
  newButton.style.justifyContent = 'space-around';
  newButton.style.padding = '8px 12px';
  newButton.style.width = '100%';
  newButton.style.margin = '0px auto';
  newButton.style.marginTop = '7px';
  newButton.style.backgroundColor = '#84E1BC';
  return newButton;
};
