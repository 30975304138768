import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Breadcrumb } from 'flowbite-react';
import { HiPlus } from 'react-icons/hi';
import {
  ConversionActionType,
  ConversionActionItem,
} from 'src/store/services/conversion-action/types';
import MarkupEditor from 'src/features/markup-editor/MarkupEditor';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
  resetEditorState,
  setMarkupList,
} from 'src/store/slices/markup-editor-slice';
import { IElement } from 'src/store/services/create-campaign/types';
import { setClearElements } from 'src/store/slices/campaignSlice';
import ActionsList from './blocks/ActionsList';
import ActionsTypeForm from './blocks/ActionsTypeForm';
import Button from 'src/components/button';
import {
  ButtonOrLinkConversionIcon,
  FormConversionIcon,
  PageVisitConversionIcon,
} from 'src/components/icons';
import ActionTypeCard from './blocks/action-type-card';
import ModalBox from 'src/components/modal-box/modal-box';

export function Actions() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [selectedTag, setSelectedTag] = useState<string>('');
  const [editAction, setEditAction] = useState<ConversionActionItem>();
  const [showModalEditor, setShowModalEditor] = useState(false);
  const [showModalSelect, setShowModalSelect] = useState(false);
  const [showModalForm, setShowModalForm] = useState(false);
  const [isExactly, setIsExactly] = useState<boolean>(true);
  const [pageUrl, setPageUrl] = useState<string>('');
  const [actionName, setActionName] = useState<string>('');
  const [conversionActionType, setConversionActionType] =
    useState<ConversionActionType>();
  const { list } = useAppSelector((state) => state.markupEditorReducer);

  const resetForm = () => {
    setActionName('');
    setPageUrl('');
    setIsExactly(false);
    setConversionActionType(ConversionActionType.Page_Visit);
    setSelectedTag('');
    setEditAction(undefined);
  };

  const handleNewPageVisit = (type: ConversionActionType) => {
    setConversionActionType(type);
    setShowModalForm(true);
    handleCloseNewConversion();
  };

  const handleNewConversion = () => {
    setConversionActionType(ConversionActionType.Button_or_Link);
    setShowModalSelect(true);
  };

  const handleClosePageVisit = () => {
    resetForm();
    setShowModalForm(false);
  };

  const handleCloseNewConversion = () => {
    setShowModalSelect(false);
  };

  const handleSaveMarkup = async () => {
    if (list.length > 0) {
      setSelectedTag(list[0].path);
      dispatch(resetEditorState());
    }
    setShowModalEditor(false);
  };

  const handleEdit = async (item: ConversionActionItem) => {
    setEditAction(item);
    setActionName(item.name);
    setPageUrl(item.page_url);
    setIsExactly(item.is_exactly);
    if (item.action_type === ConversionActionType.Page_Visit) {
      setConversionActionType(ConversionActionType.Page_Visit);
    }

    if (item.action_type === ConversionActionType.Button_or_Link) {
      setConversionActionType(ConversionActionType.Button_or_Link);
      dispatch(
        setMarkupList([
          {
            name: 'actionElement',
            path: item.tag_path,
            tag_type: 'text',
            default_value: '',
          } as IElement,
        ])
      );
      setSelectedTag(item.tag_path);
    }

    if (item.action_type === ConversionActionType.Form) {
      setConversionActionType(ConversionActionType.Form);
      dispatch(
        setMarkupList([
          {
            name: 'actionElement',
            path: item.tag_path,
            tag_type: 'text',
            default_value: '',
          } as IElement,
        ])
      );
      setSelectedTag(item.tag_path);
    }

    setShowModalForm(true);
  };

  useEffect(() => {
    dispatch(resetEditorState());
    dispatch(setClearElements());
  }, [dispatch, location]);

  return (
    <section className="h-auto">
      <div className="block items-center justify-between py-4 pt-6 px-10">
        <div className="mb-1 w-full">
          <Breadcrumb className="mb-4">
            <Breadcrumb.Item href="#">
              <div className="flex items-center gap-x-3">
                <span className="dark:text-white">Conversions</span>
              </div>
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="flex justify-between">
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
              Conversion Actions
            </h1>
            <Button
              type="default"
              title="New Conversion Action"
              onClick={() => handleNewConversion()}
              Icon={<HiPlus className="mr-2 my-[5px]" />}
              dataTest="create_conversion_action_button"
            />
          </div>
        </div>
      </div>

      <ActionsList
        handleEdit={(item: ConversionActionItem) => handleEdit(item)}
      />

      <ModalBox
        isOpen={showModalEditor}
        onClose={handleClosePageVisit}
        windowClassName="max-w-[90vw] max-h-[90vh] overflow-hidden"
      >
        <ModalBox.Body className="py-0 px-0 overflow-y-auto">
          <MarkupEditor
            byElements={
              conversionActionType === ConversionActionType.Form
                ? ['form']
                : ['a', 'submit', 'button']
            }
            limitElements={1}
            handleSaveAndExit={handleSaveMarkup}
            skipTitle={true}
            title="Select the element (button or link), a click on which will be considered as a conversion action"
            headerSelectName="Selected item"
            headerSetElementName="Select this element"
            isConversionActions={true}
          />
        </ModalBox.Body>
      </ModalBox>

      <ModalBox
        isOpen={showModalForm}
        onClose={handleClosePageVisit}
        windowClassName="max-w-4xl"
      >
        <ModalBox.Header onClose={handleClosePageVisit}>
          <p className="text-lg font-semibold text-gray-900">
            Create Conversion Action:&nbsp;
            {ConversionActionType.Page_Visit == conversionActionType &&
              'Page Visit'}
            {ConversionActionType.Button_or_Link == conversionActionType &&
              'Button or Link'}
            {ConversionActionType.Form == conversionActionType && 'Form'}
          </p>
        </ModalBox.Header>
        <ModalBox.Body>
          <ActionsTypeForm
            conversionActionType={conversionActionType}
            selectedTag={selectedTag}
            editAction={editAction}
            isExactly={isExactly}
            pageUrl={pageUrl}
            actionName={actionName}
            onOpenEditor={() => {
              setShowModalEditor(true);
            }}
            onModalClose={() => handleClosePageVisit()}
          />
        </ModalBox.Body>
      </ModalBox>

      <ModalBox
        isOpen={showModalSelect}
        onClose={handleCloseNewConversion}
        windowClassName="max-w-4xl"
      >
        <ModalBox.Header onClose={handleCloseNewConversion}>
          <p className="text-lg font-semibold text-gray-900">
            Create Conversion Action
          </p>
        </ModalBox.Header>
        <ModalBox.Body>
          <div className="m-2 flex gap-3 justify-between">
            <ActionTypeCard
              title="Page visit"
              description="Track conversion when a user visits particular website page"
              Icon={<PageVisitConversionIcon />}
              onCardClick={() =>
                handleNewPageVisit(ConversionActionType.Page_Visit)
              }
            />
            <ActionTypeCard
              title="Button or Link"
              description="Track conversion when a user clicks a button or a link"
              Icon={<ButtonOrLinkConversionIcon />}
              onCardClick={() =>
                handleNewPageVisit(ConversionActionType.Button_or_Link)
              }
            />
            <ActionTypeCard
              title="Form"
              description="Track conversion when a user submits a form"
              Icon={<FormConversionIcon />}
              onCardClick={() => handleNewPageVisit(ConversionActionType.Form)}
            />
          </div>
        </ModalBox.Body>
      </ModalBox>
    </section>
  );
}
