import toast from 'react-hot-toast';
import { HiX } from 'react-icons/hi';

export function customToastMessage(
  message: string,
  icon: JSX.Element,
  type: string,
  duration?: number
) {
  let classIcon: string;
  if (type === 'error') {
    classIcon = 'text-red-500 bg-red-100';
  } else if (type === 'success') {
    classIcon = 'text-green-500 bg-green-100';
  } else if (type === 'warning') {
    classIcon = 'text-orange-500 bg-orange-100';
  }
  toast.custom(
    (t) => (
      <div
        className={`${
          t.visible ? 'animate-enter' : 'animate-leave'
        } flex items-center w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800`}
        role="alert"
        data-testid="custom_toast"
      >
        <div
          className={`inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg ${classIcon}`}
        >
          {icon}
        </div>
        <div
          className="ml-3 text-sm font-normal"
          data-testid="custom_toast_message"
        >
          {message}
        </div>
        <button
          type="button"
          className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
          data-dismiss-target="#toast-warning"
          aria-label="Close"
          onClick={() => toast.remove(t.id)}
        >
          <span className="sr-only">Close</span>
          <HiX className="w-5 h-5" />
        </button>
      </div>
    ),
    {
      duration: duration || 5000,
    }
  );
}
