import { useEffect, useState } from 'react';
import Button from 'src/components/button';
import { useGetClearbitCompaniesQuery } from 'src/store/services/variables/endpoints/variables';
import { NextArrow } from 'src/components/icons';
import Select from 'src/components/select';
import ModalBox from 'src/components/modal-box/modal-box';

interface IModalProps {
  segmentID?: string;
  isOpen: boolean;
  onClose: () => void;
  onSave: (value: string) => void;
}

const CompanyPreviewModal = ({
  isOpen,
  onClose,
  onSave,
  segmentID,
}: IModalProps) => {
  const { data: clearbitVariables } = useGetClearbitCompaniesQuery(segmentID);

  const [value, setValue] = useState('');
  const [selected, setSelected] = useState('');

  useEffect(() => {
    setSelected(
      clearbitVariables?.data?.find((i: any) => i.domain === value)?.domain
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleSave = () => {
    onSave(value);
    setValue('');
  };
  return (
    <ModalBox isOpen={isOpen} onClose={onClose} windowClassName="max-w-md">
      <ModalBox.Body className="p-7 pb-0">
        <p className="mb-4 text-2xl font-bold text-gray-900">
          Generate Preview for a Company
        </p>
        <Select
          id="segments"
          title=""
          activeValue={selected}
          onChange={(e: any) => {
            setValue(e.target.value);
          }}
          selectOptions={
            clearbitVariables?.data?.map((i: any) => ({
              label: i.name,
              value: i.domain,
            })) || []
          }
          placeholder="Select company"
        />
      </ModalBox.Body>
      <ModalBox.Footer className="border-t-0 p-8 pt-4">
        <div className="flex justify-center">
          <Button title="Cancel" type="outline" onClick={onClose} />
          <Button
            title="Generate Preview"
            type="default"
            onClick={handleSave}
            Icon={<NextArrow className={`'-rotate-90'} ml-2`} />}
            reverse
          />
        </div>
      </ModalBox.Footer>
    </ModalBox>
  );
};

export default CompanyPreviewModal;
