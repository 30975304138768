interface ITHead {
  columns: any;
  type?: 'dark' | 'light' | 'even-bg';
}

const THead = ({ columns, type }: ITHead) => {
  const tableTypeBackground = () => {
    switch (type) {
      case 'dark':
        return 'bg-[#E5E7EB]';
      case 'light':
        return 'bg-[#F3F4F6]';
      default:
        return 'bg-[#F9FAFB]';
    }
  };

  return (
    <thead>
      <tr>
        {columns.map((column: any, index: number) => (
          <th
            key={index}
            className={`border-b border-[#E5E7EB] ${tableTypeBackground()} uppercase`}
            style={column.width && { width: column.width }}
          >
            {typeof column.label === 'function'
              ? column.label()
              : column.label
              ? column.label
              : ''}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
