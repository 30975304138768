import { Accordion } from 'flowbite-react';
import { useState } from 'react';

type TAccordion = {
  title: string;
  content: JSX.Element;
};

interface IAccordionProps {
  accordionItems: TAccordion[];
}

const BaseAccordion = ({ accordionItems }: IAccordionProps) => {
  const [openPanels, setOpenPanels] = useState<string[]>([]);

  const togglePanel = (panelId: string) => {
    if (openPanels.includes(panelId)) {
      setOpenPanels(openPanels.filter((id) => id !== panelId));
    } else {
      setOpenPanels([...openPanels, panelId]);
    }
  };

  return (
    <Accordion>
      {accordionItems.map((item, index) => (
        <Accordion.Panel
          key={`${item.title}-${index}`}
          isOpen={openPanels.includes(`${item.title}-${index}`)}
          setOpen={() => {
            togglePanel(`${item.title}-${index}`);
          }}
        >
          <Accordion.Title>{item.title}</Accordion.Title>
          <Accordion.Content>{item.content}</Accordion.Content>
        </Accordion.Panel>
      ))}
    </Accordion>
  );
};

export default BaseAccordion;
