import BaseSelect from './base-select';

export type SelectOption = {
  label: string;
  value: string;
  disable?: boolean;
};

export interface ISimpleSelectProps {
  title?: string;
  value?: string;
  items: SelectOption[];
  onSelect: (selectedValue: string) => void;
  itemClassName?: string;
  inputClassName?: string;
  baseClassName?: string;
  disabled?: boolean;
  loading?: boolean;
  reverse?: boolean;
}

const SimpleSelect = ({
  title,
  value,
  items,
  onSelect,
  itemClassName,
  inputClassName,
  baseClassName,
  disabled,
  loading,
  reverse,
}: ISimpleSelectProps) => {
  return (
    <BaseSelect
      type="base"
      title={title}
      popupClassName="[&]:p-0 [&]:max-w-none"
      value={value || items[0].label}
      inputClassName={inputClassName}
      baseClassName={baseClassName}
      disabled={disabled}
      isLoad={loading}
      reverse={reverse}
      ItemList={(handleClose) => (
        <>
          {items.map((item) => (
            <button
              key={`${item.value}-${item.label}`}
              className={`w-full p-1.5 pl-3 text-left hover:bg-gray-100 ${
                value && value === item.label ? '[&]:bg-green-100' : ''
              } ${item.disable ? '[&]:cursor-pointer' : ''}`}
              onClick={(e) => {
                onSelect(item.value);
                handleClose(e);
              }}
              disabled={item.disable}
              role="option"
              aria-selected={
                value && item.label.includes(value) ? 'true' : 'false'
              }
            >
              <span
                className={`leading-tight text-xs font-medium text-gray-900 ${itemClassName} ${
                  item.disable && item.label !== value
                    ? '[&&]:text-gray-400'
                    : ''
                } ${
                  value && item.label.includes(value) ? '[&]:text-black' : ''
                }`}
              >
                {item.label}
              </span>
            </button>
          ))}
        </>
      )}
    />
  );
};

export default SimpleSelect;
