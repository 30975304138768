import { IBaseModal } from './modal-box';

const Footer = ({ children, className }: IBaseModal) => {
  return (
    <div className={`border-t p-4 ${className ? className : ''}`}>
      {children}
    </div>
  );
};

export default Footer;
