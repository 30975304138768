import { useEffect } from 'react';
import { useAppSelector } from 'src/store/hooks';
import { useLazyLoadGenerationProcessQuery } from 'src/store/services/open-ai-generation/endpoints/open-ai-generation';

const useAiGeneration = () => {
  const { aiLoader } = useAppSelector((state) => state.storageReducer);
  const { campaignID, generationType } = useAppSelector(
    (state) => state.createCampaignReducer
  );
  const [loadGenerationProcess] = useLazyLoadGenerationProcessQuery();

  useEffect(() => {
    if (campaignID) {
      let intervalId: NodeJS.Timeout | null = null;

      const loadGenerationData = async () => {
        const response = await loadGenerationProcess({
          campaign_id: campaignID,
          generation_type: generationType,
        }).unwrap();

        if (response.task) {
          if (
            response.task.complete_cnt === response.task.ads_cnt ||
            response.task.complete_dt !== null
          ) {
            if (intervalId) {
              clearInterval(intervalId);
              intervalId = null;
            }
          }
        }
      };

      if (aiLoader) {
        loadGenerationData();
        intervalId = setInterval(loadGenerationData, 10000);
      }
    }
  }, [aiLoader, campaignID, generationType, loadGenerationProcess]);
};

export default useAiGeneration;
