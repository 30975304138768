import { useCallback, useEffect, useMemo, useState } from 'react';
import { HiExclamation, HiPencilAlt } from 'react-icons/hi';
import { useParams } from 'react-router';
import { Button, Modal } from 'flowbite-react';
import {
  useGetCampainDataQuery,
  useLazyGetCampaignByIdQuery,
  useLazyGetCampainDataQuery,
  useLazyGetChartDataQuery,
} from '../store/services/create-campaign/endpoints/create-campaign';
import {
  ChartData,
  DetailCampaignAd,
  FilterCalendarDate,
  IUserCampaign,
  TStatistic,
} from '../store/services/create-campaign/types';
import { parseResponse } from '../store/services/helpers';

import { customToastMessage } from '../components/toast';
import BreadCrumbs from '../components/bread-crumbs';
import CustomButton from 'src/components/button';
import SyncAccessModal from 'src/features/campaigns/blocks/sync-access-modal';
import useSyncCamapaign from 'src/features/campaigns/hooks/useSyncCampaign';
import { SyncStatus } from 'src/store/services/google/types';
import EmptyList from 'src/empty-list';
import { useAppSelector } from 'src/store/hooks';
import DetailCampaign from 'src/features/detail-campaign/detail-campaign';
import { differenceInDays } from 'date-fns';
import useSaveAsDraft from 'src/features/create-campaign/hooks/useSaveAsDraft';
import useStepDataTransform from 'src/features/create-campaign/hooks/useStepThreeDataTransform';
import CompanyPreviewModal from 'src/features/create-campaign/blocks/company-preview-modal';

export function DetailCampaignPage() {
  const { id } = useParams();
  const { isSyncing, handleRedirectPage } = useSyncCamapaign();
  useGetCampainDataQuery(id as string, { skip: !id });
  const [getCampaignById] = useLazyGetCampaignByIdQuery();
  const [getChartData] = useLazyGetChartDataQuery();
  const [getCampaignData] = useLazyGetCampainDataQuery();

  const { googleAdsData } = useStepDataTransform();

  const { filters } = useAppSelector((state) => state.createCampaignReducer);
  const [campaign, setCampaign] = useState<IUserCampaign>();
  const [emptyCampaign, setEmptyCampaign] = useState<{
    id: string;
    name: string;
  }>();
  const [ads, setAds] = useState<DetailCampaignAd[]>([]);
  const [chart, setChart] = useState<ChartData | null>(null);
  const [total, setTotal] = useState<TStatistic>();
  const [isOpenCompanyPreviewModal, setIsOpenCompanyPreviewModal] =
    useState(false);
  const [addId, setAddId] = useState('');
  const [previewType, setPreviewType] = useState('');
  const [openSyncModal, setOpenSyncModal] = useState(false);
  const [campaignLoad, setCampaignLoad] = useState(true);
  const [chartLoad, setChartLoad] = useState(true);

  const { handlePreviewSave } = useSaveAsDraft();

  const today = useMemo(
    () => (filters.endDate ? new Date(filters.endDate) : new Date()),
    [filters.endDate]
  );
  const last_month = useMemo(
    () =>
      filters.startDate
        ? new Date(filters.startDate)
        : new Date().setDate(today.getDate() - 30),
    [filters.startDate, today]
  );

  const [filterParams, setFilterParams] = useState<FilterCalendarDate>({
    id: '',
    startDate: Intl.DateTimeFormat('sv-SE').format(last_month),
    endDate: Intl.DateTimeFormat('sv-SE').format(today),
    ads: filters.ads,
    version: filters.versionID,
    is_desktop: filters.is_desktop,
  });

  const loadCampaignData = useCallback(
    async (campaignID: string) => {
      const lastDay =
        filterParams.startDate ||
        Intl.DateTimeFormat('sv-SE').format(last_month);
      const toDay =
        filterParams.endDate || Intl.DateTimeFormat('sv-SE').format(today);

      setCampaignLoad(true);

      try {
        const { data: campaignData, error: campaignError } =
          await getCampaignById({
            campaignID,
            filter: {
              startDate: lastDay,
              endDate: toDay,
              version: filterParams.version,
            },
          });

        const { result: campaignResult, errorMessage: campaignErrorMessage } =
          parseResponse({ data: campaignData, error: campaignError });

        if (campaignErrorMessage) {
          throw new Error(campaignErrorMessage);
        }

        if (campaignResult) {
          if (campaignResult.data.ads.length > 0) {
            setCampaign(campaignResult.data.ads[0].campaign.user_campaign);
            setAds(campaignResult.data.ads);
          }
          setEmptyCampaign(campaignResult.data.campaign);
        }
      } catch (error: any) {
        customToastMessage(
          error.message,
          <HiExclamation className="h-5 w-5" />,
          'error'
        );
      } finally {
        setCampaignLoad(false);
      }
    },
    [filterParams, last_month, today, getCampaignById]
  );

  const loadChartData = useCallback(
    async (campaignID: string) => {
      const lastDay =
        filterParams.startDate ||
        Intl.DateTimeFormat('sv-SE').format(last_month);
      const toDay =
        filterParams.endDate || Intl.DateTimeFormat('sv-SE').format(today);
      const differentDays = differenceInDays(
        new Date(lastDay),
        new Date(toDay)
      );
      const filterPart =
        differentDays > -3
          ? 'hour'
          : differentDays < -3 && differentDays > -180
          ? 'day'
          : 'week';

      setChartLoad(true);

      try {
        const { data: chartData, error: chartError } = await getChartData({
          campaignID,
          filter: {
            startDate: lastDay,
            endDate: toDay,
            part: filterPart,
            google_ad_ids: filterParams.ads || '',
            conversion_action_ids: filterParams.conversions || '',
            is_desktop: filterParams.is_desktop || '',
          },
        });

        const { result: chartResult, errorMessage: chartErrorMessage } =
          parseResponse({ data: chartData, error: chartError });

        if (chartErrorMessage) {
          throw new Error(chartErrorMessage);
        }

        if (chartResult) {
          setChart(chartResult);
          setTotal(chartResult.reportByAds.total);
        }
      } catch (error: any) {
        customToastMessage(
          error.message,
          <HiExclamation className="h-5 w-5" />,
          'error'
        );
      } finally {
        setChartLoad(false);
      }
    },
    [filterParams, last_month, today, getChartData]
  );

  const handleCreateCampaign = (url: string) => {
    if (SyncStatus[isSyncing] !== 'InProgress') {
      handleRedirectPage(url);
    } else {
      setOpenSyncModal(true);
    }
  };

  const handleEditCampaign = async (e: any, id: string) => {
    const ctrlClicked = sessionStorage.getItem('ctrlDown');
    if (ctrlClicked === null || ctrlClicked !== 'true') {
      e.preventDefault();
      const { data, error } = await getCampaignData(id.toString());
      const { result, errorMessage } = parseResponse({ data, error });

      if (error && errorMessage) {
        customToastMessage(
          errorMessage,
          <HiExclamation className="h-5 w-5" />,
          'error',
          200
        );
      }
      if (result && !(SyncStatus[isSyncing] === 'InProgress')) {
        handleCreateCampaign(`/campaigns/update-campaign/${id}`);
      }
    }
  };

  const openPreview = ({ domain = '', ads_id = '', preview_type = '' }) => {
    const windowReference = window.open();
    handlePreviewSave(ads_id).then((isSaved) => {
      const previewAds = googleAdsData.find((item: any) => item.id === ads_id);
      const haveParams = previewAds?.url.includes('?');
      const url = encodeURI(
        previewAds?.url +
          `${
            haveParams ? '&' : '?'
          }pipe_preview=true&campaign_id=${id}&ad_id=${ads_id}&type=${preview_type}&domain=${domain}`
      );
      if (isSaved) {
        if (windowReference) windowReference.location = url;
      } else {
        window.close();
      }
    });
  };

  const handleOpenPreview = (id: string, preview_type: string) => {
    setAddId(id);
    setPreviewType(preview_type);
    if (preview_type === 'company') {
      setIsOpenCompanyPreviewModal(true);
      return;
    }
    openPreview({ ads_id: id, preview_type });
  };

  const handleCloseCompanyPreviewModal = (domain: string) => {
    setIsOpenCompanyPreviewModal(false);
    openPreview({ domain, preview_type: previewType, ads_id: addId });
  };

  useEffect(() => {
    if (id) {
      loadCampaignData(id);
      loadChartData(id);
    }
  }, [id, loadCampaignData, loadChartData]);

  useEffect(() => {
    window.document.onkeydown = (event) => {
      if (event.ctrlKey || event.metaKey) {
        sessionStorage.setItem('ctrlDown', 'true');
      }
    };
    window.document.onkeyup = (event) => {
      if (event.key === 'Control' || event.key === 'Meta') {
        sessionStorage.setItem('ctrlDown', 'false');
      }
    };
  }, []);

  useEffect(() => {
    if (ads.length > 0 && chart && chart.reportByAds) {
      const adsArrWithStat = ads.map((ad) => {
        if (chart.reportByAds[ad.id]) {
          return {
            ...ad,
            experimental_leads:
              chart.reportByAds[ad.id].experimental_conversions || 0,
            experimental_visitors:
              chart.reportByAds[ad.id].experimental_visitors || 0,
            full_leads: chart.reportByAds[ad.id].ctrl_conversions || 0,
            full_visitors: chart.reportByAds[ad.id].ctrl_visitors || 0,
          };
        } else {
          return ad;
        }
      });
      setAds(adsArrWithStat);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chart]);

  return (
    <section className="flex flex-col h-auto py-6 px-10">
      <SyncAccessModal />
      <div className="mb-4">
        <BreadCrumbs
          items={[
            { title: 'Campaigns', link: '/campaigns' },
            {
              title: campaign?.name || emptyCampaign?.name || '',
              link: `/${id}`,
            },
          ]}
        />
      </div>
      <div className="flex items-center justify-between mb-[42px]">
        <h1 className="text-2xl font-semibold">
          {campaign?.name || emptyCampaign?.name}
        </h1>
        <Button
          className="[&>span]:px-0 [&>span]:py-0"
          onClick={(e: any) => handleEditCampaign(e, id ?? '')}
          color="dark"
        >
          <a
            className="px-3 py-2 flex items-center"
            href={`/campaigns/update-campaign/${id}`}
          >
            <HiPencilAlt /> &nbsp; Edit Personalization Campaign
          </a>
        </Button>
      </div>
      {(emptyCampaign && ads.length <= 0 && !campaignLoad) ||
      (!campaign && !campaignLoad) ? (
        <EmptyList title="No data. <br/> Edit the campaign." />
      ) : (
        <>
          <DetailCampaign
            campaignID={id || ''}
            url={campaign ? campaign.page_url : ''}
            ads={ads || []}
            chartData={chart}
            total={total}
            setFilterParams={setFilterParams}
            handleOpenPreview={handleOpenPreview}
            lastCampaignDate={new Date(campaign ? campaign.created_at : '')}
            isLoading={campaignLoad}
            chartLoading={chartLoad}
          />

          <CompanyPreviewModal
            isOpen={isOpenCompanyPreviewModal}
            onClose={() => setIsOpenCompanyPreviewModal(false)}
            onSave={handleCloseCompanyPreviewModal}
            segmentID={campaign && campaign.segment_id}
          />
        </>
      )}

      {openSyncModal && (
        <Modal
          show={openSyncModal}
          size="lg"
          className="[&>div>div]:py-6 [&>div>div]:px-6"
        >
          <p className="mb-6 text-sm font-medium text-[#111928] text-center">
            Synchronization with Google Ads is in progress.
            <br />
            Wait for it to finish before creating a Personalization Campaign
          </p>
          <div className="flex justify-center">
            <CustomButton
              type="outline"
              title="Ok"
              className="min-w-[100px] [&]:mr-0 justify-center"
              onClick={() => setOpenSyncModal(false)}
            />
          </div>
        </Modal>
      )}
    </section>
  );
}
