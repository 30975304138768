import { companyInfoPageAPI } from '..';
import { CompanyInfoDto } from '../types';

export const companyInfoAPI = companyInfoPageAPI.injectEndpoints({
  endpoints: (builder) => ({
    getCompanyInfo: builder.query<CompanyInfoDto, void>({
      query: () => ({
        url: 'api/company-info/load',
        method: 'POST',
      }),
      providesTags: ['getCompanyInfo'],
    }),
    saveCompanyInfo: builder.mutation<CompanyInfoDto, CompanyInfoDto>({
      query: (companyBody) => ({
        url: 'api/company-info/save',
        method: 'POST',
        body: { ...companyBody },
      }),
      invalidatesTags: ['getCompanyInfo'],
    }),
    deleteCompanyInfo: builder.mutation<any, { id: number }>({
      query: (id) => ({
        url: `/api/company-info/del/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['getCompanyInfo'],
    }),
  }),
});

export const {
  useGetCompanyInfoQuery,
  useLazyGetCompanyInfoQuery,

  useSaveCompanyInfoMutation,
  useDeleteCompanyInfoMutation,
} = companyInfoAPI;
