interface IActionCardType {
  title: string;
  description: string;
  onCardClick: () => void;
  Icon: JSX.Element;
}

const ActionTypeCard = ({
  title,
  description,
  onCardClick,
  Icon,
}: IActionCardType) => {
  return (
    <button
      className="w-[264px] h-[230px] p-[24px] border border-gray-200 shadow-md rounded-lg shrink-0 flex flex-col gap-y-2 cursor-pointer"
      onClick={() => onCardClick()}
      data-testid="elements_action_button"
    >
      {Icon}
      <p className="text-left leading-tight text-2xl font-bold">{title}</p>
      <p className="text-left text-base font-normal text-gray-500">
        {description}
      </p>
    </button>
  );
};

export default ActionTypeCard;
