const IFRAME_SANDBOX = 'allow-scripts allow-same-origin';

interface IIframe {
  iframeUrl: string;
  onLoadIframe?: () => void;
}

const Iframe = ({ iframeUrl, onLoadIframe }: IIframe) => {
  return (
    <div className="relative w-full h-[calc(100vh_-_57px)] small:h-[calc(100vh_-_87px)]">
      <iframe
        src={iframeUrl}
        height="100%"
        width="100%"
        sandbox={IFRAME_SANDBOX}
        onLoad={onLoadIframe}
      />
    </div>
  );
};

export default Iframe;
