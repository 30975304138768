import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Breadcrumb, Label, TextInput } from 'flowbite-react';
import { HiExclamation } from 'react-icons/hi';
import { format } from 'date-fns';

import {
  useChangePasswordMutation,
  useGetProfileQuery,
} from 'src/store/services/user/endpoints/user';
import {
  ChangePasswordRequest,
  UserProfile,
} from 'src/store/services/user/types';
import { useGetAllUserDomainsQuery } from 'src/store/services/client-domains/endpoints/client-domains';
import { parseError, parseResponse } from 'src/store/services/helpers';

import { customToastMessage } from 'src/components/toast';
import { useAppSelector } from 'src/store/hooks';
import Spinner from 'src/components/base-spinner';
import ModalBox from 'src/components/modal-box/modal-box';
import Button from 'src/components/button';

const LOGO_TOKEN = process.env.REACT_APP_LOGO_DEV_TOKEN;

export function AccountPage() {
  const { isLoading, data, error } = useGetProfileQuery();

  useEffect(() => {
    if (error) {
      const { data } = parseError(error);
      customToastMessage(
        data.message,
        <HiExclamation className="h-5 w-5" />,
        'error'
      );
    }
  }, [error]);

  return (
    <section>
      <div className="block items-center justify-between py-4 pt-6 px-10">
        <div className="mb-1 w-full">
          <Breadcrumb className="mb-4">
            <Breadcrumb.Item href="#">
              <div className="flex items-center gap-x-3">
                <span className="dark:text-white">Account</span>
              </div>
            </Breadcrumb.Item>
          </Breadcrumb>
          <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
            Account
          </h1>
        </div>
      </div>

      <div className="block items-center justify-between p-4 px-10">
        {isLoading && <Spinner />}
        {!isLoading && data?.data != null && <UserView user={data?.data} />}
      </div>
    </section>
  );
}

interface UserViewData {
  user: UserProfile;
}

const UserView: FC<UserViewData> = function ({ user }) {
  const avatarRef = useRef<HTMLImageElement>(null);
  const [showModal, setShowModal] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [changePassword, { isLoading, isError, isSuccess }] =
    useChangePasswordMutation();
  const { domains } = useAppSelector((state) => state.clientDomainReducer);
  useGetAllUserDomainsQuery(undefined, { skip: domains.length > 0 });
  const [domain, setDomain] = useState('');

  const handleUserDomain = useCallback(() => {
    if (domains.length > 0) {
      const domainUrl = domains[0].url.replace(/^https?:\/\//, '');
      setDomain(domainUrl);
    }
  }, [domains]);

  const handleChangeErrorImage = () => {
    if (avatarRef && avatarRef.current) {
      avatarRef.current.src = '/companies/company_1.png';
    }
  };

  const handleSubmit = async () => {
    if (confirmPassword !== newPassword) {
      setError("Passwords don't match");
      return;
    }

    const request: ChangePasswordRequest = {
      password: currentPassword,
      new_password: newPassword,
    };

    const changePasswordResult = await changePassword(request);
    const { result, error } = parseResponse(changePasswordResult);

    if (error) {
      if (error.status === 400) {
        setError(error.data.message);
      } else if (error.status === 422) {
        setError(error.data.message[0].error);
      } else {
        setError(error.error);
      }
    }

    if (result && !error) {
      setError('');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setSuccessMessage('Password changed successfully');
      setTimeout(() => setShowModal(false), 1500);
    }
  };

  useEffect(() => {
    handleUserDomain();
  }, [domains, handleUserDomain]);

  return (
    <div className="flex flex-col gap-y-8">
      <div className="flex rounded-lg bg-white shadow-sm dark:border-gray-700 dark:bg-gray-800 border-0 w-full overflow-hidden items-center gap-4 px-8 py-2">
        <div className="flex gap-4 items-center w-[426px]">
          <div className="max-w-[112px] max-h-[112px]">
            <img
              ref={avatarRef}
              src={
                domain
                  ? `https://img.logo.dev/${domain}?size=200&format=png&token=${LOGO_TOKEN}`
                  : `/companies/company_1.png`
              }
              alt="Company logo"
              onError={handleChangeErrorImage}
            />
          </div>
          <div>
            <div className="text-gray-900 text-xl font-bold">
              {user.username}
            </div>
            <div className="text-gray-500 text-base font-normal">
              {user.company_name}
            </div>
            <div></div>
          </div>
        </div>
        <div className="flex bg-gray-400 h-[78px] w-[1px]"></div>
        <div className="flex pl-[22px] pr-[35px]">
          <div>
            <div className="pb-2 text-gray-700 leading-tight text-xl font-bold">
              {user.sub.name}
            </div>
            <div className="text-gray-400 text-xs font-medium">
              Status <div className="text-green-400">Active</div>
            </div>
          </div>
          <div className="ml-[105px]">
            <div className="pb-2.5 pt-1.5 text-gray-700 leading-none text-base font-medium">
              {user.sub.price}
            </div>
            <div className="text-gray-400 text-xs font-medium">
              Next charge
              <div className="text-gray-700">
                {format(Date.parse(user.sub.next_charge), 'MMMM d, yyyy')}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex rounded-lg bg-white shadow-sm dark:border-gray-700 dark:bg-gray-800 border-0 w-full overflow-hidden items-center gap-4 px-8 py-8 pb-12">
        <div className="w-1/2">
          <div className="max-w-[437px] mb-4">
            <div className="mb-2 block">
              <Label htmlFor="userEmail" value="Email" />
            </div>
            <TextInput
              id="userEmail"
              type="email"
              placeholder={user.email}
              disabled={true}
            />
          </div>
          <div className="flex items-end">
            <div className="max-w-[437px] w-full">
              <div className="mb-2 block">
                <Label htmlFor="userPassword" value="Password" />
              </div>
              <TextInput
                id="userPassword"
                type="password"
                placeholder="****************"
                disabled={true}
              />
            </div>
            <Button
              className="ml-3 py-2.5"
              onClick={() => setShowModal(true)}
              title="Change password"
            />
          </div>
        </div>
        <ModalBox
          windowClassName="max-w-lg"
          isOpen={showModal}
          onClose={() => setShowModal(false)}
        >
          <ModalBox.Header onClose={() => setShowModal(false)}>
            Password information
          </ModalBox.Header>
          <ModalBox.Body className="p-8">
            <div>
              {isSuccess && !isError && (
                <div
                  className="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400"
                  role="alert"
                >
                  {successMessage}
                </div>
              )}

              {isError && (
                <div
                  className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                  role="alert"
                >
                  {error}
                </div>
              )}

              <div className="mb-4">
                <div className="mb-2 block">
                  <Label
                    htmlFor="formCurrentPassword"
                    value="Current password"
                  />
                </div>
                <TextInput
                  id="formCurrentPassword"
                  type="password"
                  value={currentPassword}
                  placeholder="Enter your current password"
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  data-testid="current_password_input"
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="formNewPassword" value="New password" />
                </div>
                <TextInput
                  id="formNewPassword"
                  type="password"
                  value={newPassword}
                  placeholder="Enter your new password"
                  onChange={(e) => setNewPassword(e.target.value)}
                  data-testid="new_password_input"
                />
              </div>
              <div className="my-4">
                <div className="mb-2 block">
                  <Label
                    htmlFor="formConfirmPassword"
                    value="Confirm new password"
                  />
                </div>
                <TextInput
                  id="formConfirmPassword"
                  type="password"
                  placeholder="Confirm your new password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  data-testid="confirm_password_input"
                />
              </div>
              <Button
                title="Change password"
                onClick={() => handleSubmit()}
                isLoading={isLoading}
                disabled={isLoading}
              />
            </div>
          </ModalBox.Body>
        </ModalBox>
      </div>
    </div>
  );
};
