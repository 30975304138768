import { ITBody } from './table-body';

const TableSkeleton = ({ columns, type }: ITBody) => {
  return (
    <tr>
      {columns.map((colItem: any, colIndex: number) => (
        <td
          key={colIndex}
          className={`p-3 border-b border-[#E5E7EB] ${
            type !== 'light' ? 'align-top' : ''
          }`}
          style={colItem.width && { width: colItem.width }}
        >
          <div
            className=" py-4 px-4 bg-gray-300 animate-pulse rounded-lg"
            style={{ height: '1.8rem', width: '90%' }}
          />
        </td>
      ))}
    </tr>
  );
};

export default TableSkeleton;
