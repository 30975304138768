import { useRef, useState, useEffect, useCallback } from 'react';
import { TextInput, Textarea } from 'flowbite-react';

import { IElement } from 'src/store/services/create-campaign/types';
import { ClearCross } from 'src/components/icons';
import ImageUploader from 'src/components/image-uploader/image-uploader';

import { TGPTGeneration } from './ad-elements';
import ChatGenerateSelect from './chat-generate-select';
import VariableSelect, { TItem, TSection } from './variable-select';

interface IElementInputProp {
  id: string | undefined;
  elementIndex: number;
  adsID: number;
  element: IElement;
  disabled: boolean;
  variables: TSection[];
  loadImage?: boolean;
  onDestinationChange: (index: number, url: string) => void;
  onHandleChangeElement: (index: number, variable: string) => void;
  handleOpenFallbackValueModal: (
    elementValue: string,
    selectedAttribute: TItem,
    index: number,
    adsID: number,
    cursorEnd: number
  ) => void;
  onGenerateWithGPT: (type: TGPTGeneration) => Promise<string>;
  onFixGrammarGPT: (type: TGPTGeneration) => Promise<string>;
  onImproveWithGPT: (type: TGPTGeneration) => Promise<string>;
  onBackAnswer: (value: string, index: number, adsID: number) => string;
}

const ElementInput = (props: IElementInputProp) => {
  const {
    onDestinationChange,
    onHandleChangeElement,
    handleOpenFallbackValueModal,
    onGenerateWithGPT,
    onFixGrammarGPT,
    onImproveWithGPT,
    onBackAnswer,
  } = props;
  const [generatedValue, setGeneratedValue] = useState<string[]>([]);
  const elementHtmlId = `element-input-${(Math.random() + 1)
    .toString(36)
    .substring(7)}-${props.id}-${props.adsID}`;

  const [valueHistory, setValueHistory] = useState<string[]>([]);
  const [currentValue, setCurrentValue] = useState<string>(
    props.element.personalized_value || ''
  );

  const handleGenerateValue = async (value: TGPTGeneration) => {
    const newArr = generatedValue;
    if (generatedValue.length === 3) {
      newArr.push(value.element.personalized_value || '');
      newArr.shift();
      setGeneratedValue(newArr);
    } else {
      newArr.push(value.element.personalized_value || '');
      setGeneratedValue(newArr);
    }
    const gptAnswer = await onGenerateWithGPT(value);
    handleChange(gptAnswer);
  };

  const handleFixValue = (value: TGPTGeneration) => {
    const newArr = generatedValue;
    if (generatedValue.length === 3) {
      newArr.push(value.element.personalized_value || '');
      newArr.shift();
      setGeneratedValue(newArr);
    } else {
      newArr.push(value.element.personalized_value || '');
      setGeneratedValue(newArr);
    }
    onFixGrammarGPT(value);
  };

  const handleImproveValue = (value: TGPTGeneration) => {
    const newArr = generatedValue;
    if (generatedValue.length === 3) {
      newArr.push(value.element.personalized_value || '');
      newArr.shift();
      setGeneratedValue(newArr);
    } else {
      newArr.push(value.element.personalized_value || '');
      setGeneratedValue(newArr);
    }
    onImproveWithGPT(value);
  };

  const handleBackValue = (index: number, adsID: number) => {
    const newArr = generatedValue;
    if (generatedValue.length > 0) {
      onBackAnswer(newArr[newArr.length - 1], index, adsID);
      newArr.pop();
      setGeneratedValue(newArr);
    }
  };

  const selectAttribute = (
    elementValue: string,
    selectedAttribute: TItem,
    index: number,
    adsID: number
  ) => {
    const cursorEnd = textareaRef.current?.selectionEnd;
    handleOpenFallbackValueModal(
      elementValue,
      selectedAttribute,
      index,
      adsID,
      cursorEnd || elementValue.length
    );
  };

  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const textInputRef = useRef<HTMLInputElement | null>(null);

  const handleUndo = useCallback(
    (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
        event.preventDefault();

        const focusedElement = document.activeElement;

        if (
          focusedElement === textareaRef.current ||
          focusedElement === textInputRef.current
        ) {
          if (valueHistory.length > 0) {
            const lastValue = valueHistory[valueHistory.length - 1];
            setValueHistory(valueHistory.slice(0, -1));
            setCurrentValue(lastValue);
            onHandleChangeElement(props.elementIndex, lastValue);
          }
        }
      }
    },
    [onHandleChangeElement, props.elementIndex, valueHistory]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleUndo);
    return () => {
      document.removeEventListener('keydown', handleUndo);
    };
  }, [handleUndo, valueHistory]);

  useEffect(() => {
    const value = props.element.personalized_value || '';
    setValueHistory([...valueHistory, currentValue]);
    setCurrentValue(value);
    onHandleChangeElement(props.elementIndex, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.element.personalized_value]);

  const handleChange = (text: string) => {
    if (text) {
      setValueHistory([...valueHistory, currentValue]);
      setCurrentValue(text);
      onHandleChangeElement(props.elementIndex, text);
    } else {
      setValueHistory([...valueHistory, currentValue]);
      setCurrentValue('');
      onHandleChangeElement(props.elementIndex, '');
    }
  };

  return (
    <label
      htmlFor={elementHtmlId}
      className={`block max-w-[425px] mb-5 snap-always snap-center${
        props.element.deletion === true ? ' hidden' : ''
      }`}
    >
      <div className="flex justify-between	">
        <p className="mb-2 text-xs font-medium text-[#4B5563]">
          {props.element.name}
        </p>
        {(props.element.tag_type === 'button' ||
          `${props.element.tag_type}` === '2') && (
          <button
            type="button"
            className="mb-2 border-0 bg-inherit text-xs text-[#1C64F2] disabled:text-[#E5E7EB]"
            onClick={() =>
              onDestinationChange(
                props.elementIndex,
                props.element.destination_url || ''
              )
            }
          >
            {props.element.destination_url ? (
              <div className="flex">
                <p className="flex text-xs text-[#E5E7EB] whitespace-nowrap">
                  → [
                  <span className="block max-w-[125px] overflow-hidden text-ellipsis">
                    {props.element.destination_url}
                  </span>
                  ]
                </p>
                <p className="ml-1 text-[#1C64F2]">Edit</p>
              </div>
            ) : (
              '+ Redirect URL'
            )}
          </button>
        )}
      </div>
      {(props.element.tag_type === 'text' ||
        `${props.element.tag_type}` === '1') && (
        <div>
          <div className="relative">
            <Textarea
              id={elementHtmlId}
              value={currentValue}
              placeholder={props.element.default_value}
              required={false}
              rows={3}
              className={`resize-none text-xs ${
                currentValue ? 'bg-green-100' : 'bg-white'
              }`}
              ref={textareaRef}
              onChange={(e) => handleChange(e.target.value)}
            />
          </div>
          <div className="flex flex-row justify-between mt-2">
            <ChatGenerateSelect
              title="Ask GPT to..."
              disabled={props.disabled}
              isLoading={props.disabled}
              onHandleGenerate={() =>
                handleGenerateValue({
                  element: props.element,
                  index: props.elementIndex,
                  adsID: props.adsID,
                })
              }
              onHandleFixGrammar={() =>
                handleFixValue({
                  element: props.element,
                  index: props.elementIndex,
                  adsID: props.adsID,
                })
              }
              onHandleImproveText={() =>
                handleImproveValue({
                  element: props.element,
                  index: props.elementIndex,
                  adsID: props.adsID,
                })
              }
              onHandleBackAnswer={() =>
                handleBackValue(props.elementIndex, props.adsID)
              }
            />
            <VariableSelect
              title="+ Variable"
              textItems={props.variables}
              onSelectItem={(e) =>
                selectAttribute(
                  props.element.personalized_value || '',
                  e,
                  props.elementIndex,
                  props.adsID
                )
              }
            />
          </div>
        </div>
      )}
      {(props.element.tag_type === 'image' ||
        `${props.element.tag_type}` === '3') && (
        <ImageUploader
          id={elementHtmlId}
          imageSrc={props.element.personalized_value}
          defaultImage={props.element.default_value}
          onSave={(image) => onHandleChangeElement(props.elementIndex, image)}
          loadImage={props.loadImage}
        />
      )}
      {(props.element.tag_type === 'button' ||
        `${props.element.tag_type}` === '2') && (
        <div>
          <div className="relative">
            <TextInput
              ref={textInputRef}
              id={elementHtmlId}
              type="text"
              className={`[&>div>input]:text-xs [&>div>input]:px-4 ${
                props.element.personalized_value
                  ? '[&>div>input]:bg-green-100'
                  : '[&>div>input]:bg-white'
              }`}
              placeholder={props.element.default_value}
              value={currentValue}
              required={false}
              onChange={(e) => handleChange(e.target.value)}
            />
            {currentValue && (
              <button
                className="absolute top-1/2 right-4 -translate-y-1/2"
                onClick={() => handleChange('')}
              >
                <ClearCross />
              </button>
            )}
          </div>
          <div className="flex flex-row justify-between mt-2">
            <ChatGenerateSelect
              title="Ask GPT to..."
              disabled={props.disabled}
              isLoading={props.disabled}
              onHandleGenerate={() =>
                handleGenerateValue({
                  element: props.element,
                  index: props.elementIndex,
                  adsID: props.adsID,
                })
              }
              onHandleFixGrammar={() =>
                handleFixValue({
                  element: props.element,
                  index: props.elementIndex,
                  adsID: props.adsID,
                })
              }
              onHandleImproveText={() =>
                handleImproveValue({
                  element: props.element,
                  index: props.elementIndex,
                  adsID: props.adsID,
                })
              }
              onHandleBackAnswer={() =>
                handleBackValue(props.elementIndex, props.adsID)
              }
            />
            <VariableSelect
              title="+ Variable"
              textItems={props.variables}
              onSelectItem={(e) =>
                selectAttribute(
                  props.element.personalized_value || '',
                  e,
                  props.elementIndex,
                  props.adsID
                )
              }
            />
          </div>
        </div>
      )}
    </label>
  );
};

export default ElementInput;
