import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ConversionActionItem } from '../services/conversion-action/types';
import { conversionActionAPI } from '../services/conversion-action/endpoints/conversion-action';
import { authAPI } from '../services/auth/endpoints/auth';

interface ActionType {
  actions: ConversionActionItem[];
}

const initialState: ActionType = {
  actions: [],
};

const conversionActionSlice = createSlice({
  name: 'conversionActionSlice',
  initialState,
  reducers: {
    setConversionActions: (
      state,
      action: PayloadAction<ConversionActionItem[]>
    ) => {
      state.actions = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(
        conversionActionAPI.endpoints.getActions.matchFulfilled,
        (state, action) => {
          state.actions = action.payload.data;
        }
      )
      .addMatcher(authAPI.endpoints.logoutQuery.matchFulfilled, (state) => {
        state = initialState;
        return state;
      })
      .addMatcher(authAPI.endpoints.login.matchFulfilled, (state) => {
        state = initialState;
        return state;
      });
  },
});

export const { setConversionActions } = conversionActionSlice.actions;

export default conversionActionSlice.reducer;
