import DomainComponent from 'src/features/domain/domain';
import {
  useCheckUserDomainQuery,
  useGetAllUserDomainsQuery,
} from 'src/store/services/client-domains/endpoints/client-domains';

export function DomainPage() {
  useGetAllUserDomainsQuery();
  useCheckUserDomainQuery({ url: '' });

  return (
    <section className="block items-center h-auto justify-between py-4 pt-6 px-10 pl-48">
      <div className="mb-1 w-full">
        <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
          Connect Pipedata AI to your domain
        </h1>
        <div className="flex rounded-lg border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800 flex-col p-[42px] max-w-[790px] mt-[25px]">
          <DomainComponent />
        </div>
      </div>
    </section>
  );
}
