import { useState } from 'react';
import { HeadDescriptionItem } from 'src/store/services/create-campaign/types';

interface IAdCard {
  title: string;
  url: string;
  description: string;
  id: number;
  ad_group: string;
  top_attributes: {
    headlines: HeadDescriptionItem[];
    descriptions: HeadDescriptionItem[];
  };
}

const AdCard = ({
  title,
  url,
  description,
  id,
  ad_group,
  top_attributes,
}: IAdCard) => {
  const [showAttributes, setShowAttibutes] = useState(false);
  return (
    <div>
      <p className="text-xs font-medium text-[#271EAE]">{title}</p>
      <a
        className="my-1.5 inline-block text-[10px] font-normal text-[#1A7239] leading-tight"
        href={url}
      >
        {url}
      </a>
      <p className="text-xs font-normal text-gray-800">{description}</p>
      <p className="my-1.5 text-[10px] font-medium text-[#271EAE]">id: #{id}</p>
      <p className="text-[10px] font-medium text-[#271EAE]">
        ad group: {ad_group}
      </p>
      {showAttributes && (
        <div className="mt-3">
          <p className="text-xs font-semibold text-[#271EAE]">Headlines:</p>
          {top_attributes.headlines.map((attribute) => (
            <div
              key={`${id}-${attribute.title}-${attribute.imp}`}
              className="mt-1 flex justify-between"
            >
              <p className="text-xs font-medium text-[#271EAE]">
                {attribute.title}
              </p>
              <p className="ml-5 text-xs font-medium text-[#271EAE] whitespace-nowrap">
                imp: {attribute.imp}
              </p>
            </div>
          ))}
          <p className="mt-4 text-xs font-semibold text-gray-800">
            Descriptions:{' '}
          </p>
          {top_attributes.descriptions.map((attribute) => (
            <div
              key={`${id}-${attribute.title}-${attribute.imp}`}
              className="mt-1 flex justify-between"
            >
              <p className="text-xs font-normal text-gray-800">
                {attribute.title}
              </p>
              <p className="ml-5 text-xs font-medium text-gray-800 whitespace-nowrap">
                imp: {attribute.imp}
              </p>
            </div>
          ))}
        </div>
      )}
      <button
        className="mt-3 py-1 px-3 rounded-lg bg-blue-100"
        onClick={() => setShowAttibutes(!showAttributes)}
      >
        <p className="text-xs font-normal text-blue-700">
          {showAttributes
            ? 'Hide top headlines/descriptions'
            : 'Show top headlines/descriptions'}
        </p>
      </button>
    </div>
  );
};

export default AdCard;
