import { useState } from 'react';
import BaseCheckbox from 'src/components/base-checkbox';
export type TGroupItem = {
  name: string;
  value: string;
  child?: TGroupItem[];
  parentID?: string;
};

interface IGroupProps {
  items: TGroupItem[];
  selectedItems: TGroupItem[];
  onHandleChecked: (selected: TGroupItem[]) => void;
}

const GroupCheckbox = ({
  items,
  selectedItems,
  onHandleChecked,
}: IGroupProps) => {
  const [selectedItemsArr, setSelectedItems] =
    useState<TGroupItem[]>(selectedItems);
  const areAllChildrenSelected = (parent: TGroupItem, lastAdded?: boolean) => {
    const childrenCount = parent.child ? parent.child.length : 0;
    const selectedChildrenCount = parent.child
      ? parent.child.filter((child) => selectedItemsArr.includes(child)).length
      : 0;

    if (lastAdded) {
      return childrenCount === selectedChildrenCount + 1;
    }
    return childrenCount === selectedChildrenCount;
  };

  const toggleChildSelection = (value: TGroupItem) => {
    const newItemsArr = selectedItemsArr.slice();
    if (selectedItemsArr.find((item) => item.value === value.value)) {
      const filteredArr = newItemsArr.filter((selectedValue) => {
        return (
          selectedValue.value !== value.value &&
          selectedValue.value !== value.parentID
        );
      });
      const unselectedArr = newItemsArr.filter((selectedValue) => {
        return (
          selectedValue.value === value.value ||
          selectedValue.value === value.parentID
        );
      });
      setSelectedItems(filteredArr);
      onHandleChecked(unselectedArr);
    } else {
      const parentItem = items.find((item) => item.value === value.parentID);
      let isParentSelected = false;
      if (parentItem && areAllChildrenSelected(parentItem, true)) {
        newItemsArr.push(parentItem);
        isParentSelected = true;
      }
      newItemsArr.push(value);
      const allValue =
        parentItem && isParentSelected ? [parentItem, value] : [value];

      setSelectedItems(newItemsArr);
      onHandleChecked(allValue);
    }
  };

  const toggleAllChildren = (parent: TGroupItem) => {
    if (areAllChildrenSelected(parent)) {
      const filteredArr = selectedItemsArr.filter((item) => {
        return (
          item !== parent &&
          !parent.child?.find((it) => it.value === item.value)
        );
      });
      const unselectedArr = selectedItemsArr.filter((item) => {
        return (
          item === parent || parent.child?.find((it) => it.value === item.value)
        );
      });

      setSelectedItems(filteredArr);
      onHandleChecked(unselectedArr);
    } else {
      const filteredItems = selectedItemsArr.filter(
        (item) => item.parentID !== parent.value
      );
      const newArr = [...filteredItems, parent];
      if (parent.child) newArr.push(...parent.child);

      setSelectedItems(newArr);
      onHandleChecked([parent]);
    }
  };

  const renderCheckboxes = (item: TGroupItem, index: number) => {
    const isSelected = selectedItemsArr.some(
      (selectedItem) => selectedItem.value === item.value
    );

    return (
      <div key={`${item.value}-${index}`} className="mt-3.5">
        <BaseCheckbox
          id={item.value}
          title={item.name}
          checked={isSelected}
          onHandleCheck={() =>
            item.child ? toggleAllChildren(item) : toggleChildSelection(item)
          }
        />
        <div className="ml-4">
          {item.child &&
            item.child.map((childItem, childIndex) =>
              renderCheckboxes(childItem, childIndex)
            )}
        </div>
      </div>
    );
  };

  return (
    <>
      {items.map((item: TGroupItem, index: number) =>
        renderCheckboxes(item, index)
      )}
    </>
  );
};

export default GroupCheckbox;
