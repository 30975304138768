import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux';

import { AppDispatch, RootState } from './index';
import { GoogleAdsData, IElement } from './services/create-campaign/types';

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch: () => AppDispatch = useDispatch;

export const adsTransform = (
  stateAds: GoogleAdsData[],
  payloadAds: GoogleAdsData[],
  stateElements: IElement[]
) => {
  const updatedAds = [...stateAds];
  payloadAds.forEach((item: any) => {
    const updatedElements: any = [];
    if (item.elements.length > 0) {
      item.elements.map((element: any) =>
        updatedElements.push({
          id: element.element.id,
          personalized_id: element.id,
          name: element.element.name,
          path: element.element.path,
          default_value: element.element.default_value,
          personalized_value: element.personalized_value,
          destination_url: element.destination_url,
          tag_type:
            (element.element.tag_type === 1 && 'text') ||
            (element.element.tag_type === 2 && 'button') ||
            'image',
          campaign_id: element.element.campaign_id,
          isPersonalized: false,
        })
      );
    }

    const existingAdIndex = updatedAds.findIndex((ad) => ad.id === item.id);

    if (existingAdIndex !== -1) {
      updatedAds[existingAdIndex] = {
        ...item,
        elements: [
          ...stateElements.filter(
            (itemEl) => !updatedElements.find((el: any) => itemEl.id === el.id)
          ),
          ...updatedElements,
        ],
      };
    } else {
      updatedAds.push({
        ...item,
        elements: [
          ...stateElements.filter(
            (itemEl) => !updatedElements.find((el: any) => itemEl.id === el.id)
          ),
          ...updatedElements,
        ],
      });
    }
  });

  return updatedAds;
};

export const elementTransform = (actionElements: IElement[]) => {
  return actionElements.map((element) => ({
    ...element,
    tag_type:
      (`${element.tag_type}` === '3' && 'image') ||
      (`${element.tag_type}` === '2' && 'button') ||
      'text',
  }));
};

export const elementTransformForDeleted = (
  actionElements: IElement[],
  elementId: string | number
) => {
  if (typeof elementId === 'number') {
    return actionElements.filter((e, index) => index !== elementId);
  } else {
    return actionElements.filter((e) => e.id !== elementId);
  }
};
