import { useEffect, useState } from 'react';
import { HiCheckCircle, HiExclamation } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

import {
  useDeleteSegmentMutation,
  useLazyGetAllSegmentsQuery,
  useLazyGetSegmentByIdQuery,
} from 'src/store/services/segments/endpoints/segments';
import { parseResponse } from 'src/store/services/helpers';

import Table from 'src/components/table/table';
import EmptyList from 'src/empty-list';
import ModalBox from 'src/components/modal-box/modal-box';
import Button from 'src/components/button';
import { customToastMessage } from 'src/components/toast';

import useSegmentsDataTransform from './hooks/useSegmentsDataTransform';
import { TABLE_DASHBOARD_COLUMNS } from './constants';
import Spinner from 'src/components/base-spinner';

const Segments = () => {
  const navigate = useNavigate();
  const [getSegmentById] = useLazyGetSegmentByIdQuery();
  const [getAllSegments] = useLazyGetAllSegmentsQuery();
  const { segments, isLoading, error } = useSegmentsDataTransform();
  const [confirmModal, setConfirmModal] = useState<number>();
  const [deleteSegment] = useDeleteSegmentMutation();

  const handleEditSegment = (segmentID: string) => {
    getSegmentById(segmentID);
    navigate(`/segments/update-segment/${segmentID}`);
  };

  const handleCloseConfirmModal = () => {
    setConfirmModal(undefined);
  };

  const handleDeleteSegment = async (segmentID: number) => {
    const response = await deleteSegment(segmentID);

    const { result, errorMessage } = parseResponse(response);
    if (errorMessage && !result) {
      customToastMessage(
        errorMessage,
        <HiExclamation className="h-5 w-5" />,
        'error',
        200
      );
    }

    if (result) {
      customToastMessage(
        result.data,
        <HiCheckCircle className="h-5 w-5" />,
        'success'
      );
    }

    getAllSegments();
    setConfirmModal(undefined);
  };

  useEffect(() => {
    if (error && !isLoading) {
      customToastMessage(
        error,
        <HiExclamation className="h-5 w-5" />,
        'error',
        200
      );
    }
  }, [isLoading, error]);

  if (isLoading) {
    return (
      <div className="w-full h-[calc(100vh_-_225px)] flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (segments.length < 1) {
    return <EmptyList title="Let’s Create your first Segment" />;
  }

  return (
    <>
      <Table
        type="light"
        data={segments}
        columns={TABLE_DASHBOARD_COLUMNS({
          handleEditSegment,
          setConfirmModal,
        })}
      />
      <ModalBox
        isOpen={!!confirmModal}
        onClose={handleCloseConfirmModal}
        windowClassName="max-w-md"
      >
        <ModalBox.Body>
          <p className="mb-4 text-base font-bold text-[#111928]">
            Are you sure you want to delete this segment?{' '}
          </p>
          <div className="flex justify-end">
            <Button
              type="danger"
              title="Delete"
              className="mr-4"
              onClick={() => handleDeleteSegment(confirmModal || 0)}
            />
            <Button
              type="gray"
              title="Cancel"
              className="[&]:bg-gray-200"
              onClick={handleCloseConfirmModal}
            />
          </div>
        </ModalBox.Body>
      </ModalBox>
    </>
  );
};

export default Segments;
