import { useNavigate } from 'react-router-dom';

import { AddCross } from 'src/components/icons';
import BreadCrumbs from 'src/components/bread-crumbs';
import Button from 'src/components/button';
import Segments from 'src/features/segments/segments';

export const SegmentsPage = () => {
  const navigate = useNavigate();

  const handleCreateSegment = () => {
    navigate('/segments/create-segment');
  };

  return (
    <section className="flex flex-col h-auto py-6 px-10">
      <div className="mb-4">
        <BreadCrumbs items={[{ title: 'Segments', link: '/segments' }]} />
      </div>

      <div className="flex items-center justify-between mb-[42px]">
        <h1 className="text-2xl font-semibold">Segments</h1>
        <Button
          type="default"
          title="New Segment"
          onClick={() => handleCreateSegment()}
          Icon={<AddCross />}
        />
      </div>
      <Segments />
    </section>
  );
};
