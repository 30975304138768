import { ResponseWrap } from '../../response_wrap';
import { conversionActionPageAPI } from '..';
import { ConversionActionDTO, ConversionActionItem } from '../types';

export const conversionActionAPI = conversionActionPageAPI.injectEndpoints({
  endpoints: (builder) => ({
    getActions: builder.query<ResponseWrap<ConversionActionItem[]>, void>({
      query: () => ({
        url: 'api/conversion-actions',
      }),
      providesTags: ['getActions'],
    }),
    storeAction: builder.mutation<{ message: string }, ConversionActionDTO>({
      query: (body) => ({
        url: 'api/conversion-actions',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['getActions'],
    }),
    updateAction: builder.mutation<{ message: string }, ConversionActionDTO>({
      query: (body) => ({
        url: `api/conversion-actions/${body.id}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['getActions'],
    }),
    deleteAction: builder.mutation<{ message: string }, { id: number }>({
      query: (params) => ({
        url: `api/conversion-actions/${params.id}`,
        method: 'DELETE',
        invalidatesTags: ['getActions'],
      }),
    }),
    protected: builder.mutation<{ message: string }, void>({
      query: () => 'protected',
    }),
  }),
});

export const {
  useStoreActionMutation,
  useUpdateActionMutation,
  useGetActionsQuery,
  useLazyGetActionsQuery,
  useDeleteActionMutation,
} = conversionActionAPI;
