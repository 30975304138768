import { CampaignListItem } from 'src/store/services/create-campaign/types';

export const findOldestDate = (
  objects: CampaignListItem[],
  isLoading?: boolean
) => {
  if (isLoading || objects.length === 0) return new Date();
  let oldestDate = new Date(objects[0].created_at);

  for (let i = 1; i < objects.length; i++) {
    const currentDate = new Date(objects[i].created_at);
    if (currentDate < oldestDate) {
      oldestDate = currentDate;
    }
  }

  return oldestDate;
};
