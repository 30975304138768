import { HiExclamation } from 'react-icons/hi';
import { customToastMessage } from 'src/components/toast';
import { useAppDispatch } from 'src/store/hooks';
import { useAddCustomImageMutation } from 'src/store/services/create-campaign/endpoints/create-campaign';
import { parseURL } from 'src/features/markup-editor/hooks/getSelectedElementValue';
import { IElement } from 'src/store/services/create-campaign/types';
import { parseResponse } from 'src/store/services/helpers';
import {
  setAdsElements,
  setDestinationUrl,
} from 'src/store/slices/campaignSlice';
import { useState } from 'react';
import { TItem, TSection } from './variable-select';
import ElementInput from './element-input';
import DestinationModal from './destination-modal';
import FallbackValueModal from './fallback-value-modal';

export type TGPTGeneration = {
  element: IElement;
  index: number;
  adsID: number;
};

interface IAdElements {
  adsID: number;
  elements: IElement[];
  disabled: boolean;
  variables: TSection[];
  onHandleSelectVariable: (
    variable: string,
    index: number,
    adsID: number
  ) => void;
  onGenerateWithGPT: (type: TGPTGeneration) => Promise<string>;
  onFixGrammarGPT: (type: TGPTGeneration) => Promise<string>;
  onImproveWithGPT: (type: TGPTGeneration) => Promise<string>;
  onBackAnswer: (value: string, index: number, adsID: number) => string;
}

const AdElements = ({
  adsID,
  elements,
  disabled,
  variables,
  onHandleSelectVariable,
  onGenerateWithGPT,
  onFixGrammarGPT,
  onImproveWithGPT,
  onBackAnswer,
}: IAdElements) => {
  const dispatch = useAppDispatch();
  const [addCustomImage, { isLoading: loadImage }] =
    useAddCustomImageMutation();
  const [openDestinationUrl, setOpenDestinationUrl] = useState(false);
  const [openFallbackValueModal, setOpenFallbackValueModal] = useState(false);
  const [selectedVariable, setSelectedVariable] = useState({
    elementValue: '',
    selectedAttribute: {
      value: '',
      label: '',
    },
    index: 0,
    adsID: 0,
    cursorEnd: 0,
  });
  const [destinationStateUrl, setDestinationStateUrl] = useState({
    index: 0,
    destination_url: '',
  });

  const handleChangeElement = async (elementIndex: number, value: any) => {
    if (
      elements[elementIndex].tag_type === 'image' &&
      typeof value !== 'string'
    ) {
      const formData = new FormData();
      formData.append('file', value);
      const haveImage = parseURL(
        elements[elementIndex].personalized_value || ''
      );

      formData.append('fileUrl', haveImage);

      const response = await addCustomImage(formData);
      const { result, error, errorMessage } = parseResponse(response);

      if (error && errorMessage) {
        customToastMessage(
          errorMessage,
          <HiExclamation className="h-5 w-5" />,
          'error'
        );
      }

      if (result) {
        dispatch(
          setAdsElements({
            adsID,
            elementIndex,
            newElementValue: result.data,
          })
        );
      }
    } else {
      dispatch(
        setAdsElements({
          adsID,
          elementIndex,
          newElementValue: value || null,
        })
      );
    }
  };

  const handleModalClose = (destination_url: string) => {
    dispatch(
      setDestinationUrl({
        adsID,
        elementIndex: destinationStateUrl.index,
        destination_url,
      })
    );
  };

  const handleOpenModal = (index: any, destination_url: any) => {
    setDestinationStateUrl({ index, destination_url });
    setOpenDestinationUrl(true);
  };

  const modifyStringWithVarivale = (
    oldString: string,
    variable: string,
    cursorIndex: number
  ) => {
    return (
      oldString.slice(0, cursorIndex) +
      `{${variable}}` +
      oldString.slice(cursorIndex)
    );
  };

  const handleOpenFallbackValueModal = (
    elementValue: string,
    selectedAttribute: TItem,
    index: number,
    adsID: number,
    cursorEnd: number
  ) => {
    if (selectedAttribute.hasFallbackValue) {
      setOpenFallbackValueModal(true);
      setSelectedVariable({
        elementValue,
        selectedAttribute,
        index,
        adsID,
        cursorEnd,
      });
      return;
    }
    onHandleSelectVariable(
      modifyStringWithVarivale(
        elementValue,
        selectedAttribute.value,
        cursorEnd
      ),
      index,
      adsID
    );
  };

  const handleCloseFallbackValueModal = () => {
    setOpenFallbackValueModal(false);
  };

  const handleSaveFallbackValueModal = (value: string) => {
    setOpenFallbackValueModal(false);
    const { elementValue, selectedAttribute, index, adsID, cursorEnd } =
      selectedVariable;
    onHandleSelectVariable(
      modifyStringWithVarivale(
        elementValue,
        `${selectedAttribute.label}, fallback value: ${value}`,
        cursorEnd
      ),
      index,
      adsID
    );
  };

  const handleRemoveDestinationUrl = (index: number) => {
    dispatch(
      setDestinationUrl({
        adsID,
        elementIndex: index,
        destination_url: undefined,
      })
    );
  };

  return (
    <div className="snap-y snap-mandatory">
      {elements.map((element, index) => (
        <ElementInput
          key={`${element.id}-${index}`}
          id={element.id}
          elementIndex={index}
          adsID={adsID}
          element={element}
          disabled={disabled}
          variables={variables}
          onDestinationChange={handleOpenModal}
          handleOpenFallbackValueModal={handleOpenFallbackValueModal}
          onHandleChangeElement={handleChangeElement}
          onGenerateWithGPT={onGenerateWithGPT}
          onFixGrammarGPT={onFixGrammarGPT}
          onImproveWithGPT={onImproveWithGPT}
          onBackAnswer={onBackAnswer}
          loadImage={loadImage}
        />
      ))}
      {openDestinationUrl && (
        <DestinationModal
          isOpen={openDestinationUrl}
          onClose={() => {
            setOpenDestinationUrl(false);
          }}
          onSave={handleModalClose}
          destination_url={destinationStateUrl.destination_url}
          onDelete={() => handleRemoveDestinationUrl(destinationStateUrl.index)}
        />
      )}
      <FallbackValueModal
        variable={selectedVariable.selectedAttribute.label}
        isOpen={openFallbackValueModal}
        onClose={handleCloseFallbackValueModal}
        onSave={(value) => handleSaveFallbackValueModal(value)}
      />
    </div>
  );
};

export default AdElements;
