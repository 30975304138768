import { useAppDispatch } from 'src/store/hooks';
import { authAPI } from 'src/store/services/auth/endpoints/auth';
import { conversionActionPageAPI } from 'src/store/services/conversion-action';
import { createCampaignPageAPI } from 'src/store/services/create-campaign';
import { googlePageAPI } from 'src/store/services/google';
import { segmentsPageAPI } from 'src/store/services/segments';
import { userAPI } from 'src/store/services/user';
import { variablesAPI } from 'src/store/services/variables';
import { clientDomainAPI } from 'src/store/services/client-domains/endpoints/client-domains';

const useClearStates = () => {
  const dispatch = useAppDispatch();
  const clearedRun = () => {
    dispatch(authAPI.util.resetApiState());
    dispatch(conversionActionPageAPI.util.resetApiState());
    dispatch(createCampaignPageAPI.util.resetApiState());
    dispatch(googlePageAPI.util.resetApiState());
    dispatch(segmentsPageAPI.util.resetApiState());
    dispatch(userAPI.util.resetApiState());
    dispatch(variablesAPI.util.resetApiState());
    dispatch(clientDomainAPI.util.resetApiState());
    localStorage.removeItem('syncing');
    localStorage.removeItem('latest_syncing');
    localStorage.removeItem('user_synchronized');
    localStorage.removeItem('detail_filters');
  };

  return { clearedRun };
};

export default useClearStates;
