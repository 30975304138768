function getNDistCDF(x: number, mean: number, stdev: number) {
  const a1 = 5.75885480458;
  const a2 = 2.62433121679;
  const a3 = 5.92885724438;
  const b1 = -29.8213557807;
  const b2 = 48.6959930692;
  const c1 = -0.000000038052;
  const c2 = 0.000398064794;
  const c3 = -0.151679116635;
  const c4 = 4.8385912808;
  const c5 = 0.742380924027;
  const c6 = 3.99019417011;
  const con = 1.28;
  const d1 = 1.00000615302;
  const d2 = 1.98615381364;
  const d3 = 5.29330324926;
  const d4 = -15.1508972451;
  const d5 = 30.789933034;
  const ltone = 7.0;
  const p = 0.398942280444;
  const q = 0.39990348504;
  const r = 0.398942280385;
  const utzero = 18.66;

  let up = false;
  let value;
  // let y;

  // For non-standard NDist
  let z = (x - mean) / stdev;

  if (z < 0) {
    up = true;
    z = -z;
  }

  if (ltone < z && (!up || utzero < z)) {
    value = (!up ? 0 : 1) * 1;
    return value;
  }

  const y = 0.5 * z * z;

  if (z <= con) {
    value = 0.5 - z * (p - (q * y) / (y + a1 + b1 / (y + a2 + b2 / (y + a3))));
  } else {
    value =
      (r * Math.exp(-y)) /
      (z +
        c1 +
        d1 /
          (z +
            c2 +
            d2 / (z + c3 + d3 / (z + c4 + d4 / (z + c5 + d5 / (z + c6))))));
  }

  if (!up) value = 1 - value;

  return value;
}

export function calcCR(visitors: number, leads: number): number {
  if (visitors > 0) {
    return Number(((leads / visitors) * 100).toFixed(2));
  }
  return 0;
}

export function isSignificant(
  a_conversion: number,
  a_group: number,
  b_conversion: number,
  b_group: number
) {
  const control_p = a_conversion / a_group;
  const variation_p = b_conversion / b_group;
  const control_se = Math.sqrt((control_p * (1 - control_p)) / a_group);
  const variation_se = Math.sqrt((variation_p * (1 - variation_p)) / b_group);
  const z_score =
    (control_p - variation_p) /
    Math.sqrt(Math.pow(control_se, 2) + Math.pow(variation_se, 2));
  const p_value = getNDistCDF(z_score, 0, 1);

  // 95 percent confidence
  return p_value < 0.05 || p_value > 0.95;
}

export function calcCRLift(
  full_visitors?: number,
  experimental_visitors?: number,
  full_leads?: number,
  experimental_leads?: number,
  detailLine?: boolean
) {
  if (
    !experimental_leads ||
    !full_leads ||
    !experimental_visitors ||
    !full_visitors
  ) {
    return detailLine ? '-' : '0%';
  }
  const calcControl = calcCR(full_visitors, full_leads);
  const calcExperimental = calcCR(experimental_visitors, experimental_leads);
  let lift: number | string;

  if (calcExperimental > 0 && calcControl <= 0) {
    lift = calcExperimental;
  } else {
    lift = ((calcExperimental - calcControl) / calcControl) * 100;
  }

  lift =
    lift < 0 ? (Math.floor(Math.abs(lift) * 100) * -1) / 100 : lift.toFixed(2);

  if (Number(lift) > 0) {
    lift = '+' + lift;
  }
  if (detailLine) {
    const result = lift + '%';
    if (full_leads > 0 || experimental_leads > 0) {
      return result;
    } else {
      return result === '0.00%' ? '-' : result;
    }
  }

  return lift + '%';
}
