import { openAiGenerationAPI } from '..';
import { IGenerationItem, IGenerationProp } from '../types';

export const openAiAPI = openAiGenerationAPI.injectEndpoints({
  endpoints: (builder) => ({
    createGeneration: builder.query<IGenerationItem, IGenerationProp>({
      query: ({ campaign_id, generation_type }) => ({
        url: `/api/openai-batch/gen/${campaign_id}/${generation_type}`,
      }),
    }),
    loadGenerationProcess: builder.query<IGenerationItem, IGenerationProp>({
      query: ({ campaign_id, generation_type }) => ({
        url: `/api/openai-batch/report/${campaign_id}/${generation_type}`,
      }),
    }),
    resumeGeneration: builder.query<IGenerationItem, IGenerationProp>({
      query: ({ campaign_id, generation_type }) => ({
        url: `/api/openai-batch/resume/${campaign_id}/${generation_type}`,
      }),
    }),
    stopGeneration: builder.query<void, IGenerationProp>({
      query: ({ campaign_id, generation_type }) => ({
        url: `/api/openai-batch/stop/${campaign_id}/${generation_type}`,
      }),
    }),
    handleGenerationProcess: builder.query<IGenerationItem, IGenerationProp>({
      queryFn: async (
        { campaign_id, generation_type },
        _queryApi,
        _extraOptions,
        baseQuery
      ) => {
        try {
          const reportResponse = await baseQuery(
            `/api/openai-batch/report/${campaign_id}/${generation_type}`
          );
          const reportData = (await reportResponse.data) as IGenerationItem;

          if (reportResponse.error || reportData.items.length === 0) {
            const createResponse = await baseQuery(
              `/api/openai-batch/gen/${campaign_id}/${generation_type}`
            );
            if (createResponse.error) {
              throw new Error('Failed to create generation');
            }
            return { data: createResponse.data as IGenerationItem };
          }

          if (
            (reportData.items.length > 0 && reportData.task.force_breaking) ||
            reportData.task.complete_dt !== null
          ) {
            const resumeResponse = await baseQuery(
              `/api/openai-batch/resume/${campaign_id}/${generation_type}`
            );
            if (resumeResponse.error) {
              throw new Error('Failed to resume generation');
            }
            return { data: resumeResponse.data as IGenerationItem };
          }

          return { data: reportData };
        } catch (error: any) {
          return { error: { status: 'CUSTOM_ERROR', error: error.message } };
        }
      },
    }),
  }),
});

export const {
  useLazyCreateGenerationQuery,
  useLazyLoadGenerationProcessQuery,
  useLazyResumeGenerationQuery,
  useLazyStopGenerationQuery,
  useLazyHandleGenerationProcessQuery,
} = openAiAPI;
