import { ClearbitLogo } from 'src/components/icons';

interface ISegmentedProp {
  count: number;
}

const SegmentedCompanies = ({ count }: ISegmentedProp) => {
  return (
    <div className="fixed top-[205px] right-[180px] flex flex-col max-w-[238px] bg-blue-50 p-5 rounded-lg z-10">
      <div className="py-2.5 px-7 bg-white rounded-lg">
        <p className="text-xl font-semibold text-gray-900 text-center">
          {count.toLocaleString()}
        </p>
        <p className="my-1 text-sm font-medium text-gray-900 text-center">
          matching companies
        </p>
        <p className="text-[11px] font-normal text-gray-700 text-center">
          Actual numbers may vary
        </p>
      </div>
      <ClearbitLogo className="mt-5 mx-auto" />
    </div>
  );
};

export default SegmentedCompanies;
