import MarkupEditor from 'src/features/markup-editor/MarkupEditor';

// Добавить список элементов при необходимости отслеживать одиночные элементы
// Пример: <MarkupEditor byElements={['h1', 'submit']} />;
// Где h1 - таг элемента, а submit - атрибут type

// Добавить лимит списка элементов при необходимости выбирать ограниченное количество элементов
// Пример: <MarkupEditor limitElements={1} />
// Пример: <MarkupEditor byElements={['h1', 'h2']} limitElements={1} />
// Номер {1} максимальное количество выбираемых элементов

export function MarkupPage() {
  return <MarkupEditor />;
}
