import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { LoginPage } from './pages/LoginPage';
import ProtectedRoute from './pages/ProtectedRoute';
import { AccountPage } from './pages/AccountPage';
import { DomainPage } from './pages/DomainPage';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { loadUser } from './store/slices/authSlice';
import { MarkupPage } from './pages/MarkupPage';
import { IntegrationPage } from './pages/IntegrationPage';
import ActionsPage from './pages/ActionsPage';
import { CampaignsPage } from './pages/CampaignsPage';
import { CreateCampaignPage } from './pages/CreateCampaignPage';
import { DetailCampaignPage } from './pages/DetailCampaignPage';
import UpdateCampaignPage from './pages/UpdateCampaignPage';
import { SegmentsPage } from './pages/SegmentsPage';
import { CreateSegmentsPage } from './pages/CreateSegmentPage';
import { UpdateSegmentsPage } from './pages/UpdateSegmentPage';
import CompanyInfoPage from './pages/CompanyInfoPage';

function App() {
  const dispatch = useAppDispatch();

  const { isAuthenticated } = useAppSelector((state) => state.authReducer);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(loadUser());
    }
  }, [dispatch, isAuthenticated]);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/campaigns" />} />

      <Route element={<ProtectedRoute />}>
        <Route path="/campaigns">
          <Route index element={<CampaignsPage />} />
          <Route path=":id" element={<DetailCampaignPage />} />
          <Route path="create-campaign" element={<CreateCampaignPage />} />
          <Route path="create-campaign/:id" element={<CreateCampaignPage />} />
          <Route path="update-campaign/:id" element={<UpdateCampaignPage />} />
        </Route>
        <Route path="/segments">
          <Route index element={<SegmentsPage />} />
          <Route path="create-segment" element={<CreateSegmentsPage />} />
          <Route path="update-segment/:id" element={<UpdateSegmentsPage />} />
        </Route>
        <Route path="/account" element={<AccountPage />} />
        <Route path="/domain" element={<DomainPage />} />
        <Route path="/integrations" element={<IntegrationPage />} />
        <Route path="/actions" element={<ActionsPage />} />
        <Route path="/company-info" element={<CompanyInfoPage />} />
      </Route>
      <Route path="/markup-editor" element={<MarkupPage />} />

      <Route path="/login" element={<LoginPage />} />
    </Routes>
  );
}

export default App;
