import { useEffect, useState } from 'react';
import { HiExclamation } from 'react-icons/hi';

import { customToastMessage } from 'src/components/toast';

import { useAppSelector } from 'src/store/hooks';
import { parseResponse } from 'src/store/services/helpers';
import { useLazyGetSignUrlGoogleAuthQuery } from 'src/store/services/google/endpoints/google';

import IntegrationCard from './blocks/integration-card';
import GAModal from './blocks/ga-modal';
import GAConfigureModal from './blocks/ga-configure-modal';
import { SyncStatus } from 'src/store/services/google/types';

interface IIntegrationProp {
  isLoading: boolean;
}

const Integration = ({ isLoading }: IIntegrationProp) => {
  const [showModal, setShowModal] = useState(false);
  const [syncDisabled, setSyncDisabled] = useState(false);
  const [showModalConfigure, setShowModalConfigure] = useState(false);
  const [gaLoading, setGaLoading] = useState(false);
  const { isSyncing, latestSync } = useAppSelector(
    (state) => state.googleSyncReducer
  );
  const [getSignUrlGoogleAuth] = useLazyGetSignUrlGoogleAuthQuery();

  const handleShowInfo = (helpButton: boolean) => {
    if (!latestSync) {
      customToastMessage(
        "For proper analytics integration, it's necessary to link your Google account.",
        <HiExclamation className="h-5 w-5" />,
        'error'
      );
      return;
    }
    if (helpButton) {
      setShowModal(true);
    } else {
      setShowModalConfigure(true);
    }
  };

  const handleConnect = async () => {
    const { data, error } = await getSignUrlGoogleAuth();
    const { result, errorMessage } = parseResponse({ data, error });

    if (error && errorMessage) {
      customToastMessage(
        errorMessage,
        <HiExclamation className="h-5 w-5" />,
        'error'
      );
    }

    if (result) {
      window.location.href = result.data.url;
    }
  };

  useEffect(() => {
    if (SyncStatus[isSyncing] === SyncStatus[2] || isLoading) {
      setSyncDisabled(true);
    } else {
      setSyncDisabled(false);
    }
  }, [isLoading, isSyncing]);

  return (
    <>
      <div className="flex">
        <IntegrationCard
          type="sync"
          image="/assets/images/ic_google.png"
          lazyImage="/assets/images/ic_google_lazy.png"
          onHandleConnect={handleConnect}
          disabled={syncDisabled}
          isSyncing={isSyncing}
          isLoading={isLoading}
        />
        <IntegrationCard
          type="analytic"
          image="/assets/images/google_analitic.png"
          lazyImage="/assets/images/google_analitic_lazy.png"
          onHandleConnect={() => handleShowInfo(false)}
          disabled={syncDisabled}
          onShowInfo={() => handleShowInfo(true)}
          isLoading={isLoading}
          isLoadingRemoteGAConfig={gaLoading}
        />
      </div>
      <GAModal
        showModal={showModalConfigure}
        handleHideModal={() => setShowModalConfigure(false)}
        setGaConfigLoading={setGaLoading}
      />
      <GAConfigureModal
        open={showModal}
        onClose={() => {
          setShowModal(false);
          setShowModalConfigure(true);
        }}
      />
    </>
  );
};

export default Integration;
