import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { setPreviewStep } from 'src/store/slices/storageSlice';
import { useLazyStopGenerationQuery } from 'src/store/services/open-ai-generation/endpoints/open-ai-generation';

export const useBrowserNavigation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const [stopGeneration] = useLazyStopGenerationQuery();
  const { campaignStep } = useAppSelector((state) => state.storageReducer);
  const { campaignID, generationType, totalAds, currentAds } = useAppSelector(
    (state) => state.createCampaignReducer
  );

  useEffect(() => {
    const browserGoBack = () => {
      if (campaignStep === 1) {
        navigate('/campaigns');
      } else {
        if (!pathname.includes('update')) {
          if (campaignStep === 3 && campaignID && totalAds !== currentAds) {
            stopGeneration({
              campaign_id: campaignID,
              generation_type: generationType,
            });
          }
        }
        dispatch(setPreviewStep());
      }
    };

    window.addEventListener('popstate', browserGoBack);
    return () => {
      window.removeEventListener('popstate', browserGoBack);
    };
  }, [
    campaignID,
    campaignStep,
    currentAds,
    dispatch,
    generationType,
    navigate,
    pathname,
    stopGeneration,
    totalAds,
  ]);
};
