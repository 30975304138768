import TableSkeleton from './table-skeleton';

export interface ITBody {
  data: any[];
  columns: any[];
  type?: 'dark' | 'light' | 'even-bg';
  isLoading?: boolean;
}

const TBody = ({ data, columns, type, isLoading }: ITBody) => {
  return (
    <tbody>
      {isLoading
        ? Array.from({ length: 3 }).map((_, rowIndex) => (
            <TableSkeleton
              key={`skeleton-${rowIndex}`}
              data={[]}
              columns={columns}
            />
          ))
        : data.map((rowItem: any, rowIndex: number) => (
            <tr
              key={rowIndex}
              className={`${
                type === 'dark'
                  ? 'odd:bg-[#F9FAFB]'
                  : type === 'even-bg'
                  ? 'even:bg-[#F9FAFB]'
                  : ''
              }`}
            >
              {columns.map((colItem: any, colIndex: number) => (
                <td
                  key={colIndex}
                  className={`p-3 border-b border-[#E5E7EB] ${
                    type !== 'light' ? 'align-top' : ''
                  } ${colItem.className ? colItem.className(rowItem) : ''}`}
                  style={colItem.width && { width: colItem.width }}
                >
                  {colItem.render
                    ? colItem.render(rowItem, rowIndex)
                    : rowItem?.[colItem.key] ?? ''}
                </td>
              ))}
            </tr>
          ))}
    </tbody>
  );
};

export default TBody;
