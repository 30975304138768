import { googlePageAPI } from '..';
import { ResponseWrap } from '../../response_wrap';
import {
  CheckGoogleSync,
  FindGoogleToken,
  GoogleAdsParams,
  GoogleAnalyticsConfig,
  GoogleSignIn,
  SyncGoogleAccount,
} from '../types';

export const googleAPI = googlePageAPI.injectEndpoints({
  endpoints: (builder) => ({
    getSignUrlGoogleAuth: builder.query<ResponseWrap<GoogleSignIn>, void>({
      query: () => ({
        url: 'api/integrations/auth/google',
      }),
    }),
    syncGoogleAccount: builder.query<CheckGoogleSync, void>({
      query: () => ({
        url: 'api/job-manager/google/all',
      }),
    }),
    syncGoogleAds: builder.query<CheckGoogleSync, void>({
      query: () => ({
        url: 'api/job-manager/google/ads',
      }),
    }),
    checkIsSyncedGoogle: builder.query<CheckGoogleSync, void>({
      query: () => ({
        url: 'api/job-manager/google/status/check',
      }),
    }),
    getGAConfig: builder.query<ResponseWrap<GoogleAnalyticsConfig>, void>({
      query: () => ({
        url: 'api/integrations/ga/config',
      }),
    }),
    verifyAdsUrl: builder.query<GoogleAdsParams[], void>({
      query: () => ({
        url: 'api/integrations/google-ads/url-verify',
      }),
    }),
    getGoogleSignInValidate: builder.query<boolean, void>({
      query: () => ({
        url: 'api/integrations/auth/check',
      }),
    }),
    storeGoogleTokens: builder.mutation<
      ResponseWrap<CheckGoogleSync>,
      FindGoogleToken
    >({
      query: (body) => ({
        url: 'api/integrations/auth/google/code',
        method: 'POST',
        body: body,
      }),
    }),
    storeGoogleAnalyticsConfig: builder.mutation<
      SyncGoogleAccount,
      GoogleAnalyticsConfig
    >({
      query: (body) => ({
        url: 'api/integrations/ga/config',
        method: 'POST',
        body: body,
      }),
    }),
  }),
});

export const {
  useLazyGetSignUrlGoogleAuthQuery,
  useCheckIsSyncedGoogleQuery,
  useLazyCheckIsSyncedGoogleQuery,
  useLazySyncGoogleAccountQuery,
  useGetGAConfigQuery,
  useLazySyncGoogleAdsQuery,
  useLazyVerifyAdsUrlQuery,
  useGetGoogleSignInValidateQuery,
  useLazyGetGoogleSignInValidateQuery,

  useStoreGoogleTokensMutation,
  useStoreGoogleAnalyticsConfigMutation,
} = googleAPI;
