import BaseCheckbox from 'src/components/base-checkbox';

interface IAICardProps {
  id: string;
  title: string;
  description: string;
  icon: JSX.Element;
  checked: boolean;
  handleCheck: (id: string) => void;
}

const AiMethodCard = ({
  id,
  title,
  description,
  checked,
  icon,
  handleCheck,
}: IAICardProps) => {
  return (
    <label
      htmlFor={id}
      className={`max-w-[250px] w-full p-6 border ${
        checked ? 'border-primary-600' : 'border-gray-300'
      } rounded-lg shadow-md cursor-pointer`}
    >
      <div className="flex justify-between">
        {icon}
        <BaseCheckbox
          id={id}
          checked={checked}
          onHandleCheck={() => handleCheck(id)}
        />
      </div>
      <p className="my-3 text-lg font-bold leading-tight">{title}</p>
      <p className="text-sm font-normal text-gray-600">{description}</p>
    </label>
  );
};

export default AiMethodCard;
