import TableHeader, { ITableHeader } from './table-header';
import TableHead from './table-head';
import TableBody from './table-body';
import TablePagination, { ITablePaginator } from './table-pagination';
import { useEffect, useRef } from 'react';

export interface ITableColumns {
  key: string;
  label?: string | (() => void);
  width?: string;
  className?: (item: any) => void;
  render?: (item: any, rowIndex: number) => void;
}

interface ITable {
  data: any;
  columns: ITableColumns[];
  headerContent?: ITableHeader;
  paginatorContent?: ITablePaginator;
  type?: 'dark' | 'light' | 'even-bg';
  noOverflow?: boolean;
  stickHead?: boolean;
  isLoading?: boolean;
}

const Table = ({
  data,
  columns,
  headerContent,
  paginatorContent,
  type,
  noOverflow = false,
  stickHead,
  isLoading,
}: ITable) => {
  const currentTableRef = useRef<HTMLDivElement>(null);
  const stickyHeadRef = useRef<HTMLDivElement>(null);

  const handleScroll = (event: any) => {
    const scrollLeft = (event.target as HTMLElement).scrollLeft;
    const tableHeaders = stickyHeadRef.current;
    if (tableHeaders) {
      tableHeaders.scrollLeft = scrollLeft;
    }
  };

  useEffect(() => {
    const currentTableRefValue = currentTableRef;
    const fixFullHeightTable = () => {
      const tBody = currentTableRefValue.current as HTMLDivElement;
      const stickyHead = stickyHeadRef.current as HTMLDivElement;

      const thead = tBody.querySelector('thead') as HTMLTableSectionElement;
      const stickyThead = stickyHead.querySelector(
        'thead'
      ) as HTMLTableSectionElement;
      const stickyColumns = stickyThead.querySelectorAll('th');

      const ths = thead.querySelectorAll('th');
      for (let i = 0; i < ths.length; i++) {
        const th = ths[i];
        stickyColumns[i].style.minWidth = `${th.offsetWidth}px`;
        stickyColumns[i].style.maxWidth = `${th.offsetWidth}px`;
        stickyColumns[i].style.width = `${th.offsetWidth}px`;
      }

      tBody.style.marginTop = `-${thead.offsetHeight}px`;
    };

    if (stickHead) {
      fixFullHeightTable();
      currentTableRefValue.current?.addEventListener(
        'scroll',
        fixFullHeightTable
      );
    }

    return () => {
      if (stickHead) {
        currentTableRefValue.current?.removeEventListener(
          'scroll',
          fixFullHeightTable
        );
      }
    };
  }, [stickHead]);

  return (
    <div className="bg-[#FFFFFF] rounded-lg shadow-md">
      {headerContent && <TableHeader {...headerContent} />}
      {stickHead && (
        <div
          ref={stickyHeadRef}
          className="webkit-table-sticky table-sticky top-0 overflow-x-hidden z-[1]"
        >
          <table className="w-full border-spacing-0">
            <TableHead columns={columns} type={type} />
          </table>
        </div>
      )}
      <div
        className={`${noOverflow ? '' : 'overflow-x-auto'}`}
        ref={currentTableRef}
        onScroll={handleScroll}
      >
        <table className="w-full border-spacing-0">
          <TableHead columns={columns} type={type} />
          <TableBody
            data={data}
            columns={columns}
            type={type}
            isLoading={isLoading}
          />
        </table>
      </div>
      {paginatorContent && <TablePagination {...paginatorContent} />}
    </div>
  );
};

export default Table;
