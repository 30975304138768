import { Navigate } from 'react-router-dom';
import Layout from 'src/features/base-layout/layout';
// import MainLayout from 'src/features/layouts/main-layout';
import { useAppSelector } from 'src/store/hooks';

const ProtectedRoute = () => {
  const { isAuthenticated } = useAppSelector((state) => state.authReducer);

  if (isAuthenticated === false) {
    return <Navigate to="/login" replace />;
  }

  // returns layout with child routers
  // return <MainLayout />;
  return <Layout />;
};
export default ProtectedRoute;
