import { useAppSelector } from 'src/store/hooks';
import { usePublishCampaignMutation } from 'src/store/services/create-campaign/endpoints/create-campaign';

const useCampaignPublish = () => {
  const {
    campaignID,
    campaignName,
    googleAds,
    siteUrl,
    googleAdsAccount,
    googleAdsCampaing,
    elements,
    distributionType,
    actionsID,
    hypothesis_description,
    segmentID,
  } = useAppSelector((state) => state.createCampaignReducer);
  const [publishCampaign, { data, isLoading, error: publishErrorMessage }] =
    usePublishCampaignMutation();
  const handlePublish = async () => {
    await publishCampaign({
      campaign_id: campaignID,
      name: campaignName,
      page_url: siteUrl,
      google_ads_id: googleAdsAccount,
      google_campaign_id: googleAdsCampaing,
      personalized_ads:
        googleAds.filter(
          (ad) =>
            ad.isPersonalized ||
            ad.elements?.find(
              (el) => el.personalized_value || el.destination_url
            )
        ) || [],
      elements: elements || [],
      distribution_type: distributionType,
      actions_id: actionsID,
      hypothesis_description: hypothesis_description,
      segment_id: segmentID,
    });
  };

  return {
    handlePublish,
    isPublishLoading: isLoading,
    publishErrorMessage,
    result: data,
  };
};

export default useCampaignPublish;
