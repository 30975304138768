import { useEffect, useMemo, useState } from 'react';
import { ISelectItems } from 'src/components/selects/checkbox-select';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { useGetActionsQuery } from 'src/store/services/conversion-action/endpoints/conversion-action';
import {
  setActionsID,
  setDistributionType,
} from 'src/store/slices/campaignSlice';

const DISTRIBUTION: ISelectItems[] = [
  {
    label: 'Experiment',
    value: '50',
    title: '50% Personalized / 50% Control',
    description:
      'Start with this one and tune it until you get a great result with statistical significance.',
  },
  {
    label: 'Full',
    value: '100',
    title: '100% Personalized',
    description: 'Select this one when you’ve got a winner on your hands',
  },
];

export const useSelectDistributionValues = () => {
  const dispatch = useAppDispatch();
  const { distributionType } = useAppSelector(
    (state) => state.createCampaignReducer
  );
  const [activeValues, setActiveValues] = useState<ISelectItems[]>([]);

  const handleSelectedValue = (value: ISelectItems) => {
    setActiveValues([value]);
    dispatch(setDistributionType(Number(value.value)));
  };

  useEffect(() => {
    if (distributionType) {
      const getActiveValues = DISTRIBUTION.find(
        (item) => item.value === `${distributionType}`
      );
      setActiveValues([
        {
          label: getActiveValues?.label || '',
          value: getActiveValues?.value || '',
        },
      ]);
    }
  }, [distributionType]);

  return {
    active: activeValues,
    allValues: DISTRIBUTION,
    handleSelect: handleSelectedValue,
  };
};

const useSelectActionsValues = () => {
  const dispatch = useAppDispatch();
  useGetActionsQuery();
  const { actionsID } = useAppSelector((state) => state.createCampaignReducer);
  const [activeValues, setActiveValues] = useState<ISelectItems[]>([]);
  const { actions } = useAppSelector((state) => state.conversionActionReducer);
  const validSelectValue = useMemo(
    () =>
      actions.map((action) => ({
        label: action.name,
        value: action.id,
      })),
    [actions]
  );

  const handleSelectedValue = (action: ISelectItems) => {
    const newValues = activeValues.find((item) => item.value === action.value)
      ? activeValues.filter((item) => item.value !== action.value)
      : [...activeValues, action];

    setActiveValues(newValues);
    dispatch(setActionsID(newValues.map((item) => item.value)));
  };

  useEffect(() => {
    if (actionsID) {
      const getActiveValues = validSelectValue.filter((item) => {
        return actionsID.find((id) => {
          return id === item.value;
        });
      });
      setActiveValues(getActiveValues);
    }
  }, [actionsID, validSelectValue]);

  return {
    validSelectValue,
    activeValues,
    handleSelectedValue,
  };
};

export default useSelectActionsValues;
