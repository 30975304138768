import { ReactNode } from 'react';
import Spinner from './base-spinner';

interface IButtonProp {
  title: string;
  type?: 'default' | 'outline' | 'dark' | 'gray' | 'danger';
  Icon?: ReactNode;
  className?: string;
  reverse?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  onClick: (e?: any) => void;
  dataTest?: string;
  buttonType?: 'button' | 'submit' | 'reset' | undefined;
}

const Button = ({
  title,
  type = 'default',
  Icon,
  className,
  reverse = false,
  disabled,
  isLoading,
  onClick,
  dataTest,
  buttonType = 'button',
}: IButtonProp) => {
  const buttonTypeClass = {
    default: `bg-[#1A56DB] border-[#1A56DB] text-[#FFFFFF] hover:bg-[#1e429f] disabled:border-[#E5E7EB] disabled:bg-[#E5E7EB] disabled:text-[#1F2A37] ${className}`,
    outline: `border-[#1A56DB] text-[#1A56DB] mr-[20px] disabled:border-[#E5E7EB] disabled:bg-[#E5E7EB] disabled:text-[#1F2A37] ${className}`,
    dark: `bg-[#1f2937] border-[#1f2937] text-[#FFFFFF] hover:bg-[#111827] disabled:border-[#E5E7EB] disabled:bg-[#E5E7EB] disabled:text-[#1F2A37] ${className}`,
    gray: `bg-white border-gray-300 text-gray-900 hover:bg-gray-100 disabled:border-[#E5E7EB] disabled:bg-[#E5E7EB] disabled:text-[#1F2A37] ${className}`,
    danger: `bg-red-700 border-red-300 text-white hover:bg-red-900 disabled:border-[#E5E7EB] disabled:bg-[#E5E7EB] disabled:text-[#1F2A37] ${className}`,
  };

  return (
    <button
      type={buttonType}
      className={`flex items-center py-2 px-3 border ${
        reverse && 'flex-row-reverse'
      } ${
        buttonTypeClass[type]
      } rounded-lg text-sm font-medium active:shadow-lg disabled:pointer-events-none`}
      onClick={onClick}
      disabled={disabled}
      data-testid={dataTest}
    >
      {Icon}
      <span>{title}</span>
      {isLoading && <Spinner className={reverse ? 'mr-2' : 'ml-2'} />}
    </button>
  );
};

export default Button;
