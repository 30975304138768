import { useAppSelector } from 'src/store/hooks';
import { GoogleAdsData } from 'src/store/services/create-campaign/types';

const useStepFourDataTransform = () => {
  const {
    campaignName,
    googleAdsAccountName,
    googleAdsCampaingName,
    siteUrl,
    elements,
    googleAds,
  } = useAppSelector((state) => state.createCampaignReducer);

  const findElementsCount = (ads: GoogleAdsData[]) => {
    return ads
      .filter(
        (item) =>
          item.isPersonalized ||
          item.elements?.find((el) => el.personalized_value)
      )
      .reduce((count, ad) => {
        if (ad && ad.elements) {
          return (
            count +
            ad.elements.reduce((innerCount, element) => {
              return (
                innerCount +
                (element.personalized_value || element.destination_url ? 1 : 0)
              );
            }, 0)
          );
        }
        return count;
      }, 0);
  };

  const transformData = [];
  if (campaignName) {
    transformData.push({
      title: 'Campaign Name',
      value: campaignName,
    });
  }
  if (googleAdsAccountName) {
    transformData.push({
      title: 'Google Account',
      value: googleAdsAccountName,
    });
  }
  if (googleAdsCampaingName) {
    transformData.push({
      title: 'Google Ads Campaign',
      value: googleAdsCampaingName,
    });
  }
  if (siteUrl) {
    transformData.push({
      title: 'URL of the page which will be personalized',
      value: siteUrl,
    });
  }
  if (elements) {
    transformData.push({
      title: 'Title of Personalization Elements',
      value: elements
        .filter((e) => e.deletion !== true)
        .map((element) => element.name),
    });
  }
  if (googleAds) {
    transformData.push({
      title: 'Number of personalized ads ',
      value: googleAds.filter((ads) =>
        ads.elements
          ?.filter((e) => !e.deletion)
          .find((item) => item.personalized_value || item.destination_url)
      ).length,
    });
    transformData.push({
      title: 'Number of personalized keywords ',
      value: googleAds
        .filter(
          (ad) =>
            ad.isPersonalized ||
            ad.elements?.find(
              (el) => el.personalized_value || el.destination_url
            )
        )
        .reduce((total, ad) => total + (ad.keywords?.length || 0), 0),
    });
    transformData.push({
      title: 'Number of personalized elements',
      value: findElementsCount(googleAds),
    });
  }

  return {
    transformData,
  };
};

export default useStepFourDataTransform;
