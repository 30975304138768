import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { authAPI } from '../services/auth/endpoints/auth';
import { openAiAPI } from '../services/open-ai-generation/endpoints/open-ai-generation';
import { clientDomainAPI } from '../services/client-domains/endpoints/client-domains';

const queryParams = new URLSearchParams(window.location.search);

interface Storage {
  campaignStep: number;
  isSidebarOpen: boolean;
  redirectAfterSync: string;
  userDomain: string;
  globalLoading: boolean;
  aiLoader: boolean;
  userCopyInput: string;
}

const initialState: Storage = {
  userDomain: '',
  userCopyInput: '',
  campaignStep: Number(queryParams.get('step')) || 1,
  isSidebarOpen: true,
  redirectAfterSync: '',
  globalLoading: false,
  aiLoader: false,
};

const storageSlice = createSlice({
  name: 'storageSlice',
  initialState,
  reducers: {
    setStep: (state, action: PayloadAction<number>) => {
      state.campaignStep = action.payload ? action.payload : 1;
      return state;
    },
    setNextStep: (state) => {
      state.campaignStep += 1;
      return state;
    },
    setPreviewStep: (state) => {
      state.campaignStep -= 1;
      return state;
    },
    setClearCampaingsStep: (state) => {
      state.campaignStep = 1;
      return state;
    },
    setSidebarOpen: (state, action: PayloadAction<boolean>) => {
      state.isSidebarOpen = action.payload;
      return state;
    },
    setRedirectAfterSync: (state, action: PayloadAction<string>) => {
      state.redirectAfterSync = action.payload;
      return state;
    },
    setGlobalLoading: (state, action: PayloadAction<boolean>) => {
      state.globalLoading = action.payload;
      return state;
    },
    setUserDomain: (state, action: PayloadAction<string>) => {
      state.userDomain = action.payload;
      return state;
    },
    setAiLoader: (state, action: PayloadAction<boolean>) => {
      state.aiLoader = action.payload;
      return state;
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(authAPI.endpoints.logoutQuery.matchFulfilled, (state) => {
        state = initialState;
        return state;
      })
      .addMatcher(authAPI.endpoints.login.matchFulfilled, (state) => {
        state = initialState;
        return state;
      })
      .addMatcher(
        clientDomainAPI.endpoints.checkUserDomain.matchFulfilled,
        (state, action) => {
          state.userDomain = action.payload.url;
          if (action.payload.insertUrl) {
            state.userCopyInput = action.payload.insertUrl;
          }
          return state;
        }
      )
      .addMatcher(
        openAiAPI.endpoints.createGeneration.matchFulfilled,
        (state) => {
          state.aiLoader = true;
          state.globalLoading = true;
          return state;
        }
      )
      .addMatcher(
        openAiAPI.endpoints.loadGenerationProcess.matchFulfilled,
        (state, action) => {
          const taskPayload = action.payload.task;
          if (taskPayload) {
            if (
              (taskPayload.ads_cnt >= 10 && taskPayload.complete_cnt >= 10) ||
              taskPayload.ads_cnt === taskPayload.complete_cnt
            ) {
              state.aiLoader = false;
              state.globalLoading = false;
            }

            if (taskPayload.complete_dt !== null) {
              state.aiLoader = false;
              state.globalLoading = false;
            }
          }
          return state;
        }
      )
      .addMatcher(
        openAiAPI.endpoints.stopGeneration.matchFulfilled,
        (state) => {
          state.aiLoader = false;
          state.globalLoading = false;
          return state;
        }
      );
  },
});

export const {
  setStep,
  setNextStep,
  setPreviewStep,
  setClearCampaingsStep,
  setSidebarOpen,
  setRedirectAfterSync,
  setGlobalLoading,
  setAiLoader,
} = storageSlice.actions;

export default storageSlice.reducer;
