import React from 'react';
import GroupCheckbox, { TGroupItem } from './group-checkbox';
import BaseAccordion from 'src/components/base-accordion';
import { ClearCross, SearchIcon } from 'src/components/icons';

interface IAutocompleteProps {
  items: TGroupItem[];
  selectedItems: TGroupItem[];
  onHandleSelect: (items: TGroupItem[]) => void;
  firstTitle?: string;
}

const Autocomplete = ({
  items,
  selectedItems,
  onHandleSelect,
  firstTitle,
}: IAutocompleteProps) => {
  const [searchValue, setSearchValue] = React.useState('');

  const filtered = React.useMemo(() => {
    const newFilteredOptions = items.filter(
      (option: TGroupItem) =>
        option.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
    );
    return newFilteredOptions.length === 0 && searchValue === ''
      ? items
      : newFilteredOptions;
  }, [items, searchValue]);

  return (
    <div>
      <div className="sticky pb-2 px-3 pt-5 top-0 w-full bg-white z-10">
        <label className="relative flex items-center">
          <SearchIcon className="absolute left-4 pointer-events-none" />
          <input
            className="w-full h-10 bg-[#F9FAFB] flex items-center gap-2.5 self-stretch border border-[color:var(--gray-300,#D1D5DB)] pl-10 px-3 py-3 rounded-lg border-solid 
              placeholder:text-[color:var(--gray-500,#6B7280)] placeholder:text-sm placeholder:not-italic placeholder:font-normal placeholder:leading-[125%]"
            type="text"
            placeholder={searchValue || 'Search...'}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <button
            className="absolute right-4 cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              setSearchValue('');
            }}
          >
            <ClearCross />
          </button>
        </label>
      </div>
      {firstTitle === 'Industry Attributes' ? (
        <BaseAccordion
          accordionItems={filtered.map((item, index) => {
            return {
              title: item.name,
              content: (
                <GroupCheckbox
                  key={`${item.value}-${index}`}
                  items={item.child || []}
                  onHandleChecked={onHandleSelect}
                  selectedItems={selectedItems}
                />
              ),
            };
          })}
        />
      ) : (
        <GroupCheckbox
          items={filtered}
          onHandleChecked={onHandleSelect}
          selectedItems={selectedItems}
        />
      )}
    </div>
  );
};

export default Autocomplete;
