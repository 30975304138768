import { Alert, Card } from 'flowbite-react';
import {
  HiPlusCircle,
  HiCheckCircle,
  HiQuestionMarkCircle,
} from 'react-icons/hi';
import Button from 'src/components/button';
import { useImagePreview } from 'src/helpers/lazy-load-image';
import { useAppSelector } from 'src/store/hooks';

interface IIntegrationCardProp {
  image: string;
  lazyImage: string;
  disabled?: boolean;
  isLoading?: boolean;
  isLoadingRemoteGAConfig?: boolean;
  type: 'sync' | 'analytic';
  onHandleConnect: () => void;
  onShowInfo?: () => void;
  verifyAdsTrackingUrl?: () => void;
  isLoadingCheck?: boolean;
  isSyncing?: number;
}

const IntegrationCard = ({
  image,
  lazyImage,
  disabled,
  isLoading,
  isLoadingRemoteGAConfig,
  type,
  onHandleConnect,
  onShowInfo,
  isLoadingCheck,
  isSyncing,
}: IIntegrationCardProp) => {
  const { latestSync } = useAppSelector((state) => state.googleSyncReducer);
  const imageSrc = useImagePreview(lazyImage, image);

  return (
    <div className="block items-center justify-between p-4 px-10">
      <Card className="w-full max-w-[353px]">
        <div className="flex items-center flex-col gap-y-4 select-none relative">
          {type !== 'sync' && (
            <button
              className="absolute right-[-10px] top-[-10px] cursor-pointer"
              onClick={onShowInfo}
              data-testid="help_analytic_button"
            >
              <HiQuestionMarkCircle className="text-gray-400" size="20" />
            </button>
          )}
          <div className="flex justify-center max-w-[75%] w-full">
            <img className="w-full" src={imageSrc} alt="ads google logo" />
          </div>
          <div className="text-sm font-normal text-gray-500 text-center">
            {type === 'sync'
              ? 'Integrate your first party data from Google Ads to pull in lead data for personalization.'
              : 'Configure your Google Analytics integration to seamlessly track triggered personalizations'}
          </div>
          {type === 'sync' && latestSync && isSyncing !== 2 && (
            <Alert
              color="success"
              icon={HiCheckCircle}
              className="!py-2 !px-4 m-0"
            >
              Active
            </Alert>
          )}

          {type === 'sync' &&
            (!latestSync ||
              isSyncing === 2 ||
              (latestSync && isSyncing !== 3)) && (
              <Button
                type="default"
                onClick={onHandleConnect}
                disabled={disabled || isLoadingRemoteGAConfig}
                Icon={!isLoadingCheck && <HiPlusCircle className="mr-2" />}
                title="Connect"
                isLoading={isSyncing === 2 || isLoading || isLoadingCheck}
              />
            )}

          {type === 'analytic' && (
            <Button
              type="default"
              onClick={onHandleConnect}
              disabled={disabled || isLoadingRemoteGAConfig}
              title="Configure"
              isLoading={isLoadingRemoteGAConfig}
              reverse
            />
          )}
          {type === 'sync' && latestSync && isSyncing !== 2 && (
            <div className="text-xs font-normal text-gray-400 text-center">
              Pipedata AI use and transfer to any other app of information
              received from Google APIs will adhere to{' '}
              <a
                href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
                className="text-blue-700"
                target="_blank"
              >
                Google API Services User Data Policy
              </a>
              , including the Limited Use requirements.
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default IntegrationCard;
