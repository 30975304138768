import { useCallback, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
  setMarkupIframeLink,
  setMarkupList,
  setMarkupDeleteElementById,
  setMarkupDeleteElementByPath,
  setMarkupLink,
} from 'src/store/slices/markup-editor-slice';
import {
  useIframeMethods,
  getTypeElement,
} from 'src/components/iframe/helpers/iframe-events';
import Header from 'src/components/iframe/header';
import IframeModalBox from 'src/components/iframe/modal-box';
import Iframe from 'src/components/iframe/iframe';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  setSelectedElements,
  setDeleteElementById,
  setDeleteElementByPath,
  setCampaignSiteUrl,
} from 'src/store/slices/campaignSlice';
import { getSelectedElementPath } from './hooks/getSelectedElementPath';
import { getSelectedElementValue } from './hooks/getSelectedElementValue';
import { IElement } from 'src/store/services/create-campaign/types';
import {
  useCheckRedirectedUrlMutation,
  useLazyGetCampainDataQuery,
} from 'src/store/services/create-campaign/endpoints/create-campaign';
import { parseResponse } from 'src/store/services/helpers';
import Spinner from 'src/components/base-spinner';
import {
  clickableWindow,
  windowTitle,
} from 'src/components/iframe/selected-window';

const MarkupEditor = ({
  byElements,
  limitElements,
  handleSaveAndExit,
  skipTitle,
  title,
  headerSelectName,
  headerSetElementName,
  isConversionActions,
}: {
  byElements?: string[];
  limitElements?: number;
  handleSaveAndExit?: () => void;
  skipTitle?: boolean;
  title?: string;
  headerSelectName?: string;
  headerSetElementName?: string;
  isConversionActions?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { iframeUrl, link, list } = useAppSelector(
    (state) => state.markupEditorReducer
  );
  const [checkRedirectedUrl] = useCheckRedirectedUrlMutation();
  const [getCampainData] = useLazyGetCampainDataQuery();
  const [iframeError, setIframeError] = useState('');
  const [scriptLoading, setScriptLoading] = useState(true);
  const [selectedElement, setSelectedElement] = useState<any>(undefined);
  const [selectedElementModal, setSelectedElementModal] =
    useState<boolean>(false);
  const [iframeLoad, setIframeLoad] = useState(false);
  const [pointerEventsRemoved, setPointerEventsRemoved] = useState(true);
  const [hrefRemoved, setHrefRemoved] = useState(true);
  const activeMethods = useIframeMethods(
    list,
    setSelectedElementModal,
    setSelectedElement,
    byElements
  );
  const removePointerEvents = useCallback((doc: any) => {
    const elementsWithPointerEvents = doc.querySelectorAll(
      '*[style*="pointer-events"]'
    );
    elementsWithPointerEvents.forEach((element: any) => {
      element.style.pointerEvents = '';
    });
    const elements = doc.querySelectorAll('[data-framer-generated] *');
    elements.forEach((element: any) => {
      const isMyElement = element.hasAttribute('maked-button');
      if (!isMyElement) {
        element.style.pointerEvents = 'auto';
      }
    });
  }, []);

  const removeHrefEvents = useCallback((doc: any) => {
    const hrefElements = doc.querySelectorAll('a');
    hrefElements.forEach((element: any) => {
      const clone = element.cloneNode(true);
      element.parentNode.replaceChild(clone, element);
    });
  }, []);

  const getIframeData = useCallback(async () => {
    const response = await checkRedirectedUrl(link);
    const { result, errorMessage } = parseResponse(response);
    if (errorMessage) {
      setIframeError(errorMessage);
    }
    if (result && !errorMessage) {
      dispatch(setMarkupIframeLink(result.url));
    }
  }, [checkRedirectedUrl, dispatch, link]);

  const handleAddedElement = (element: any, elementName?: string) => {
    const selectedElement = element;
    const elementPath = getSelectedElementPath(selectedElement);
    const elementValue = getSelectedElementValue(selectedElement);
    const elementType = getTypeElement(selectedElement);
    const elementUrl = selectedElement.href;
    const saveElement = {
      name: elementName ?? 'actionElement',
      path: elementPath,
      default_value: elementValue,
      tag_type: elementType,
      default_url: elementUrl ?? '',
    };

    selectedElement.setAttribute(
      'selected',
      `${elementName ?? 'actionElement'}`
    );

    if (elementName) {
      const rect = selectedElement.getBoundingClientRect();
      const newText = windowTitle(elementName);
      console.log('selected rect', rect);
      const elementWindow = clickableWindow(
        newText,
        rect.top + rect.height + window[0].scrollY + 'px',
        rect.right + window[0].scrollX
      );

      selectedElement.style.position = 'relative';
      window[0].document.body.appendChild(elementWindow);
    }

    selectedElement.style.boxShadow = 'inset 0px 0px 0px 4px #FACA15';
    selectedElement.removeAttribute('pre-select');
    dispatch(setMarkupList([...list, saveElement]));
    setSelectedElement(element);
  };

  const handleDeleteSelectedElement = (element: IElement) => {
    try {
      const deletedElement: any = window[0].document.querySelector(
        `[selected="${element.name}"]`
      );
      if (deletedElement) {
        deletedElement.removeAttribute('selected');
        deletedElement.style.position = null;
        deletedElement.style.boxShadow = null;
      }
      const elementDescription =
        window[0].document.querySelector('[maked-button]');
      if (elementDescription) {
        elementDescription.remove();
      }
    } catch (e) {
      // probably was not found selector
    }

    if (element.id) {
      dispatch(setDeleteElementById(element.id));
      dispatch(setMarkupDeleteElementById(element.id));
    } else {
      if (element.path) {
        dispatch(setDeleteElementByPath(element.path));
        dispatch(setMarkupDeleteElementByPath(element.path));
      } else {
        const elementPath = getSelectedElementPath(selectedElement);
        dispatch(setDeleteElementByPath(elementPath));
        dispatch(setMarkupDeleteElementByPath(elementPath));
      }
    }

    setSelectedElement(undefined);
  };

  const handleSaveAndExitEditor = (elementsArr: IElement[]) => {
    const stateUrl = location.state;
    const markupURL = searchParams.get('url') || link;
    dispatch(setSelectedElements(elementsArr));
    if (handleSaveAndExit) {
      handleSaveAndExit();
      return;
    }
    dispatch(setCampaignSiteUrl(markupURL));
    navigate(stateUrl);
  };

  useEffect(() => {
    const campaignID = searchParams.get('campaignID');
    const markupURL = searchParams.get('url');
    if (markupURL && !isConversionActions) {
      dispatch(setMarkupLink(markupURL));
      dispatch(setMarkupIframeLink(markupURL));
    }

    if (campaignID && link === '' && !isConversionActions) {
      getCampainData(campaignID);
      setScriptLoading(true);
      setIframeError('');
    }
  }, [dispatch, getCampainData, isConversionActions, link, searchParams]);

  useEffect(() => {
    try {
      const clearUrl = encodeURIComponent(
        link && link.endsWith('/') ? link.slice(0, -1) : link
      );
      const clearFrameUrl =
        iframeUrl && iframeUrl.endsWith('/')
          ? iframeUrl.slice(0, -1)
          : iframeUrl;
      if (
        iframeLoad &&
        ((clearFrameUrl.includes(clearUrl) && clearUrl !== '') ||
          isConversionActions)
      ) {
        if (typeof window !== 'undefined' && window[0] && window[0].document) {
          if (pointerEventsRemoved) {
            removePointerEvents(window[0].document);
            setPointerEventsRemoved(false);
          }
          if (hrefRemoved) {
            removeHrefEvents(window[0].document);
            setHrefRemoved(false);
          }
          // ### Markup editor
          window[0].onload = () => {
            activeMethods.setSelectedElements(window[0].document);
            window[0].document.onclick = (e) => activeMethods.handleClick(e);

            window[0].document.onmouseover = activeMethods.handleMouseOver;
            window[0].document.onmouseout = activeMethods.handleMouseOut;
          };
          window[0].document.onkeydown = (event) => {
            if (event.ctrlKey) {
              sessionStorage.setItem('ctrlDown', 'true');
            }
          };
          window[0].document.onkeyup = (event) => {
            if (event.key === 'Control') {
              sessionStorage.setItem('ctrlDown', 'false');
            }
          };
          setScriptLoading(false);
          // ###
          // ## for conversion actions
          if (limitElements === list.length) {
            activeMethods.setSelectedElements(window[0].document);
            window[0].document.onclick = (e) => {
              e.preventDefault();
              e.stopPropagation();
            };
            window[0].document.onmouseover = null;
            window[0].document.onmouseout = null;
            setScriptLoading(false);
          } else {
            if (!selectedElement) {
              activeMethods.setSelectedElements(window[0].document);
            }
            window[0].document.onclick = (e) => activeMethods.handleClick(e);
            window[0].document.onmouseover = activeMethods.handleMouseOver;
            window[0].document.onmouseout = activeMethods.handleMouseOut;
          }
          // ##
          if (window[0].document.onmouseover) {
            const handleDOMContentLoaded = () => {
              setScriptLoading(false);
              window[0].removeEventListener('load', handleDOMContentLoaded);
            };
            window[0].addEventListener('load', handleDOMContentLoaded);
          }
        }
      }
    } catch (e) {
      console.log('markup load error: ', e);
    }
  }, [
    list,
    selectedElement,
    activeMethods,
    limitElements,
    iframeLoad,
    removePointerEvents,
    pointerEventsRemoved,
    iframeUrl,
    link,
    isConversionActions,
    hrefRemoved,
    removeHrefEvents,
  ]);

  useEffect(() => {
    const clearUrl = link && link.endsWith('/') ? link.slice(0, -1) : link;
    const clearFrameUrl =
      iframeUrl && iframeUrl.endsWith('/') ? iframeUrl.slice(0, -1) : iframeUrl;
    if (
      (clearUrl !== '' && clearFrameUrl.includes(clearUrl)) ||
      isConversionActions
    ) {
      getIframeData();
    }
  }, [getIframeData, iframeUrl, isConversionActions, link]);

  return (
    <div className="relative">
      <Header
        title={
          title
            ? title
            : 'Select the elements (text, images, background images, buttons) that will change on your website'
        }
        selectName={headerSelectName}
        setElementName={headerSetElementName}
        selectedItems={list.filter((e: any) => !e.deletion)}
        haveSelectItem={
          !!selectedElement && !selectedElement?.hasAttribute('selected')
        }
        onDeleteItemClick={handleDeleteSelectedElement}
        onSaveClick={(arr) => handleSaveAndExitEditor(arr)}
        onOpenSelectedModal={() => {
          if (isConversionActions) {
            handleAddedElement(selectedElement);
            return;
          }
          setSelectedElementModal(!selectedElementModal);
        }}
        selectedType={
          !isConversionActions && selectedElement
            ? getTypeElement(selectedElement)
            : undefined
        }
        isConversionActions={isConversionActions}
      />
      {scriptLoading && !iframeError && (
        <div className="absolute w-full h-[calc(100vh_-_56.4px)] flex justify-center items-center bg-[#00000080] z-10">
          <Spinner size="h-10 w-10" />
        </div>
      )}
      {iframeError ? (
        <div className="absolute w-full h-[calc(100vh_-_56.4px)] flex justify-center items-center bg-[#00000080] z-10">
          <p>{iframeError}</p>
        </div>
      ) : (
        <Iframe
          iframeUrl={iframeUrl}
          onLoadIframe={() => setIframeLoad(true)}
        />
      )}
      <IframeModalBox
        isOpen={selectedElementModal}
        onClose={() => {
          setSelectedElementModal(false);
        }}
        element={selectedElement}
        onSave={handleAddedElement}
        skipTitle={skipTitle}
      />
    </div>
  );
};

export default MarkupEditor;
