import React from 'react';
import PaginationArrows from '../pagination-arros';
import BaseSelect from '../selects/base-select';

export interface ITablePaginator {
  currentPage: number;
  totalPages: number;
  step: number;
  onPageChange: (e: any) => void;
  onChangeStep: (e: any) => void;
  disabledNext?: boolean;
  tooltipTitle?: string;
}

const TablePagination = ({
  currentPage,
  totalPages,
  step,
  onPageChange,
  onChangeStep,
  disabledNext,
  tooltipTitle,
}: ITablePaginator) => {
  const totalValue = +currentPage - 1 + +step;
  const startElement = currentPage;
  const endElement = totalValue > totalPages ? totalPages : totalValue;
  return (
    <div className="flex items-center justify-end p-4 shadow">
      <p className="text-sm font-normal text-[#6B7280]">Rows per page</p>
      <BaseSelect
        value={`${step}`}
        baseClassName="w-[64px] mx-2.5"
        popupClassName="min-w-[64px] [&]:pt-2"
        inputClassName="pl-3"
        ItemList={(handleClose) => (
          <div className="flex flex-col">
            {STEPS.map((item) => (
              <button
                key={item.value}
                className="p-1 hover:bg-blue-100"
                onClick={(e) => {
                  handleClose(e), onChangeStep(item.value);
                }}
              >
                <p className="leading-tight text-xs font-medium text-left text-gray-900">
                  {item.label}
                </p>
              </button>
            ))}
          </div>
        )}
      />
      <p className="mr-2.5 text-xs font-medium text-[#111928]">
        {startElement}-{endElement}
        <span className="text-[#6B7280]"> of </span>
        {totalPages}
      </p>
      <PaginationArrows
        currentPage={currentPage}
        totalPages={totalPages}
        step={step}
        onChangePage={onPageChange}
        disabledNext={disabledNext}
        tooltipTitle={tooltipTitle}
      />
    </div>
  );
};

export default TablePagination;

const STEPS = [
  {
    label: 5,
    value: '5',
  },
  {
    label: 10,
    value: '10',
  },
  {
    label: 25,
    value: '25',
  },
  {
    label: 50,
    value: '50',
  },
];
