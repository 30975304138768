import { useState } from 'react';
import {
  IKeywordObject,
  KeywordTypeLabels,
  KeywordType,
} from 'src/store/services/create-campaign/types';

interface IKeywords {
  maxToShow: number;
  keywordTitle: IKeywordObject[];
}

const Keywords = ({ maxToShow, keywordTitle }: IKeywords) => {
  const [showKeywords, setShowKeywords] = useState(false);

  const keywordType = (matchType: string) => {
    const typeNumber = Number(matchType);

    if (typeNumber in KeywordTypeLabels) {
      return KeywordTypeLabels[typeNumber as KeywordType];
    }

    return KeywordTypeLabels[0];
  };

  return (
    <div className="p-1 snap-y snap-mandatory">
      {keywordTitle.length > 0
        ? keywordTitle
            .slice(0, showKeywords ? keywordTitle.length : maxToShow)
            .map((item, index) => (
              <p
                key={`${item.keyword}-${index}`}
                className="mb-2.5 text-[11px] font-normal leading-none text-gray-800 snap-always snap-center"
              >
                {item.clicks !== null
                  ? `${item.clicks} / ${keywordType(item.match_type)} / ${
                      item.keyword
                    }`
                  : `0 / ${keywordType(item.match_type)} / ${item.keyword}`}
              </p>
            ))
        : ''}
      {keywordTitle.length > 0
        ? maxToShow <= keywordTitle.length - 1 && (
            <button
              className="-mt-1.5 px-3 py-1 bg-blue-100 rounded-lg text-[11px] font-normal leading-normal text-blue-700"
              onClick={() => setShowKeywords(!showKeywords)}
            >
              {showKeywords ? 'Hide extra' : 'Show more'} keywords
              {showKeywords
                ? ''
                : `(${keywordTitle ? keywordTitle.length - maxToShow : ''})`}
            </button>
          )
        : null}
    </div>
  );
};

export default Keywords;
