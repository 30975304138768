interface ITitle {
  title: string;
  textPosition?: 'left' | 'center' | 'right';
  className?: string;
}

const TableHeadTitle = ({
  title,
  textPosition = 'left',
  className,
}: ITitle) => {
  return (
    <p
      className={`p-4 pl-2.5 bg-gray-100 text-xs font-semibold text-gray-700 ${
        textPosition === 'center'
          ? 'text-center'
          : textPosition === 'right'
          ? 'text-right'
          : 'text-left'
      } uppercase ${className}`}
    >
      {title}
    </p>
  );
};

export default TableHeadTitle;
