import BaseSelect from 'src/components/selects/base-select';

export interface ISelectItems {
  label: string;
  value: string;
  title?: string;
  description?: string;
}

interface ICheckSelect {
  title?: string;
  type: 'radio' | 'checkbox';
  activeValues: ISelectItems[];
  selectElements: ISelectItems[];
  onSelect: (value: ISelectItems) => void;
  className?: string;
  placeholder?: string;
  errorMessage?: string;
  popupClassName?: string;
}

const CheckboxSelect = ({
  title,
  type,
  activeValues,
  selectElements,
  onSelect,
  className,
  placeholder,
  errorMessage,
  popupClassName,
}: ICheckSelect) => {
  return (
    <div className={className}>
      <BaseSelect
        title={title}
        popupClassName="[&]:p-0 [&]:max-h-max [&]:max-w-none"
        errorMessage={errorMessage}
        value={activeValues.map((item) => item.label).join(', ') || placeholder}
        ItemList={() =>
          type === 'radio' ? (
            <ul
              className={`p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200 ${popupClassName}`}
              aria-labelledby="dropdownRadioHelperButton"
              role="radiogroup"
            >
              {selectElements.map((item, index) => (
                <li
                  key={`${item.value}-${index}`}
                  role="radio"
                  aria-checked={Boolean(
                    activeValues.find((val) => val.value === item.value)
                  )}
                >
                  <label
                    className="w-full flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer"
                    onClick={() => onSelect(item)}
                  >
                    <input
                      id={`helper-radio-${index}`}
                      name="helper-radio"
                      type="radio"
                      onChange={() => onSelect(item)}
                      checked={Boolean(
                        activeValues.find((val) => val.value === item.value)
                      )}
                      className="w-4 h-4 text-sm text-blue-600 bg-gray-100 border-gray-300 focus:shadow-none focus:ring-offset-0 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <div className="ml-2 text-sm">
                      <div className="w-full font-medium text-gray-900 dark:text-gray-300">
                        <p className="text-sm font-bold text-[#111928] text-left">
                          {item.label}
                        </p>
                        {item.title && (
                          <p
                            id="helper-radio-text-4"
                            className="text-xs font-medium text-[#111928] text-left"
                          >
                            {item.title}
                          </p>
                        )}
                        {item.description && (
                          <p
                            id="helper-radio-text-4"
                            className="text-xs font-medium text-[#6B7280] dark:text-gray-300 text-left"
                          >
                            {item.description}
                          </p>
                        )}
                      </div>
                    </div>
                  </label>
                </li>
              ))}
            </ul>
          ) : (
            <ul
              className={`p-3 space-y-3 text-sm text-gray-700 dark:text-gray-200 ${popupClassName}`}
              aria-labelledby="dropdownCheckboxButton"
              role="group"
            >
              {selectElements.map((item, index) => (
                <li
                  key={`${item.value}-${index}`}
                  role="checkbox"
                  aria-checked={Boolean(
                    activeValues.find((val) => val.value === item.value)
                  )}
                >
                  <label
                    className="w-full flex items-center"
                    onClick={() => onSelect(item)}
                  >
                    <input
                      id={`checkbox-item-${index}`}
                      type="checkbox"
                      onChange={() => onSelect(item)}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      checked={Boolean(
                        activeValues.find((val) => val.value === item.value)
                      )}
                    />
                    <div className="w-full ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                      {item.label}
                    </div>
                  </label>
                </li>
              ))}
            </ul>
          )
        }
      />
    </div>
  );
};

export default CheckboxSelect;
