interface IEmptyProps {
  title: string;
}

const EmptyList = ({ title }: IEmptyProps) => {
  return (
    <div className="flex w-full h-full">
      <div className="flex flex-col items-center justify-center m-auto">
        <img src="/assets/images/no-campaign.png" alt="no campaign image" />
        <p
          className="mt-10 w-3/4 text-sm font-medium text-center text-[#6B7280]"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </div>
    </div>
  );
};

export default EmptyList;
