import { parseResponse } from 'src/store/services/helpers';
import { useGetAllSegmentsAttributesQuery } from 'src/store/services/segments/endpoints/segments';
import { TItem } from '../blocks/select-button';

const useAttributesSorting = () => {
  const { isLoading, data, error } = useGetAllSegmentsAttributesQuery();
  const { result, errorMessage } = parseResponse({ data, error });
  const dataItem: TItem[] = [];

  if (result) {
    result.data.map((item: any) => {
      dataItem.push({
        label: item.name,
        value: item.id,
      });
    });
  }

  return {
    isLoading,
    attributes: dataItem,
    error: errorMessage,
  };
};

export default useAttributesSorting;
