import React from 'react';
import { Outlet } from 'react-router-dom';
const MemoOutlet = React.memo(Outlet);

const Main: React.FC = () => {
  return (
    <main
      className="flex-1 bg-gray-50 overflow-y-auto"
      style={{ height: 'calc(100vh - 4rem)' }}
    >
      <MemoOutlet />
    </main>
  );
};

export default Main;
