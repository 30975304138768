import { HiClock, HiRefresh } from 'react-icons/hi';
import { HiShieldCheck } from 'react-icons/hi2';

export type TStatus = 'waitStatSig' | 'statSig' | 'iterate' | 'wait';

interface IRecProp {
  type: TStatus;
}

const WaitForStat = () => {
  return (
    <div className="flex items-center justify-center w-max py-1 px-2 bg-green-50 rounded-md">
      <HiClock color="rgb(22 163 74)" />
      <p className="ml-1 text-xs font-medium text-gray-700 whitespace-nowrap">
        Wait Stat Sig
      </p>
    </div>
  );
};

const Stat = () => {
  return (
    <div className="flex items-center justify-center w-max py-1 px-2 bg-green-50 rounded-md">
      <HiShieldCheck color="rgb(22 163 74)" />
      <p className="ml-1 text-xs font-medium text-gray-700 whitespace-nowrap">
        Stat sig!
      </p>
    </div>
  );
};

const Iterate = () => {
  return (
    <div className="flex items-center justify-center w-max py-1 px-2 bg-red-50 rounded-md">
      <HiRefresh color="rgb(220 38 38)" />
      <p className="ml-1 text-xs font-medium text-gray-700 whitespace-nowrap">
        Iterate
      </p>
    </div>
  );
};

const Wait = () => {
  return (
    <div className="flex items-center justify-center w-max py-1 px-2 bg-gray-100 rounded-md">
      <HiClock color="rgb(75 85 99)" />
      <p className="ml-1 text-xs font-medium text-gray-700 whitespace-nowrap">
        Wait More traff
      </p>
    </div>
  );
};

const REC_ITEM = {
  waitStatSig: <WaitForStat key="WaitForStat" />,
  statSig: <Stat key="Stat" />,
  iterate: <Iterate key="Iterate" />,
  wait: <Wait key="Wait" />,
};

const RecommendationStatus = ({ type }: IRecProp) => {
  return REC_ITEM[type];
};

export default RecommendationStatus;
