import { useEffect, useRef, useState } from 'react';
import { HiOutlineX } from 'react-icons/hi';

import { EAdStatus } from 'src/store/services/create-campaign/types';

interface IDetailAdCard {
  id: number;
  title: string;
  url: string;
  adGroup: string;
  description: string;
  status: EAdStatus;
}

const DetailAdCard = ({
  id,
  title,
  url,
  adGroup,
  description,
  status,
}: IDetailAdCard) => {
  const menu = useRef<HTMLButtonElement | null>(null);
  const dropdown = useRef<HTMLDivElement | null>(null);
  const [adInfoOpen, setAdInfoOpen] = useState(false);
  const [arrowPosition, setArrowPosition] = useState({
    y: 0,
    x: 0,
    isTop: false,
  });

  const findElementTop = () => {
    if (menu.current && dropdown.current) {
      const windowHeight = window.innerHeight;
      const menuBounds = menu.current.getBoundingClientRect();
      const dropdownElement = dropdown.current;

      const { height: popupHeight } = dropdownElement.getBoundingClientRect();
      const { top, left, width: menuWidth, height: menuHeight } = menuBounds;

      const popupPosition = left - menuWidth;
      const arrowXPosition = left + menuWidth / 2;

      const freeSpace = windowHeight - top + menuHeight;
      const topPosition = top + 320 >= windowHeight;
      const maxHeight = freeSpace > 320 ? 320 : freeSpace;

      const positionY = Math.floor(
        !topPosition
          ? top + menuHeight + 8
          : top - (popupHeight > 320 ? 320 : popupHeight) - 8
      );

      dropdownElement.style.cssText = ` 
        top: ${positionY}px; 
        left: ${popupPosition}px; 
        max-height: ${
          topPosition ? (popupHeight > 320 ? 320 : popupHeight) : maxHeight
        }px;
      `;

      setArrowPosition({
        y: Math.floor(
          topPosition ? positionY - 8 + popupHeight : positionY - 6
        ),
        x: Math.floor(arrowXPosition),
        isTop: topPosition,
      });
    }
  };

  const handleMenuClicked = () => {
    setAdInfoOpen(!adInfoOpen);
    findElementTop();
  };

  useEffect(() => {
    const handleScroll = () => {
      if (adInfoOpen) {
        findElementTop();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [adInfoOpen]);

  return (
    <div className="relative">
      <button
        ref={menu}
        className="py-1 px-2 border border-blue-300 bg-blue-100 rounded-md"
        onClick={handleMenuClicked}
      >
        <p className="font-medium text-xs text-blue-600">$Ad</p>
      </button>
      <div
        ref={dropdown}
        className={`${
          adInfoOpen
            ? 'opacity-100 z-[61] pointer-events-auto'
            : 'opacity-0 -z-[1] pointer-events-none'
        } fixed max-w-[520px] border border-gray-200 rounded-md overflow-y-auto text-left`}
      >
        <div
          className={`fixed w-4 h-4 transform -translate-x-1/2 border border-gray-200 ${
            arrowPosition.isTop
              ? `rotate-45 bg-gradient-to-tl from-white from-50% to-transparent via-50% z-[1] border-t-0 border-l-0`
              : `rotate-45 bg-blue-50 -z-[1]`
          }`}
          style={{
            left: `${arrowPosition.x}px`,
            top: `${arrowPosition.y}px`,
          }}
        />
        <div className="flex justify-between items-center py-2 px-3 bg-blue-50 border-b border-gray-200">
          <div className="flex">
            {AdStatus(status)}
            <p className="ml-2.5 font-medium text-xs text-gray-500">
              Ad id: <span className="text-purple-800">{id}</span>
            </p>
          </div>
          <button onClick={() => setAdInfoOpen(false)}>
            <HiOutlineX color="#6B7280" />
          </button>
        </div>
        <div className="py-2 px-3 bg-white">
          <p className="text-[11px] font-medium text-purple-800">{title}</p>
          <a
            className="my-1.5 inline-block text-[10px] font-normal text-green-600 leading-tight"
            href={url}
          >
            {url}
          </a>
          <p className="my-[5px] text-[10px] font-normal text-gray-800">
            {description}
          </p>
          <p className="text-[10px] font-medium text-purple-800">
            ad group: {adGroup}
          </p>
        </div>
      </div>
    </div>
  );
};

export const AdStatus = (adStatus: EAdStatus) => {
  switch (adStatus) {
    case EAdStatus.ENABLED:
      return <div className="mx-auto w-3 h-3 rounded-full bg-green-400" />;
    case EAdStatus.PAUSED:
      return <div className="mx-auto w-3 h-3 rounded-full bg-gray-400" />;
    case EAdStatus.REMOVED:
      return <div className="mx-auto w-3 h-3 rounded-full bg-red-400" />;
    default:
      return <div className="mx-auto w-3 h-3 rounded-full bg-red-400" />;
  }
};

export default DetailAdCard;
