export interface ConversionActionDTO {
  id: number;
  name: string;
  action_type: ConversionActionType;
  tag_path?: string;
  page_url: string;
  is_exactly: boolean;
}

export enum ConversionActionType {
  Page_Visit = 1,
  Button_or_Link,
  Form,
}

export interface ConversionActionItem {
  action_type: ConversionActionType;
  count_conversions: number;
  created_at: string;
  id: string;
  tag_path: string;
  page_url: string;
  is_exactly: boolean;
  last_conversion: string;
  name: string;
}
