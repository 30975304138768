import { HiRefresh } from 'react-icons/hi';

interface IAiGenerationProp {
  total: number;
  current: number;
}

const AiGeneration = ({ total, current }: IAiGenerationProp) => {
  const progress = (current / total) * 100;

  return (
    <div className="relative w-full py-1 px-3 border border-gray-300 rounded-md overflow-hidden">
      <div
        className="absolute top-0 left-0 h-full bg-blue-100 transition-width duration-500 ease-in-out"
        style={{ width: `${progress}%` }}
      />
      <div className="relative flex items-center">
        <HiRefresh color="rgb(37 99 235)" className="animate-spin-fast" />
        <p className="ml-1 text-xs font-semibold text-gray-700">
          Generating personalized content {current}/{total} ...
        </p>
      </div>
    </div>
  );
};

export default AiGeneration;
