interface ITTitle {
  title: string;
  className?: string;
}

const Title = ({ title, className }: ITTitle) => {
  return (
    <p
      className={`p-4 text-xs text-left font-semibold text-gray-500 ${className}`}
    >
      {title}
    </p>
  );
};

export default Title;
