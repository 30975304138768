import { Breadcrumb } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'src/store/hooks';
import { setClearCampaignData } from 'src/store/slices/campaignSlice';
import { setClearCampaingsStep } from 'src/store/slices/storageSlice';
import { resetEditorState } from 'src/store/slices/markup-editor-slice';

type CrumbsItem = {
  title: string;
  link: string;
};

interface IBreadCrumbs {
  items: CrumbsItem[];
}

const BreadCrumbs = ({ items }: IBreadCrumbs) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handlePageNavigate = (href: string, e: any, index: number) => {
    e.preventDefault();
    if (index !== items.length - 1) {
      dispatch(setClearCampaignData());
      dispatch(setClearCampaingsStep());
      dispatch(resetEditorState());
      navigate(href);
    }
  };

  return (
    <Breadcrumb>
      {items.map((crumb, index) => (
        <Breadcrumb.Item
          key={`${crumb}-${index}`}
          className="[&>svg]:w-4 [&>svg]:h-4"
        >
          <button
            className="w-full"
            type="button"
            onClick={(e) => handlePageNavigate(crumb.link, e, index)}
          >
            {crumb.title}
          </button>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default BreadCrumbs;
