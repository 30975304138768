import React, { useEffect, useState } from 'react';

localStorage.removeItem('theme');

const Theme = () => {
  const defaultTheme = 'light';
  const [theme, setTheme] = useState(
    localStorage.getItem('theme') || defaultTheme
  );

  useEffect(() => {
    const root = document.documentElement;
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');

    if (theme === 'dark') {
      root.classList.add('dark');
      if (metaThemeColor) {
        metaThemeColor.setAttribute('content', '#000000');
      }
    } else {
      root.classList.remove('dark');
      if (metaThemeColor) {
        metaThemeColor.setAttribute('content', '#FFFFFF');
      }
    }
    localStorage.setItem('theme', theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  return <button className="hidden" onClick={toggleTheme}></button>;
};

export default Theme;
