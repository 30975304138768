import { useCallback, useEffect, useState } from 'react';
import { HiExclamation } from 'react-icons/hi';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
  useLazyCheckIsSyncedGoogleQuery,
  useLazySyncGoogleAccountQuery,
} from '../store/services/google/endpoints/google';
import { loadSync } from '../store/slices/googleSyncSlice';

import { parseResponse } from 'src/store/services/helpers';
import { SyncStatus, SyncStatusKeys } from 'src/store/services/google/types';
import { customToastMessage } from 'src/components/toast';

export default function SyncModal() {
  const dispatch = useAppDispatch();
  const { isSyncing } = useAppSelector((state) => state.googleSyncReducer);
  const [attempts, setAttempts] = useState(0);

  const [checkIsSyncedGoogle] = useLazyCheckIsSyncedGoogleQuery();
  const [syncGoogleAccount] = useLazySyncGoogleAccountQuery();

  const checkSyncStatus = useCallback(async () => {
    const { data, error } = await checkIsSyncedGoogle();
    const { result } = parseResponse({ data, error });
    if (result) {
      const syncStatus = SyncStatus[result.status_code as SyncStatusKeys];

      if (syncStatus === 'Completed' || syncStatus === 'CompletedWithError') {
        if (syncStatus === 'Completed') {
          customToastMessage(
            'Synchronization successfully completed.',
            <HiExclamation className="h-5 w-5" />,
            'success'
          );
        }

        if (syncStatus === 'CompletedWithError') {
          customToastMessage(
            'Synchronization ended with an error.',
            <HiExclamation className="h-5 w-5" />,
            'error'
          );
        }

        return syncStatus;
      } else {
        return syncStatus;
      }
    }

    return 'Unknown';
  }, [checkIsSyncedGoogle]);

  useEffect(() => {
    loadSync();
    let intervalID: NodeJS.Timeout;

    const syncInterval = async () => {
      setAttempts((prevAttempts) => prevAttempts + 1);

      if (attempts > 10) {
        await syncGoogleAccount();
        setAttempts(0);
      }

      const isSynced = await checkSyncStatus();
      if (
        isSynced === SyncStatus[3] ||
        isSynced === SyncStatus[4] ||
        isSynced === SyncStatus[10]
      ) {
        clearInterval(intervalID);
      }
    };

    const syncStatus = SyncStatus[isSyncing];

    if (
      syncStatus !== SyncStatus[3] &&
      syncStatus !== SyncStatus[4] &&
      syncStatus !== SyncStatus[10]
    ) {
      intervalID = setInterval(syncInterval, 5000);
    }

    return () => {
      clearInterval(intervalID);
    };
  }, [isSyncing, attempts, checkSyncStatus, syncGoogleAccount, dispatch]);

  return (
    <div className="absolute h-[1px] w-[1px] inset-x-0 inset-y-0 opacity-0" />
  );
}
