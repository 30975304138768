import React, { ReactNode, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Header from './modal-header';
import Body from './modal-body';
import Footer from './modal-footer';

export interface IBaseModal {
  children: ReactNode;
  className?: string;
}

interface ModalBoxProps extends IBaseModal {
  isOpen: boolean;
  onClose: () => void;
  windowClassName?: string;
}

const ModalBox = ({
  children,
  isOpen,
  onClose,
  className = '',
  windowClassName = '',
}: ModalBoxProps) => {
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEsc);
    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, [onClose]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div
      className={`fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 z-50 ${className}`}
    >
      <div
        className={`bg-white rounded-lg shadow-lg w-full ${windowClassName}`}
      >
        {children}
      </div>
    </div>,
    document.body
  );
};

ModalBox.Header = React.memo(Header);
ModalBox.Body = React.memo(Body);
ModalBox.Footer = React.memo(Footer);

export default ModalBox;
