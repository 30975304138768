import {
  BaseQueryApi,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  fetchBaseQuery,
} from '@reduxjs/toolkit/dist/query/react';
import { SerializedError } from '@reduxjs/toolkit';
import { RootState } from '..';

const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

export const bq = fetchBaseQuery({
  baseUrl: BACKEND_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).authReducer.access_token;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
}) as BaseQueryFn<
  string | FetchArgs,
  any,
  FetchBaseQueryError,
  any,
  FetchBaseQueryMeta
>;

export const baseQuery = async (
  ...args: [args: any, api: BaseQueryApi, extraOptions: any]
) => {
  const result = await bq(...args);

  if ('error' in result && result.error) {
    return { error: result.error };
  }

  return { data: result.data };
};

export const refreshQuery = fetchBaseQuery({
  baseUrl: BACKEND_BASE_URL,
  credentials: 'include',
});

export const parseError = (
  error: FetchBaseQueryError | SerializedError | undefined
) => {
  const newError: any = {};
  if (!error) return newError;
  if (!('data' in error)) return newError;
  if (error.status === 422) {
    return error as UnprocessableEntityError;
  } else if (error.status === 400) {
    return error as BadRequestError;
  }
  return error;
};

export const parseResponse = <T>(
  response:
    | {
        data: T;
      }
    | {
        error: FetchBaseQueryError | SerializedError;
      }
) => {
  const error = 'error' in response && parseError(response.error)?.data;
  const result = 'data' in response && !error && response.data;
  let errorMessage = undefined;

  if (!result) {
    errorMessage =
      error && error.message
        ? error.statusCode === 422
          ? error.message.map((e: { error: string }) => e.error).join(', ')
          : error.message
        : 'Server connection error';
  }

  return { result, errorMessage, error };
};

export class BadRequestError {
  error?: string;
  message?: string;
  statusCode?: number;
}

export class UnprocessableEntityError {
  error?: string;
  message?: UnprocessableEntityField[];
  statusCode?: number;
}

export class UnprocessableEntityField {
  error?: string;
  field?: string;
}
