import { FC, useEffect, useRef, useState } from 'react';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {
  DateRangePicker,
  RangeKeyDict,
  Range,
  createStaticRanges,
  defaultStaticRanges,
} from 'react-date-range';

interface CalendarEvents {
  onChange: (selection: any) => void;
  onClose: (e: any) => void;
  startDate?: Date;
  endDate?: Date;
  lastDate: Date;
  disabled?: boolean;
}

const CalendarComponent: FC<CalendarEvents> = function ({
  onChange,
  onClose,
  startDate,
  endDate,
  lastDate,
  disabled,
}) {
  const [dates, setDates] = useState<Range[]>([
    {
      startDate: startDate,
      endDate: endDate || new Date(),
      key: 'selection',
    },
  ]);

  const containerRef = useRef(null);

  const handleOnChange = (range: RangeKeyDict) => {
    if (!disabled) {
      const { selection } = range;
      onChange(selection);
      setDates([selection]);
    }
  };

  useEffect(() => {
    const onClickOutComponent = (e: any) => {
      if (!containerRef || !containerRef.current || disabled) {
        return;
      }

      let didClickInside = false;

      let parentElement = e.target;
      while (parentElement) {
        if (parentElement === containerRef.current) {
          didClickInside = true;
          break;
        }

        parentElement = parentElement.parentElement;
      }

      if (didClickInside) {
        return;
      }
      onClose(dates[0]);
    };

    document.body.addEventListener('click', onClickOutComponent, true);

    return () => {
      document.body.removeEventListener('click', onClickOutComponent, true);
    };
  }, [dates, onClose, disabled]);

  return (
    <div className="absolute top-[44px] right-0 z-[51]" ref={containerRef}>
      <DateRangePicker
        className="border border-gray-300 rounded-lg overflow-hidden shadow-md"
        onChange={handleOnChange}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={dates}
        direction="horizontal"
        inputRanges={[]}
        staticRanges={customStaticRanges(lastDate)}
      />
    </div>
  );
};

const customStaticRanges = (startDate: Date) =>
  createStaticRanges([
    ...defaultStaticRanges,
    {
      label: 'Last 30 days',
      range: () => ({
        startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
        endDate: new Date(),
      }),
      isSelected(range) {
        const customRange = this.range();
        return (
          range.startDate?.getTime() === customRange.startDate?.getTime() &&
          range.endDate?.getTime() === customRange.endDate?.getTime()
        );
      },
    },
    {
      label: 'All time',
      range: () => ({
        startDate: startDate,
        endDate: new Date(),
      }),
      isSelected(range) {
        const customRange = this.range();
        return (
          range.startDate?.getTime() === customRange.startDate?.getTime() &&
          range.endDate?.getTime() === customRange.endDate?.getTime()
        );
      },
    },
  ]);

export default CalendarComponent;
