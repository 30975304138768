import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DomainUrlsResponse } from '../services/client-domains/types';
import { clientDomainAPI } from '../services/client-domains/endpoints/client-domains';
import { authAPI } from '../services/auth/endpoints/auth';

interface ActionType {
  domains: DomainUrlsResponse[];
}

const initialState: ActionType = {
  domains: [],
};

const clientDomainSlice = createSlice({
  name: 'clientDomainSlice',
  initialState,
  reducers: {
    setDomains: (state, action) => {
      state.domains = action.payload;
      return state;
    },
    changeDomainUrl: (
      state,
      action: PayloadAction<{ id: string; url: string }>
    ) => {
      const payload = action.payload;
      state.domains = state.domains.map((item) => {
        if (item.id === payload.id) {
          return {
            ...item,
            url: payload.url,
            urlChange: item.newDomain ? false : true,
          };
        }
        return item;
      });
      return state;
    },
    changeDomain: (state, action: PayloadAction<DomainUrlsResponse>) => {
      const payload = action.payload;
      state.domains = state.domains.map((item) => {
        if (item.id === payload.id || item.url === payload.url) {
          return {
            id: payload.id,
            active: payload.active,
            check_date: payload.check_date,
            client_domain_id: payload.client_domain_id,
            installed_script_src: payload.installed_script_src,
            url: payload.url,
          };
        }
        return item;
      });
      return state;
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(authAPI.endpoints.logoutQuery.matchFulfilled, (state) => {
        state = initialState;
        return state;
      })
      .addMatcher(authAPI.endpoints.login.matchFulfilled, (state) => {
        state = initialState;
        return state;
      })
      .addMatcher(
        clientDomainAPI.endpoints.getAllUserDomains.matchFulfilled,
        (state, action) => {
          state.domains = action.payload.data;
          return state;
        }
      )
      .addMatcher(
        clientDomainAPI.endpoints.addDomain.matchFulfilled,
        (state, action) => {
          const payload = action.payload.data;
          state.domains = state.domains.map((item) => {
            if (item.url === payload.url || item.id === payload.id) {
              return {
                id: payload.id,
                active: payload.active,
                check_date: payload.check_date,
                client_domain_id: payload.client_domain_id,
                installed_script_src: payload.installed_script_src,
                url: payload.url,
                newDomain: undefined,
                urlChange: undefined,
              };
            }
            return item;
          });
          return state;
        }
      )
      .addMatcher(
        clientDomainAPI.endpoints.postChangeDomainUrl.matchFulfilled,
        (state, action) => {
          const payload = action.payload.data;
          state.domains = state.domains.map((item) => {
            if (item.id === payload.id || item.url === payload.url) {
              return {
                ...item,
                url: payload.url,
                urlChange: undefined,
              };
            }
            return item;
          });
          return state;
        }
      )
      .addMatcher(
        clientDomainAPI.endpoints.checkScriptStatus.matchFulfilled,
        (state, action) => {
          const payload = action.payload.data;
          state.domains = state.domains.map((item) => {
            if (item.id === payload.id) {
              return {
                ...payload,
              };
            }
            return item;
          });

          return state;
        }
      )
      .addMatcher(
        clientDomainAPI.endpoints.checkScriptStatus.matchRejected,
        (state, action) => {
          const id = action.meta.arg.originalArgs.id;
          state.domains = state.domains.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                installed_script_src: '',
              };
            }
            return item;
          });

          return state;
        }
      );
  },
});

export const { setDomains, changeDomain, changeDomainUrl } =
  clientDomainSlice.actions;

export default clientDomainSlice.reducer;
