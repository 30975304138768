import React, { useEffect, useMemo, useRef, useState } from 'react';

import { parseURL } from 'src/features/markup-editor/hooks/getSelectedElementValue';

import CropImageModal from './crop-modal';
import Spinner from '../base-spinner';
import { ClearCross } from '../icons';
import { generateFileName } from './crop-image';

interface IImageUploader {
  id: string;
  onSave: (src: string | any) => void;
  defaultImage: string;
  imageSrc?: string;
  loadImage?: boolean;
}

const ImageUploader: React.FC<IImageUploader> = ({
  id,
  imageSrc,
  onSave,
  defaultImage,
  loadImage,
}) => {
  const clearImage = useMemo(
    () => parseURL(imageSrc || defaultImage),
    [defaultImage, imageSrc]
  );
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<string>(clearImage);
  const [aspectImage, setAspectImage] = useState<number>(16 / 9);
  const [croppedImage, setCroppedImage] = useState<string | Blob>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [imageType, setImageType] = useState<string>('image/jpeg');

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    readImage(file);
  };

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    readImage(file);
  };

  const readImageAspect = (src: string): Promise<number> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        const aspectRatio = img.width / img.height;
        resolve(aspectRatio);
      };
      img.onerror = (error) => {
        reject(error);
      };
    });
  };

  const readImage = (file: File) => {
    const fileFormat = file.type;
    const reader = new FileReader();
    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result as string;
      img.onload = () => {
        setImageType(fileFormat);
        setIsModalOpen(true);
      };
      setImage(img.src);
    };
    reader.readAsDataURL(file);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleSaveCropImage = async (image: string | Blob) => {
    if (typeof image === 'string' && image.startsWith('blob:')) {
      const blob = await fetch(image).then((res) => res.blob());
      const fileName = generateFileName();
      const file = new File(
        [blob],
        fileName + `.${imageType.slice(imageType.indexOf('/') + 1)}`,
        { type: imageType }
      );
      onSave(file);
      setCroppedImage(image);
    } else if (image instanceof Blob) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const fileName = generateFileName();
        const file = new File(
          [image],
          fileName + `.${imageType.slice(imageType.indexOf('/') + 1)}`,
          { type: imageType }
        );
        onSave(file);
        setCroppedImage(URL.createObjectURL(file));
      };
      reader.readAsDataURL(image);
    } else {
      onSave(image);
      setCroppedImage(image);
    }
  };

  const handleClearFileState = (e: any) => {
    e.preventDefault();
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setImage(parseURL(defaultImage));
    setCroppedImage(parseURL(defaultImage));
  };

  useEffect(() => {
    if (clearImage) {
      readImageAspect(clearImage)
        .then((aspectRatio) => {
          setAspectImage(Number(aspectRatio.toFixed(1)));
          setCroppedImage(clearImage);
        })
        .catch((error) => {
          setImage(parseURL(defaultImage));
          setCroppedImage(parseURL(defaultImage));
          console.error('Error loading image:', error);
        });
    }
  }, [clearImage, defaultImage]);

  return (
    <>
      <label htmlFor={id} className="w-full flex flex-col items-center">
        <div
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          className="relative border-2 py-2 px-4 w-full border-dashed border-blue-500 rounded-lg flex flex-col items-center justify-center cursor-pointer bg-gray-50"
        >
          {loadImage && (
            <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-[#00000050]">
              <Spinner size="h-8 w-8" />
            </div>
          )}
          {croppedImage ? (
            <div
              className="w-full h-40 bg-no-repeat bg-center bg-contain"
              style={{ backgroundImage: `url("${croppedImage}")` }}
            />
          ) : (
            <div className="text-center text-gray-500">
              <p>Upload or drop a file right here</p>
            </div>
          )}
          {croppedImage && croppedImage !== parseURL(defaultImage) && (
            <button onClick={handleClearFileState}>
              <ClearCross className="w-3 h-3 absolute top-6 right-6" />
            </button>
          )}
        </div>
        <input
          id={id}
          ref={fileInputRef}
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          className="mt-2 hidden"
        />
      </label>
      <CropImageModal
        image={image}
        imageAspect={aspectImage}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={(image) => handleSaveCropImage(image)}
        imageFormat={imageType}
      />
    </>
  );
};

export default ImageUploader;
