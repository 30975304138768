import React, { useState } from 'react';
import { Card, Label, TextInput, Footer } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from 'src/store/hooks';
import { useLoginMutation } from 'src/store/services/auth/endpoints/auth';
import { loadUser, setCredentials } from 'src/store/slices/authSlice';
import { parseResponse } from 'src/store/services/helpers';

import Button from 'src/components/button';

export function LoginPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [error, setError] = useState('');
  const currentYear = new Date().getFullYear();
  const [login, { isLoading: isLoadingAuth, isError }] = useLoginMutation();

  const onSubmitForm = async (e: any) => {
    e.preventDefault();
    const tokens = await login({ email, password });
    const { result, errorMessage, error } = parseResponse(tokens);
    if (error) {
      setError(errorMessage);
    }
    if (result) {
      dispatch(setCredentials(result.data));
      dispatch(loadUser());
      navigate('/');
    }
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center py-6 h-screen lg:gap-y-6">
        <div className="my-6 flex items-center gap-x-1 lg:my-0">
          <img alt="" src="/assets/images/logo.svg" className="h-12" />
        </div>

        {isError && (
          <div
            data-testid="error_message"
            className="p-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
            role="alert"
          >
            {error}
          </div>
        )}

        <Card horizontal className="w-full max-w-[448px] md:[&>*]:w-full">
          <h1 className="mb-3 text-2xl font-bold dark:text-white md:text-3xl">
            Log in
          </h1>

          <form onSubmit={onSubmitForm}>
            <div className="mb-4 flex flex-col gap-y-3">
              <Label htmlFor="email">Email</Label>
              <TextInput
                id="email"
                data-testid="email"
                name="email"
                placeholder="name@example.com"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-6 flex flex-col gap-y-3">
              <Label htmlFor="password">Password</Label>
              <TextInput
                id="password"
                data-testid="password"
                name="password"
                placeholder="••••••••"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div className="mb-6">
              <Button
                buttonType="submit"
                className="w-full bg-primary-700 justify-center"
                onClick={onSubmitForm}
                title={!isLoadingAuth ? 'Log In' : 'Loading...'}
                isLoading={isLoadingAuth}
                disabled={isLoadingAuth}
              />
            </div>
            <p className="text-gray-600 font-medium text-xs text-center">
              By signing in you agree with{' '}
              <a
                data-testid="terms_link"
                className="text-primary-600 underline"
                rel="noreferrer"
                href="https://ai.pipedata.co/terms-of-use"
                target="_blank"
              >
                Terms
              </a>{' '}
              and{' '}
              <a
                data-testid="privacy_link"
                className="text-primary-600 underline"
                rel="noreferrer"
                href="https://ai.pipedata.co/privacy-policy"
                target="_blank"
              >
                Privacy Policy
              </a>
            </p>
          </form>
        </Card>

        <div className="w-full sm:items-center sm:justify-center pt-[43px] text-center">
          <Footer.Copyright
            by="Pipedata AI, LLC. All rights reserved."
            href="#"
            className="text-gray-500 py-[32px]"
            year={currentYear}
          />
        </div>
      </div>
    </>
  );
}
