import { useMemo, useState } from 'react';
import { TextInput } from 'flowbite-react';
import { getTypeElement } from './helpers/iframe-events';
import ModalBox from '../modal-box/modal-box';
import Button from '../button';

interface IModalProps {
  isOpen: boolean;
  element?: any;
  onClose: () => void;
  skipTitle?: boolean;
  onSave: (selected: any, name?: string) => void;
}

const IframeModalBox = ({
  isOpen,
  element,
  onClose,
  onSave,
  skipTitle,
}: IModalProps) => {
  const [headline, setHeadline] = useState('');

  const elementPlaceholder = useMemo(() => {
    if (element) {
      const elementType = getTypeElement(element);
      const elementTag = element.tagName.toLowerCase();
      const elementParentTag = element.parentElement.tagName.toLowerCase();
      const elementText = () => {
        switch (elementType) {
          case 'image':
            return 'Image';
          case 'button':
            return 'CTA';
          default:
            return elementTag.includes('h') || elementParentTag.includes('h')
              ? 'Headline'
              : 'Text';
        }
      };
      return elementText();
    } else {
      return 'Add element name here';
    }
  }, [element]);

  const handleSave = () => {
    if (skipTitle) {
      onSave(element);
    } else {
      onSave(element, headline);
    }
    setHeadline('');
    onClose();
  };

  return (
    <ModalBox isOpen={isOpen} onClose={onClose} windowClassName="max-w-xl">
      <ModalBox.Header>
        <p className="text-lg font-semibold text-gray-900">Name the element</p>
      </ModalBox.Header>
      <ModalBox.Body>
        {skipTitle ? (
          'Do you want select this element?'
        ) : (
          <TextInput
            id="atribute-name"
            placeholder={elementPlaceholder}
            value={headline}
            onChange={(e) => setHeadline(e.target.value)}
            required={true}
          />
        )}
      </ModalBox.Body>
      <ModalBox.Footer className="border-t-0 pt-0">
        <Button
          title="Save"
          className="min-w-73 ml-auto"
          onClick={handleSave}
        />
      </ModalBox.Footer>
    </ModalBox>
  );
};

export default IframeModalBox;
